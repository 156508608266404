import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import axios from "axios";
import Notification from "../../../components/notification/notification";

function FuelCostUpdateDataPopup({ togglePopup }) {
  const [fuelDetails, setFuelDetails] = useState({
    fuelType: "",
    effectiveDate: "",
    fuelCostPerLiter: "",
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

  const [notification, setNotification] = useState({ message: "", type: "" });
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFuelDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleDateChange = (date, name) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setFuelDetails((prevDetails) => ({
      ...prevDetails,
      [name]: formattedDate,
    }));
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    console.log(fuelDetails);
    try {
      const response = await axios.post(
        `${backendUrl}/fuelDetails/`,
        fuelDetails,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log(response.data);

      // Extract a success message or create one if not present
      const successMessage =
        response.data.message || "Fuel data saved successfully!";

      setNotification({
        message: successMessage, // Ensure this is a string
        type: "success",
      });
    } catch (error) {
      console.log(error.response);

      // Extract error message from the error response or set a default error
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        "An error occurred while saving fuel data.";

      setNotification({
        message: errorMessage, // Ensure this is a string
        type: "error",
      });
    }

    setIsSaveConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const handleResetForm = () => {
    setFuelDetails({
      fuel_type: "",
      effective_data: "",
      fuel_cost_per_liter: "",
    });
    setIsResetConfirmationOpen(false);
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Fuel Cost Data</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* vehicle_type */}
              <div>
                <label className="block text-gray-700">Vehicle Type</label>
                <select
                  name="fuelType"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelDetails.fuelType}
                  onChange={handleInputChange}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="CAR">CAR</option>
                  <option value="VAN">VAN</option>
                  <option value="SUV">SUV</option>
                </select>
              </div>

              {/* Effective Date */}
              <div>
                <label className="block text-gray-700">Effective Date *</label>
                <DatePicker
                  selected={fuelDetails.effectiveDate}
                  onChange={(date) => handleDateChange(date, "effectiveDate")}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              {/* Fuel Cost Per Liter */}
              <div>
                <label className="block text-gray-700">
                  Fuel Cost Per Liter*
                </label>
                <input
                  type="text"
                  name="fuelCostPerLiter"
                  onChange={handleInputChange}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelDetails.fuelCostPerLiter}
                />
              </div>
            </div>
          </div>

          {/* <h1 className="text-[30px] font-bold mb-8 mt-10">
            Vehicle Registration Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button> */}

          {/* {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )} */}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FuelCostUpdateDataPopup;
