import React, { useEffect, useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import usePermissions from "../../../components/permissions/permission";
import { IoAdd } from "react-icons/io5";

const Tender_quality_test_reports = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_QUALITY;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableRest, setTableReset] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState("");
  const [seletedValue, setSeletedValue] = useState({
    sample_test_id: "",
    start_date: "", 
    end_date: ""
  
  });

  const { hasPermission } = usePermissions();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });

  const rowsPerPage = 5; // Number of rows per page

  // Calculate the number of pages
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  // Get the current rows for the page
  const currentRows = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleRefNumChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatDateForInput = (date) => {
    if (!date) return ""; // Return an empty string if date is invalid
    const formattedDate = new Date(date);
    return isNaN(formattedDate) ? "" : formattedDate.toISOString().slice(0, 10); // Format to 'YYYY-MM-DD'
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Prevent page change if out of bounds
    setCurrentPage(page);
  };

  const handleStartDateChange = (event) => {
    const formattedDate = formatDateForInput(event.target.value);
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (event) => {
    const formattedDate = formatDateForInput(event.target.value);
    setEndDate(formattedDate);
  };

 

  const getTableData = async () => {
    try {
      const params = {
        ...seletedValue
      };

      const response = await axios.get(
        `${backendUrl}/qualitySampleTest/getQualityTestTender`,
        {
          params,
        }
      );

      setTableData(response.data);
    } catch (error) {
      setTableData([]); 
      console.error("Error fetching sample details:", error);
    }
  };

  const handleExportCSV = () => {
    const csvData = tableData.map((obj) => ({
      SampleID: obj.sample_id,
      SubmissionDate: obj.sample_type,
      Status: obj.status,
    }));
    const csv = Papa.unparse(csvData); // Convert to CSV format
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Sample_Allocation_data.csv"); // Save the CSV file
  };


  // Function to handle PDF download
  const downloadPDF = async (row) => {

    console.log(row);


    const temp = row.pdf

    console.log(temp);
   
    try {
      const response = await axios.get(`${backendUrl}/qualitySampleTest/getPdf`, {
        params: {
          pdf_upload_path: temp, // Pass registration_upload_path as a query param
        },
        responseType: "blob", // Ensure we expect a blob response
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "TenderQualityTestReports.pdf"; // Add .pdf extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  useEffect(() => {
    getTableData();
  }, [seletedValue]);

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Tender Quality Test Reports
            </p>
          </div>
        </div>

        {/* Controls with Add Button */}
        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/* Search Box with Manual Width */}
              <div className="w-full mt-6 md:w-[300px]">
                <input
                  type="text"
                  placeholder="Search..."
                  name="sample_test_id"
                  value={seletedValue.sample_test_id}
                  onChange={handleRefNumChange}
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Start Date Selector with Manual Width */}
              <div className="w-full md:w-[200px]">
                <label className="block mb-1 text-gray-700">Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={seletedValue.start_date}
                  onChange={handleRefNumChange}
                />
              </div>

              {/* End Date Selector with Manual Width */}
              <div className="w-full md:w-[200px]">
                <label className="block mb-1 text-gray-700">End Date</label>
                <input
                  type="date"
                  name="end_date"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={seletedValue.end_date}
                  onChange={handleRefNumChange}
                />
              </div>
            </div>

                {/* Right-Side Buttons */}
                <div className="relative flex items-center gap-4 mt-4 md:mt-0">
              {/* Add Button */}

      \

              {/* {hasPermission(8003) && (
                <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                  onClick={handleExportPDF}
                >
                  <IoAdd className="text-xl" />
                  PDF to Export
                </button>
              )} */}

              {hasPermission(8012) && (
                <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
                  onClick={handleExportCSV}
                >
                  <IoAdd className="text-xl" />
                  CSV to Export
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-14">
        {/* TailwindCSS Table */}
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Sample Testing ID
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Submission Date
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Report
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentRows.map((row) => (
              <tr key={row.sample_id}>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {row.sample_test_id}
                </td>

                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {new Date(row.received_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long", // Use "short" for abbreviated month names
                    day: "2-digit",
                  })}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                  {hasPermission(8014) && (
                    <button
                      className="p-2 text-blue-500 rounded hover:bg-blue-100"
                      onClick={() =>
                        downloadPDF(row)
                      }
                    >
                      Download PDF
                    </button>
                  )}
                </td>

                <td
                  className={`px-6 py-4 text-sm whitespace-nowrap ${
                    row.status === "PENDING" ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {row.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            className={`px-3 py-1 mx-1 text-sm rounded ${
              currentPage === i + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 mx-1 text-sm text-white bg-gray-500 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Tender_quality_test_reports;
