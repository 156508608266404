import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LabelImport from "../../../../Assets/icons/label_important.png";
import DragIndicator from "../../../../Assets/icons/drag_indicator.png";
import Avatar from "../../../../Assets/icons/Avatar.png";
import { MdDelete, MdVisibility } from "react-icons/md";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";

// Example data array
const exampleData = [
  {
    id: 1,
    from: 'Apple MacBook Pro 17"',
    status: "Sent",
    subject: "Laptop",
    content: "$2999",
  },
  {
    id: 2,
    from: "Microsoft Surface Pro",
    status: "Sent",
    subject: "Laptop PC",
    content: "$1999",
  },
  {
    id: 3,
    from: "Magic Mouse 2",
    status: "Sent",
    subject: "Accessories",
    content: "$99",
  },
  {
    id: 4,
    from: "Apple Watch",
    status: "Sent",
    subject: "Accessories",
    content: "$179",
  },
  {
    id: 5,
    from: "iPad",
    status: "Sent",
    subject: "Tablet",
    content: "$699",
  },
  {
    id: 6,
    from: 'Apple iMac 27"',
    status: "Sent",
    subject: "PC Desktop",
    content: "$3999",
  },
];

const TableMail = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    setSelectedRow(rowData);
  };
  return (
    <div className="relative overflow-x-auto  ">
      <table className=" text-sm text-left text-black ">
        <thead className="text-xs text-gray-700 uppercase bg-white">
          <tr>
            <th scope="col" className="p-4 bg-white">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {exampleData.map((item) => (
            <tr key={item.id} className="bg-white border-b hover:bg-gray-50">
              <td className="w-4 p-4 max-w-[30px]">
                <div className="flex items-center">
                  <img
                    src={DragIndicator}
                    alt="DragIndicator"
                    className="mr-2"
                  />
                  <input
                    id={`checkbox-table-${item.id}`}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label
                    htmlFor={`checkbox-table-${item.id}`}
                    className="sr-only"
                  >
                    checkbox
                  </label>
                  <img src={LabelImport} alt="SideIcon" className="ml-2" />
                </div>
              </td>
              <td
                scope="row"
                className="px-1 py-4 font-medium text-gray-900 whitespace-nowrap text-left"
              >
                {item.from}
              </td>
              <td className=" py-4 text-center">
                <div className=" flex justify-start">
                  <div className="bg-[#C0D1AD] text-[#0D3B44] rounded-lg min-w-10 ">
                    {item.status}
                  </div>
                </div>
              </td>
              <td className=" py-4 text-left">
                <div className="flex justify-start">
                  <div className="font-bold">{item.subject}</div>
                  <div>
                    <p> :- </p>
                  </div>
                  <div> {item.content}</div>
                </div>
              </td>

              <td className=" py-4">
                <button>
                  <MdDelete />
                </button>
              </td>
              <td className=" py-4">
                <button onClick={() => handleViewClick(item)}>
                  <MdVisibility />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRow && (
        <div className="absolute inset-0 flex min-h-[100%] min-w-[100%] justify-center items-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 min-h-[100%] min-w-[100%] ">
            <div className="mt-4 flex items-center">
              <button onClick={() => setSelectedRow(null)}>
                <AiOutlineArrowLeft />
              </button>

              <h5 className="ml-6 text-[22px]"> {selectedRow.subject}</h5>
              <div className="min-h-4 min-w-20 bg-[#0000000d] rounded-lg ml-16 text-center justify-center flex items-center font-bold text-slate-500 ">
                inbox <AiOutlineClose className="ml-1 mt-1" />
              </div>
            </div>
            <div className=" flex justify justify-between">
              <div className="flex">
                {" "}
                <img src={Avatar} alt="DragIndicator" className="mr-2 mt-4" />
                <div>
                  <div className="mt-4 flex items-center">
                    <p className=" font-bold text-[14px]">SANDARU SANDARU</p>{" "}
                    <p className=" text-slate-500 ml-2">sandaru@example.com</p>
                  </div>
                  <div>
                    <p className="text-slate-500">to me</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-slate-500">9.14 AM (8 hours ago)</p>
                <MdDelete className="ml-10 text-slate-500 text-[22px]" />
              </div>
            </div>

            <div className="mt-6">
              <p>From: {selectedRow.from}</p>
              <p>Status: {selectedRow.status}</p>
              <p>Subject: {selectedRow.subject}</p>
              <p>Content: {selectedRow.content}</p>
              {/* Add more data fields as needed */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableMail;
