 

import React, { useState, useEffect, useCallback } from "react";

const Approval_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change

  const permissionHierarchy = {
    17: { children: [260, 261] },
    260: { children: [1980, 1981, 1982, 1983, 1984, 1985] },
    261: { children: [1986, 1987, 1988, 1989, 1990, 1991] },
    // Add the rest of your permissions here
  };

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
    console.log("Key Values", checkedValues);
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[17].children.includes(perm)
      ) &&
      !selectedPermissions.includes(17)
    ) {
      selectedPermissions.push(17);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline"
            Value="17"
          >
            Approvals
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Approvals 1
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1980"
                      checked={!!checkedValues[1980]}
                      onChange={() => handleCheckboxChange(1980)}
                    />
                    HRIS Leave Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1981"
                      checked={!!checkedValues[1981]}
                      onChange={() => handleCheckboxChange(1981)}
                    />
                    HRIS Employee Data Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1982"
                      checked={!!checkedValues[1982]}
                      onChange={() => handleCheckboxChange(1982)}
                    />
                    POS add Registered Customer
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1983"
                      checked={!!checkedValues[1983]}
                      onChange={() => handleCheckboxChange(1983)}
                    />
                    POS Credit Clearance Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1984"
                      checked={!!checkedValues[1984]}
                      onChange={() => handleCheckboxChange(1984)}
                    />
                    Inventory Despose Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1985"
                      checked={!!checkedValues[1985]}
                      onChange={() => handleCheckboxChange(1985)}
                    />
                    Inventory Adjust Approvals
                  </label>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Approvals 2
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1986"
                      checked={!!checkedValues[1986]}
                      onChange={() => handleCheckboxChange(1986)}
                    />
                    HRIS Leave Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1987"
                      checked={!!checkedValues[1987]}
                      onChange={() => handleCheckboxChange(1987)}
                    />
                    HRIS Employee Data Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1988"
                      checked={!!checkedValues[1988]}
                      onChange={() => handleCheckboxChange(1988)}
                    />
                    POS add Registered Customer
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1989"
                      checked={!!checkedValues[1989]}
                      onChange={() => handleCheckboxChange(1989)}
                    />
                    POS Credit Clearance Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1990"
                      checked={!!checkedValues[1990]}
                      onChange={() => handleCheckboxChange(1990)}
                    />
                    Inventory Despose Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1991"
                      checked={!!checkedValues[1991]}
                      onChange={() => handleCheckboxChange(1991)}
                    />
                    Inventory Adjust Approvals
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Approval_Permission;
