/** @format */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import usePermissions from "../../../../components/permissions/permission";

const allowances = [
  { name: "Allowance TP", value: "Allowance_TP", type: "Allowance" },
  { name: "AJT Allowance", value: "AJT_Allowance", type: "Allowance" },
  { name: "Cost Of Allowance", value: "Cost_Of_Allowance", type: "Allowance" },
  { name: "SLADC Allowance", value: "SLADC_Allowance", type: "Allowance" },
  { name: "Salary Arrears", value: "Salary_Arrears", type: "Allowance" },
  { name: "Traveling", value: "Traveling", type: "Allowance" },

  { name: "Traveling and Fuel", value: "Traveling_Fuel", type: "Allowance" },
  { name: "Special Allowance", value: "Spe_Allowance", type: "Allowance" },
  {
    name: "Difficult Allowance",
    value: "Difficult_Allowance",
    type: "Allowance",
  },
  { name: "Acting Allowance", value: "Act_Allowance", type: "Allowance" },
  {
    name: "Accounting Allowance Arrears",
    value: "Acct_Allo_Arre",
    type: "Allowance",
  },
  {
    name: "Special Allowance 02",
    value: "SPE_ALLOWANCE_02",
    type: "Allowance",
  },
  {
    name: "Cost of Allowance Language",
    value: "cost_of_allowance_language",
    type: "Allowance",
  },
  { name: "Over Time", value: "Over_Time", type: "Allowance" },
  {
    name: "Language Allowance",
    value: "language_allowance",
    type: "Allowance",
  },
];

const PaymentMenu = () => {
  const { hasPermission } = usePermissions();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleButtonClick = () => {
    navigate("/all-allowance"); // Replace '/payments' with your desired path
  };

  const filteredAllowances = allowances.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-background h-screen overflow-y-auto overflow-x-hidden">
      <div>
        <p className="ml-6 mt-6 font-sans text-[30px] font-bold text-[#797C80]">
          Payments Menu
        </p>
      </div>

      <div className="flex justify-between items-center gap-[45%] mt-[-40px]">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form className="form relative">
              <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  className="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
            </form>
          </div>
        </div>
        <div>
          {hasPermission(2060) && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] ml-[35%]">
              <div
                key=""
                className="bg-white rounded-[32px] w-[350px] h-[180px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  <h2 className="text-[20px] font-semibold mb-1 font-sans">
                    All Allowance
                  </h2>
                  <h2 className="text-[20px] font-semibold mb-1 font-sans">
                    Details
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                    onClick={handleButtonClick}
                  >
                    Click Here
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="mt-5 ml-6 font-sans font-semibold text-[#797C80] text-[20px] mb-[30px]">
          <p className="text-[25px] font-bold">Allowances </p>
          <div className="flex justify-center ">
            <div className="bg-white w-[80%] rounded-[39px] mt-6 mr-[5%] h-[470px] overflow-y-auto scrollbar-thin scrollbar-thumb-primary mb-5">
              <div className="ml-6 grid grid-cols-5 gap-1">
                {filteredAllowances.map((item) => (
                  <Link
                    to={`/payment_details/${item.value}/${item.name}/${item.type}`}
                    key={item.value}
                  >
                    <button className="bg-primary p-3 rounded-[20px] w-[200px] mt-8 h-[80px] text-white shadow-md">
                      <p className="text-[20px]">{item.name}</p>
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMenu;
