/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiFile, FiCalendar } from "react-icons/fi";
import axios from "axios";
import DialogEditForm from "./editSuppliersDialog";
import usePermissions from "../../../../components/permissions/permission";

const TableSupplier = ({ data, tableDate, category }) => {
  const { hasPermission } = usePermissions();
  const [showDialogIndex, setShowDialogIndex] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const openDialog = (item) => {
    setShowDialogIndex(true);
    setSelectedItem(item);
  };

  const closeDialog = () => {
    setShowDialogIndex(false);
    setSelectedItem(null);
  };

  const delteItem = async (item) => {
    if (window.confirm("Are you sure you want to delete this supplier?")) {
      try {
        const request = await axios.delete(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/supplier/${item}`
        );
        tableDate();
        alert("Success Fully Deleted User Data");
        console.log(request);
      } catch (error) {
        console.log(error);
        alert("User Delete Fail");
      }
    }
  };

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data;
  console.log("Table Data:", tableData);

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[95%] max-h-[500px] overflow-y-auto mt-8 mb-8">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6 ">Supplier Number</th>
            <th className="px-6 py-6 ">Category Name</th>
            <th className="px-6 py-6 ">Address</th>
            <th className="px-6 py-6 ">Phone No.</th>
            <th className="px-6 py-6 ">Fax No.</th>
            <th className="px-6 py-6 ">Email</th>
            <th className="px-6 py-6 ">VAT No.</th>
            <th className="px-6 py-6 ">Category</th>
            <th className="px-6 py-6 "></th>
            <th className="px-6 py-6 "></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-6 py-2">{item.supplier_id}</td>
              <td className="px-6 py-2">{item.supplier_type}</td>
              <td className="px-6 py-2">{item.supplier_address}</td>
              <td className="px-6 py-2">{item.supplier_contact_no}</td>
              <td className="px-6 py-2">{item.supplier_name}</td>
              <td className="px-6 py-2">{item.supplier_email}</td>
              <td className="px-6 py-2">{item.supplier_status}</td>
              <td className="px-6 py-2">{item.supplier_update_date}</td>
              <td className="px-6 py-2">
                {hasPermission(2631) && (
                  <button
                    className="text-black bg-gray-300 rounded-[22px] min-w-[55px]"
                    onClick={() => openDialog(item)}
                  >
                    Edit
                  </button>
                )}
                {showDialogIndex && (
                  <DialogEditForm
                    onClose={closeDialog}
                    item={selectedItem}
                    tableDate={tableDate}
                    dropdown={category}
                  />
                )}
              </td>
              <td className="px-6 py-2">
                {hasPermission(2632) && (
                  <button
                    className="bg-primary text-white min-w-[65px] rounded-[22px] "
                    onClick={() => delteItem(item.supplier_id)}
                  >
                    Delete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSupplier;
