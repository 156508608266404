/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../../../Assets/icons/csv.png";
import usePermissions from "../../../../../components/permissions/permission";
import Papa from "papaparse";

const Opening_Price_Quo_Form_Lists = ({ itemReq }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const location = useLocation();
  const { selectedRecord = {} } = location.state || {};
  const [tableData, setTableData] = useState(selectedRecord.Suppliers || []);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const HandleOnClick = (itemReq) => {
    // Navigate to the new page and pass the object
    navigate("/committess/openingPriceFormQuoView", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees = tableData.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.inventory_request_by
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.inventory_request_id
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    return searchMatch;
  });

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const handleExportCSV = () => {
  //   const csvContent =
  //     "data:text/csv;charset=utf-8," +
  //     items.map((row) => Object.values(row).join(",")).join("\n");

  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", "employee_data.csv");
  //   document.body.appendChild(link);
  //   link.click();

  //   setTimeout(() => {
  //     window.alert("Successfully Downloaded");
  //   }, 100);
  // };

  const exportToCSV = (currentItems) => {
    const csvData = currentItems.map((itemReq) => ({
      "Quotation No": itemReq.qoutation_id,
      "Supplier ID": itemReq.supplier_id,
      "Supplier Name": itemReq.supplier_name,
      "Net Total": itemReq.net_total,
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Opening_Price_Quo_Form_Lists.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Opening Price Quotation List
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by Purchase Request No"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Quotation No</th>
              <th className="text-primary px-6 py-4">Supplier ID</th>
              <th className="text-primary px-6 py-4">Supplier Name</th>
              <th className="text-primary px-6 py-4">Net Total</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems.map((itemReq) => (
              <tr key={itemReq.qoutation_id}>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.qoutation_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.supplier_id}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.supplier_name}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  {itemReq.net_total}
                </td>
                <td className="bg-white px-6 py-4 text-center">
                  <button
                    className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                    onClick={() => HandleOnClick(itemReq)}
                    // setselectedItemReq={itemReq}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
          <div className="flex items-center" onClick={exportToCSV}>
            <div onClick={exportToCSV} className="font-semibold">
              Export CSV
            </div>
            <div>
              <img className="h-8 w-8" src={CSV} alt="logo-csv" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Opening_Price_Quo_Form_Lists;
