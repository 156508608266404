/** @format */

import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import ConfirmationModal from "../confirmation_modal";
import { CSVLink } from "react-csv";
import AddUser from "./add_user"; // Make sure to import the AddUser component
import Edit_User from "./edit_user";
import usePermissions from "../../../components/permissions/permission";

const User_Management = () => {
  const { hasPermission } = usePermissions();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false); // State for showing Add User modal
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState(null);
  const itemsPerPage = 6;
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleRowSelect = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((i) => i !== index)
        : [...prevSelectedRows, index]
    );
  };

  const handleDeleteClick = (item) => {
    setUserToDelete(item);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmed = async () => {
    if (userToDelete) {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/user/deleteUserRole?role_id=${userToDelete.id}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          console.log("Deleted User:", userToDelete);
          setShowConfirmationModal(false);
          setUserToDelete(null);
          // Refresh the roles list after deletion
          fetchUserData();
        } else {
          console.error("Failed to delete item");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setUserToDelete(null);
  };

  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const handleEditUserClick = (users) => {
    setEditUserDetails(users); // Set the user details
    setShowEditUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
    fetchUserData();
  };

  const handleCloseEditUserModal = () => {
    setShowEditUserModal(false); // Corrected this line
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/user/getalluser`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("user: ", data);
      setUsers(data);
      console.log("set users: ", users);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const pageCount = Math.ceil(users.length / itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const usersData = users.slice(offset, offset + itemsPerPage);

  const csvData = users.map((item, index) => ({
    id: item.id,
    employee_no: item.employee_no,
    username: item.username,
    employee_status: item.status,
    Role: item.user_role,
    Description: item.description,
  }));

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div className="mt-10 ml-6">
        <p className="text-[28px] font-sans font-semibold">User Management</p>
      </div>

      <div>
        <div className="bg-background overflow-y-auto h-screen overflow-x-hidden">
          <div className="bg-[#F4F7FCBF] rounded-[6px] opacity-75 w-[90%] overflow-y-auto ml-[20px] mt-[20px] mb-10">
            <div className="flex justify-between items-center p-5">
              <div className="ml-[20px]">
                <input
                  className="bg-white border mt-[10px] h-8 border-gray rounded-[20px]"
                  placeholder="Search"
                />
              </div>
              <div>
                {hasPermission(1970) && (
                  <button
                    onClick={handleAddUserClick}
                    className="bg-[#2264E5] text-white flex gap-2 items-center p-2 rounded-[35px]"
                  >
                    <FaPlus />
                    Add User
                  </button>
                )}
              </div>
            </div>

            {/* table */}
            <div className="w-[90%] ml-[50px] mt-[20px]">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-center">#</th>
                    <th className="py-2 px-4 text-center">User Id</th>
                    <th className="py-2 px-4 text-center">User name</th>
                    <th className="py-2 px-4 text-center">email</th>
                    <th className="py-2 px-4 text-center">Status</th>
                    <th className="py-2 px-4 text-center">Role</th>
                    <th className="py-2 px-4 text-center">Description</th>
                    <th className="py-2 px-4 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.map((user, index) => (
                    <tr
                      key={user.id}
                      className={`${
                        selectedRows.includes(index)
                          ? "bg-gray-200"
                          : index % 2 === 1
                          ? "bg-[#F4F7FCBF]"
                          : "bg-white"
                      }`}
                    >
                      <td className="py-2 px-4 text-center">
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelect(index)}
                        />
                        {offset + index + 1}
                      </td>
                      <td className="py-2 px-4 text-center">{user.id}</td>
                      <td className="py-2 px-4 text-center">
                        {user.employee_no}
                      </td>
                      <td className="py-2 px-4 text-center">{user.username}</td>
                      <td className="py-2 px-4 text-center">
                        <span
                          className={`rounded py-1 px-2 ${
                            user.status === "Active"
                              ? "bg-green-500 text-white"
                              : "bg-red-500 text-white"
                          }`}
                        >
                          {user.employee_status}
                        </span>
                      </td>
                      <td className="py-2 px-4 text-center">
                        {user.user_role}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {user.user_role}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {hasPermission(1971) && (
                          <button
                            className=" px-2 py-1 rounded"
                            onClick={() => handleEditUserClick(user)}
                          >
                            <CiEdit />
                          </button>
                        )}

                        {hasPermission(1971) && (
                          <button
                            className=" px-2 py-1 rounded"
                            onClick={() => handleDeleteClick(user)}
                          >
                            <MdDelete />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center mt-4">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            <div className="flex justify-end mr-[2%]">
              {hasPermission(1972) && (
                <CSVLink
                  data={csvData}
                  filename="user_data.csv"
                  className="p-2 bg-primary rounded-[16px] text-white"
                >
                  Export as CSV
                </CSVLink>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      {showAddUserModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-[30px]">
            <AddUser onClose={handleCloseAddUserModal} />
          </div>
        </div>
      )}

      {/* Edit User Modal */}
      {showEditUserModal && (
        <div>
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-[30px]">
              <Edit_User
                userDetails={editUserDetails}
                onClose={handleCloseEditUserModal}
              />
            </div>
          </div>
        </div>
      )}

      {/* Delete User Modal */}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this?"
          onCancel={handleCancelDelete}
          onConfirm={handleDeleteConfirmed}
        />
      )}
    </div>
  );
};

export default User_Management;
