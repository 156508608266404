import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import axios from "axios";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Notification from "../../../../components/notification/notification";

function Edit_vehicle_part_popup({ toggleEditPopup, vehicle }) {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;

  
  const [vehicleDetails, setVehicleDetails] = useState({
    vehicle_id: "",
    part_name: "",
    part_number: "",
    part_life_span: "",
    installation_date: "",
    manufactured_date: "",
    expiry_date: "",
    maintenance_date: "",
    
    files: [],
  });

  console.log('vehicleDetails => ', vehicleDetails)

  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]); // Define state for uploaded files
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleResetForm = () => {
    setVehicleDetails({
      vehicle_id: "",
      part_name: "",
      part_number: "",
      part_life_span: "",
      installation_date: "",
      manufactured_date: "",
      expiry_date: "",
      maintenance_date: "",
 
      files: [],
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleRemoveFile = (index) => {
    // Remove the selected file from the uploaded files list
    const newFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newFiles);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleOpenSaveConfirmation = () => {
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      files: uploadedFiles,
    }));
    setIsSaveConfirmationOpen(true);
  };

  // const handleSaveForm = async () => {

  //   console.log(vehicleDetails);
  //   setIsSaveConfirmationOpen(false);
  // };

  const handleSaveForm = async () => {
    console.log(vehicleDetails);

    try {
      // Create a FormData object
      const formData = new FormData();

      // Append vehicle details (non-file fields)
      for (const key in vehicleDetails) {
        if (vehicleDetails.hasOwnProperty(key)) {
          formData.append(key, vehicleDetails[key]);
        }
      }

      // If you have files to upload (e.g., vehicle registration documents)
      if (vehicleDetails.registration_document) {
        formData.append(
          "registration_document",
          vehicleDetails.registration_document
        ); // Adjust the key to match your backend
      }

      // Send the POST request with multipart/form-data and query params
      const response = await axios.put(`${backendUrl}/parts/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          vehicle_id: vehicleDetails.vehicle_id, // Pass registration_upload_path as a query param
        },
      });

      console.log(response);

      setNotification({
        message: response.data,
        type: "success",
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        // Safely access error messages by checking if they exist
        const errorMessage =
          (error.response.data[0] && error.response.data[0].msg) ||
          error.response.data.error ||
          "An unexpected error occurred";

        setNotification({
          message: errorMessage,
          type: "error",
        });
      }
    }

    setIsSaveConfirmationOpen(false); // Close confirmation dialog after save
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const handleDateChange = (date, name) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: formattedDate,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    setVehicleDetails(vehicle);
  }, []);
  // if(this.item === undefined) {return}
  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Vehicle Part</h2>
          <button
            type="button"
            onClick={toggleEditPopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          <div className="items-center gap-8 mt-5 ">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px] ">
              {/* Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID</label>
                <input
                  type="text"
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.vehicle_id}
                  readOnly
                />
              </div>
              {/* // */}

              {/* <div>
                <label className="block text-gray-700"> ID</label>
                <input
                  type="text"
                  name="id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.id}
                  readOnly
                />
              </div> */}

              {/* part Name */}
              <div>
                <label className="block text-gray-700">Part Name</label>
                <input
                  type="text"
                  name="part_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.part_name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Vehicle part number */}
              <div>
                <label className="block text-gray-700">
                  Vehicle Part Number
                </label>
                <input
                  type="text"
                  name="part_number"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.part_number}
                  onChange={handleInputChange}
                />
              </div>
              {/* part life span */}
              <div>
                <label className="block text-gray-700">Part Life Span</label>
                <input
                  type="text"
                  name="part_life_span"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.part_life_span}
                  onChange={handleInputChange}
                />
              </div>

              {/* installation date */}
              <div>
                <label className="block text-gray-700">Installation Date</label>

                <DatePicker
                  selected={vehicleDetails.installation_date}
                  onChange={(date) =>
                    handleDateChange(date, "installation_date")
                  }
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              {/* Gasoline */}
              {/* <div>
              <label className="block text-gray-700">Gasoline</label>
              <input
                type="FLOAT"
                name="vehicle_mileage"
                className="w-full p-2 mt-2 border border-gray-300 rounded"
                value={vehicleDetails.vehicle_mileage}
                onChange={handleInputChange}
              />
            </div> */}
              {/* manufactured date  */}
              <div>
                <label className="block text-gray-700">Manufactured Date</label>

                <DatePicker
                  selected={vehicleDetails.manufactured_date}
                  onChange={(date) =>
                    handleDateChange(date, "manufactured_date")
                  }
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              {/* expiry date */}
              <div>
                <label className="block text-gray-700">Expiry Date</label>

                <DatePicker
                  selected={vehicleDetails.expiry_date}
                  onChange={(date) => handleDateChange(date, "expiry_date")}
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              {/* Maintenance Date */}
              <div>
                <label className="block text-gray-700">Maintenance Date</label>

                <DatePicker
                  selected={vehicleDetails.maintenance_date}
                  onChange={(date) =>
                    handleDateChange(date, "maintenance_date")
                  }
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  dateFormat="yyyy-MM-dd"
                />
              </div>

              {/*Status */}
              <div>
                <label className="block text-gray-700">Status</label>
                <select
                  name="part_status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.part_status}
                  onChange={handleInputChange}
                >
                  <option value="">Select Part Status</option>
                  {/* <option value="OPERATIONAL">OPERATIONAL</option>
                  <option value="UNDER MAINTENANCE">UNDER MAINTENANCE</option>
                  <option value="CAR MALFUNCTION">CAR MALFUNCTION</option> */}

                  <option value="OPERATIONAL">option 1</option>
                  <option value="UNDER MAINTENANCE"> option 2</option>
                  <option value="CAR MALFUNCTION">option 3</option>
                </select>

                {/* <input
                  type="text"
                  name="part_status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={vehicleDetails.part_status}
                  onChange={handleInputChange}
                /> */}
              </div>

              {/* Service */}
              {/* <div>
              <label className="block text-gray-700">Date Of Service</label>
              <DatePicker
                selected={vehicleDetails.vehi}
                onChange={(date) => handleDateChange(date, "vehicle_year")}
                className="w-full p-2 mt-2 border border-gray-300 rounded"
                dateFormat="yyyy/MM/dd"
              />
            </div> */}
            </div>
          </div>

          <h1 className="text-[30px] font-bold mb-8 mt-10">
            Vehicle parts Document
          </h1>
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#069855] rounded-[25px] text-[#069855]"
          >
            <div className="flex items-center gap-2">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button>

          {uploadedFiles.length > 0 && (
            <div className="mt-5">
              <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                >
                  <span>{file.name}</span>
                  <button
                    onClick={() => handleRemoveFile(index)}
                    className="px-3 py-1 text-white bg-red-500 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {/* {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )} */}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Edit_vehicle_part_popup;
