import React, { useEffect, useState } from "react";
import { Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import axios from "axios";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const Dashboard = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const [temp, setTemp] = useState({});
  const [temp2, setTemp2] = useState({});

  const lineData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Revenue",
        data: [12000, 19000, 3000, 5000, 20000, 30000],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const lineData2 = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Revenue",
        data: [9000, 1000, 2000, 3000, 20000, 50000],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const pieData = {
    labels: ["Revenue", "Expenses", "Profit"],
    datasets: [
      {
        label: "Financial Breakdown",
        data: [60, 25, 15],
        backgroundColor: ["#4CAF50", "#FF6384", "#36A2EB"],
        hoverOffset: 4,
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false, // Allows the chart to adjust based on the container size
  };

  const getNetProfit = async () => {
    try {
      const response = await axios.get(`${backendUrl}/dashboard/getNetProfit`);
      setTemp(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getGrossProfit = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/dashboard/getGrossProfit`
      );

      setTemp2(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNetProfit();
    getGrossProfit();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Container for the financial metric cards */}
      <div className="flex items-start justify-between space-x-4 mb-8">
        {/* Total Revenue Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-sm">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-700">
              Total Revenue
            </h2>
            <div className="p-2 bg-green-100 rounded-full">
              <svg
                className="h-6 w-6 text-green-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.75 18v-4.5H15v-1.5h-2.25v-1.5H15v-1.5h-2.25V6h-1.5v3H9v1.5h2.25v1.5H9v1.5h2.25V18h1.5z"></path>
              </svg>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-3xl font-bold text-gray-800">
              LKR{" "}
              {parseFloat(temp.total_revenue).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p className="text-sm text-gray-500 mt-2">This month</p>
          </div>
          <div className="mt-4">
            <p className="text-sm text-green-600 font-medium">▲ 12% increase</p>
          </div>
        </div>

        {/* Total Expenses Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-sm">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-700">
              Total Expenses
            </h2>
            <div className="p-2 bg-red-100 rounded-full">
              <svg
                className="h-6 w-6 text-red-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.75 18v-4.5H15v-1.5h-2.25v-1.5H15v-1.5h-2.25V6h-1.5v3H9v1.5h2.25v1.5H9v1.5h2.25V18h1.5z"></path>
              </svg>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-3xl font-bold text-gray-800">
              LKR{" "}
              {parseFloat(temp.total_expenses).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p className="text-sm text-gray-500 mt-2">This month</p>
          </div>

          <div className="mt-4">
            <p className="text-sm text-red-600 font-medium">▼ 8% decrease</p>
          </div>
        </div>

        {/* Total Expenses Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-sm">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-700">Gross</h2>
            <div className="p-2 bg-red-100 rounded-full">
              <svg
                className="h-6 w-6 text-yellow-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.75 18v-4.5H15v-1.5h-2.25v-1.5H15v-1.5h-2.25V6h-1.5v3H9v1.5h2.25v1.5H9v1.5h2.25V18h1.5z"></path>
              </svg>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-3xl font-bold text-gray-800">
              LKR{" "}
              {parseFloat(temp2.gross_profit).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p className="text-sm text-gray-500 mt-2">This month</p>
          </div>

          <div className="mt-4">
            <p className="text-sm text-yellow-500 font-medium">▼ 8% decrease</p>
          </div>
        </div>

        {/* Net Profit Card */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-sm">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-700">Net Profit</h2>
            <div className="p-2 bg-blue-100 rounded-full">
              <svg
                className="h-6 w-6 text-blue-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.75 18v-4.5H15v-1.5h-2.25v-1.5H15v-1.5h-2.25V6h-1.5v3H9v1.5h2.25v1.5H9v1.5h2.25V18h1.5z"></path>
              </svg>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-3xl font-bold text-gray-800">
              LKR{" "}
              {parseFloat(temp.net_profit).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
            <p className="text-sm text-gray-500 mt-2">This month</p>
          </div>

          <div className="mt-4">
            <p className="text-sm text-blue-600 font-medium">▲ 5% increase</p>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="flex items-start justify-between space-x-4">
        {/* Line Chart (Revenue Over Time) */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-1/2 h-100">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Accounts Payable Ageing
          </h2>
          <div className="h-full">
            <Line data={lineData} />
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 w-1/2 h-100">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Accounts Receivable Ageing
          </h2>
          <div className="h-full">
            <Line data={lineData2} />
          </div>
        </div>

        {/* Pie Chart (Financial Breakdown) */}
        {/* <div className="bg-white shadow-lg rounded-lg p-6 w-1/2 h-100">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Financial Breakdown</h2>
          <div className="h-full">
            <Pie data={pieData}  />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
