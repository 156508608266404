import React, { useState, useEffect } from "react";
import axios from "axios";
import { GrPrevious, GrNext } from "react-icons/gr";

function HistoryLog() {
  const [selectedLabel, setSelectedLabel] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/hris/employees/gethistorylog`
        );
        setData(response.data); // Assuming the response data is an array of history log objects
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleClick = (label) => {
    setSelectedLabel(label);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReset = () => {
    setSearchQuery("");
  };

  // Filtered data based on search query
  const filteredData = data.filter((item) =>
    item.employee_no.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="overflow-y-auto h-screen bg-[#E5EDF9] font-sans">
      {/* Header */}
      <div>
        <p className="text-[25px] ml-6 mt-5 w-[100%] rounded-xl font-bold text-[#797C80]">
          History Log of Edits
        </p>
      </div>
      {/* Search */}
      <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
        <form className="form relative">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            type="text"
            required=""
            placeholder="Search by Emp No"
            value={searchQuery}
            onChange={handleSearchChange}
            className="input w-[25%] rounded-full px-8 py-3 border-2 border-transparent focus:outline-none focus:border-[#797C80] placeholder-gray-400"
          />

          <button className="ml-5" type="reset" onClick={handleReset}>
            <label className="cursor-pointer">Reset</label>
          </button>
        </form>
      </div>
      {/* Time Frame */}
      <p className="text-[16px] ml-[5%] mt-5 w-[100%] rounded-xl font-bold text-[#797C80]">
        Time frame
      </p>
      <div>
        <div className="mt-5 ml-[5%] flex flex-row">
          {["Last 24 Hours", "Last 7 Days", "Last 30 Days", "Last 60 Days"].map(
            (label) => (
              <label
                key={label}
                className={`mr-5 cursor-pointer ${
                  selectedLabel === label ? "font-bold" : ""
                }`}
                style={{
                  borderBottom:
                    selectedLabel === label ? "2px solid #B7C6A7" : "none",
                  paddingBottom: selectedLabel === label ? "2px" : "0",
                }}
                onClick={() => handleClick(label)}
              >
                {label}
              </label>
            )
          )}
        </div>
        {/* Time Frame table */}
        <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
          <table className="table-auto border-none w-[95%] rounded-2xl border-2">
            <thead>
              <tr className="bg-white rounded-xl">
                {[
                  "Emp NO",
                  "Edit Description",
                  "Approval Status 01",
                  "Approved Status 01 Description",
                  "Approval Status 02",
                  "Approval Status 02 Description",
                ].map((heading) => (
                  <th
                    key={heading}
                    className="text-primary px-4 py-2 text-left"
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id} className="bg-white border-b">
                  <td className="px-4 py-2">{item.employee_no}</td>
                  <td className="px-4 py-2 justify-center">{item.edit_description}</td>
                  <td className="px-4 py-2">{item.approved_status_1}</td>
                  <td className="px-4 py-2">
                    {item.approved_status_1_description}
                  </td>
                  <td className="px-4 py-2">{item.approved_status_2}</td>
                  <td className="px-4 py-2">
                    {item.approved_status_2_description}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md "
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
}

export default HistoryLog;
