import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Drug_Logo from "../../../../../Assets/icons/drug-logo.png";
import { useLocation } from "react-router-dom";

const Tec_Meeting_Report = () => {
  const formRef = useRef(null);
  const location = useLocation();

  const meetingData = location.state.formData;
  const institute = location.state.instituteNames;

  console.log("institute Data:", meetingData);

  const committeeNature = meetingData.committee_nature;
  const isFilled = (nature) => committeeNature === nature;

  const downloadPdf = () => {
    const input = formRef.current;
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("OpeningPriceForm.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="flex justify-center mt-10">
      <button
        onClick={downloadPdf}
        className="bg-blue-500 text-white w-[100px] h-[100px] py-2 px-4 rounded"
      >
        Download Form as PDF
      </button>
      <div ref={formRef} id="form-to-print">
        <div className="py-[70px] px-[150px] font-sans">
          <div className="flex justify-center">
            <img
              className="w-[634px] h-[113px]"
              src={Drug_Logo}
              alt="drug-coop-logo"
            />
          </div>
          <div className="flex justify-center mt-8">
            <p className="font-semibold text-[#2D499B] text-[27px]">
              Technical Evaluation Committee Meeting Report
            </p>
          </div>
          <hr
            className="bg-[#2D499B] mx-auto mt-2 rounded-xl"
            style={{ height: "5px", width: "600px", border: "none" }}
          />
          <div className="mt-10 text-[18px] font-semibold">
            <div className="flex items-center mb-4">
              <p className="w-1/4 ">The Nature of the Committee:</p>
              <div className="flex gap-8">
                <div className="flex items-center gap-2">
                  <p>Cabinet Memo</p>
                  <div
                    className={`box-border h-6 w-6 p-4 border-2 rounded-lg border-black ${
                      isFilled("Cabinet Memo") ? "bg-black" : ""
                    }`}
                  ></div>
                </div>

                <div className="flex items-center gap-2">
                  <p>Departmental Pro.</p>
                  <div
                    className={`box-border h-6 w-6 p-4 border-2 rounded-lg border-black ${
                      isFilled("Department Pro.") ? "bg-black" : ""
                    }`}
                  ></div>
                </div>

                <div className="flex items-center gap-2">
                  <p>Business Pro</p>
                  <div
                    className={`box-border h-6 w-6 p-4 border-2 rounded-lg border-black ${
                      isFilled("Business Pro.") ? "bg-black" : ""
                    }`}
                  ></div>
                </div>

                <div className="flex items-center gap-2">
                  <p>Offline Pro</p>
                  <div
                    className={`box-border h-6 w-6 p-4 border-2 rounded-lg border-black ${
                      isFilled("Office Pro.") ? "bg-black" : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <p>Name of the procurement item:</p>
              <input
                className="h-[40px] w-full rounded-[23px] border border-black"
                value={meetingData.procurement_item}
              />
            </div>
            <div className="mb-4">
              <div className="flex">
                <p>Name of the procurement:</p>
                <input
                  className="ml-8 h-[40px] w-full rounded-[23px] border border-black"
                  value={meetingData.procurement_name}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center mb-4">
                <div className="flex items-center gap-[100px]">
                  <div>
                    <p>No of meetings:</p>
                  </div>
                  <input
                    className=" h-[40px] w-[20%] rounded-[23px] border border-black"
                    value={meetingData.meeting_number}
                  />
                  <div className="flex gap-1">
                    <div className="flex items-center gap-2">
                      <div className="box-border h-2 w-2 p-4 border-2 rounded-lg border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border h-2 w-2 p-4 border-2 rounded-lg border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="box-border rounded-lg h-2 w-2 p-4 border-2 border-black"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-5">
                <p>Purpose/purposes:</p>
              </div>
              <input
                className="border border-black h-[100px] w-[100%] rounded-[23px] mt-4"
                value={meetingData.purpose}
              />

              <div className=" gap-4 items-center mt-4">
                <p>Participated Technical Committee Members</p>
                <div className=" box-border w-full p-4 border-2 rounded-[30px] border-black">
                  {institute.map((institute, index) => (
                    <div key={index}>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p>Name</p>
                          <input
                            className="border border-black rounded-[30px] h-10 w-[100%] mt-4"
                            value={institute.name}
                          ></input>
                        </div>
                        <div>
                          <p>Committee Role</p>
                          <input
                            className="border border-black rounded-[30px] h-10 2-[100%] mt-4"
                            value={institute.role}
                          ></input>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-4">
                <p className="mb-5 font-semibold">Key Observations</p>
                <p className="text-sm mt-2">
                  (key observations can be shown below.  More details with
                  references can be given in annexure). Some essential
                  information should be made as follows. 
                </p>
                <p className="text-sm mt-5">
                  (a) Details of the meeting proceedings and comments on matters
                  which arising from the previous meeting. (if any)
                </p>

                <input
                  className="h-[40px] mt-5 w-full rounded-[23px] border border-black"
                  value={meetingData.previous_meeting_comments}
                />
              </div>

              <div className=" mt-4">
                <p className="text-sm mt-5">
                  (b) If any ideas on the attendance/ absence  of committee
                  members
                </p>
                <input
                  className="mt-5 border border-black rounded-[23px] h-[40px] w-[100%]"
                  value={meetingData.attendance_comments}
                />
              </div>

              <div className=" gap-4 items-center mt-4">
                <p className="text-sm mt-5">
                  (c) Comments on the entire procurement process
                </p>
                <input
                  className="mt-2 border border-black rounded-[23px] h-[40px] w-[100%]"
                  value={meetingData.procurement_comments}
                />
              </div>

              <div className=" gap-4 items-center mt-4">
                <p className="text-sm mt-5">
                  (d) Documents presented and subjected to discussion
                </p>
                <input
                  className="mt-2 border border-black rounded-[23px] h-[40px] w-[100%]"
                  value={meetingData.documents_discussed}
                />
              </div>

              <div className=" gap-4 items-center mt-4">
                <p className="text-sm mt-5">
                  (e) Special strategies/methods used (if any)
                </p>
                <input
                  className=" border border-black rounded-[23px] h-[40px] w-[100%] mt-4"
                  value={meetingData.special_strategies}
                />
              </div>
              <div className="flex justify-start">
                <hr
                  className="bg-black mx-auto mt-2 rounded-xl"
                  style={{ height: "5px", width: "600px", border: "none" }}
                />
              </div>
              <p className="text-sm mt-6">
                (8) Mention about the pre-qualification document verification,
                pre- qualification evaluation, bid document evaluation and
                awarding contracts.
              </p>

              <div>
                <div>
                  <p>Summary on key decisions taken</p>
                  <input
                    className="border border-black rounded-[30px] h-[222px] w-[100%] mt-4"
                    value={meetingData.key_decisions}
                  ></input>
                  <p className="text-sm mt-5">
                    (Summary of key decision / observations should be mentioned
                    here. Details may be attached with references) some
                    essential information should be mentioned in respectively
                    are given below.
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <p>
                  01) For document verification: usage of standard bid documents
                  and accuracy of the bid documents.
                </p>
                <input
                  className="border border-black rounded-[30px] h-10 w-[100%] mt-4"
                  value={meetingData.bid_document_verification}
                ></input>
              </div>

              <div className="mt-5">
                <p>
                  02) Total number of applications received for pre-qualified
                  and short listing names and addresses of bidders. 
                </p>
                <input
                  className="border border-black rounded-[30px] h-10 w-[100%]"
                  value={meetingData.applications_received}
                ></input>
              </div>

              <div className="mt-5">
                <p>
                  03) Recommendations for awarding of contracts. Name and
                  address of successful bidder, value of contract ( if relevant/
                  in different currencies) 
                </p>
                <input
                  className="border border-black rounded-[30px] h-10 w-[100%]"
                  value={meetingData.award_recommendations}
                ></input>
              </div>

              <div className="mt-5">
                <p>
                  04) Clearly mention the reasons given to the procurement
                  entity for interim decisions.
                </p>
                <input
                  className="border border-black rounded-[30px] h-10 w-[100%]"
                  value={meetingData.interim_decision_reasons}
                ></input>
              </div>

              <div className="mt-5">
                <p>
                  05) Justification for their decision/ decisions if the
                  committee members desired to be included.
                </p>
                <input
                  className="border border-black rounded-[30px] h-10 w-[100%]"
                  value={meetingData.decision_justifications}
                ></input>
              </div>

              <div className=" gap-4 items-center mt-4">
                <p>Tender Opening Committee</p>
                <div className="grid grid-cols-3 grid-flow-row gap-4 box-border w-full p-4 border-2 rounded-[30px] border-black">
                  <div>
                    <p>Committee Role</p>
                    <input
                      className="border border-black rounded-[30px] h-10 w-[100%]"
                      value={meetingData.committee_role}
                    ></input>
                  </div>
                  <div>
                    <p>Name</p>
                    <input
                      className="border border-black rounded-[30px] h-10 w-[100%]"
                      value={meetingData.name}
                    ></input>
                  </div>
                  <div>
                    <p>Whether agreed with the above decision.</p>
                    <div className="border border-black rounded-[30px] h-[80px]"></div>
                  </div>

                  <div className="border border-black rounded-[30px] h-10"></div>
                  <div className="border border-black rounded-[30px] h-10"></div>
                  <div className="border border-black rounded-[30px] h-[80px]"></div>
                </div>
              </div>

              <div className=" gap-4 items-center mt-4">
                <p>Other Notes</p>
                <input className="box-border w-full p-4 border-2 h-[122px] rounded-[30px] border-black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tec_Meeting_Report;
