/** @format */

import React, { useState, useEffect, useCallback } from "react";

const BranchReq_Permissions = ({ onPermissionsChange, userPermissions }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  //that shoulde change
  useEffect(() => {
    if (userPermissions && userPermissions.length > 0) {
      const initialCheckedValues = userPermissions.reduce((acc, curr) => {
        acc[String(curr)] = true;
        return acc;
      }, {});
      setCheckedValues(initialCheckedValues);
      console.log("Initialized checked values:", initialCheckedValues);
    }
  }, [userPermissions]);

  const permissionHierarchy = {
    18: { children: [270] },
    270: { children: [1995, 1996, 1997, 1998] },
    1996: { children: [2740] },
    1997: { children: [2750] },
    1998: { children: [2760] },

    // Add the rest of your permissions here
  };

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[18].children.includes(perm)
      ) &&
      !selectedPermissions.includes(19)
    ) {
      selectedPermissions.push(19);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline"
            Value="1"
          >
            Branch Requests
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Branch Requests
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1995"
                      checked={!!checkedValues[1995]}
                      onChange={() => handleCheckboxChange(1995)}
                    />
                    Create Branch request
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1996"
                      checked={!!checkedValues[1996]}
                      onChange={() => handleCheckboxChange(1996)}
                    />
                    View Branch Request
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2740"
                          checked={!!checkedValues[2740]}
                          onChange={() => handleCheckboxChange(2740, 1996)}
                        />
                        View
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1997"
                      checked={!!checkedValues[1997]}
                      onChange={() => handleCheckboxChange(1997)}
                    />
                    View Approved Branch request
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2750"
                          checked={!!checkedValues[2750]}
                          onChange={() => handleCheckboxChange(2750, 1997)}
                        />
                        View
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1998"
                      checked={!!checkedValues[1998]}
                      onChange={() => handleCheckboxChange(1998)}
                    />
                    History Log of Recevied Branch Request
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2760"
                          checked={!!checkedValues[2760]}
                          onChange={() => handleCheckboxChange(2760, 1998)}
                        />
                        View History Log
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BranchReq_Permissions;
