import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import Notification from "../../../components/notification/notification";

function FuelConsumptionPopup({ togglePopup }) {
  const [fuelConsumptionDetails, setFuelConsumptionDetails] = useState({
    vehicle_id: "",
    fuel_qty: "",
    distance_traveled: "",
    fuel_efficiency: "",
    driverName: "",
    fuel_cost: "",
  });
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [VehicleNumbers, setVehicleNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [selectedVehicleIdOrName, setSelectedVehicleIDOrName] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });

  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;

    // Handle Notification Close
    const handleCloseNotification = () => {
      setNotification({ message: "", type: "" });
    };

  // get all vehicle details
  const getVehicleID = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/vehicle/get-all-vehicle-id`
      );
      const data = response.data;

      setVehicleNumbers(data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFuelConsumptionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "vehicle_id" && value.length > 0) {
      const filtered = VehicleNumbers.filter((emp) =>
        emp.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredVehicles(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  // Employee Number Auto suggestion
  const handleSuggestionClick = (suggestion) => {
    setFuelConsumptionDetails((prevDetails) => ({
      ...prevDetails,
      vehicle_id: suggestion,
    }));
    setShowSuggestions(false);
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    console.log(fuelConsumptionDetails);
    try {
      const response = await axios.post(
        `${backendUrl}/fuelsManagements/create`,
        fuelConsumptionDetails,
      );
  
      console.log(response.data);
  
      // Extract the message if it exists, otherwise use a fallback string
      const successMessage = response.data.message || "Data saved successfully!";
  
      setNotification({
        message: successMessage,
        type: "success",
      });
    } catch (error) {
      console.log(error);
      // If there's an error, extract a meaningful message or use a fallback
      const errorMessage =
        (error.response?.data?.message) ||
        "An unexpected error occurred";
  
      setNotification({
        message: errorMessage,
        type: "error",
      });
    }
  
    setIsSaveConfirmationOpen(false);
  };
  

  const handleResetForm = () => {
    setFuelConsumptionDetails({
      vehicle_id: "",
      fuel_qty: "",
      distance_traveled: "",
      fuel_efficiency: "",
      driverName: "",
      fuel_cost: "",
    });
    setIsResetConfirmationOpen(false);
  };

  useEffect(() => {
    getVehicleID();
  }, []);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add fuel consumption Data</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>
        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Vehicle ID  */}
              <div className="relative">
                <label className="block text-gray-700">Vehicle ID *</label>
                <input
                  type="text"
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelConsumptionDetails.vehicle_id}
                  onChange={handleInputChange}
                />
                {showSuggestions && filteredVehicles.length > 0 && (
                  <ul className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg max-h-40">
                    {filteredVehicles.map((suggestion, index) => (
                      <li
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Fuel Quantity (Liters) * */}
              <div>
                <label className="block text-gray-700">
                  Fuel Quantity (Liters) *
                </label>
                <input
                  type="text"
                  name="fuel_qty"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelConsumptionDetails.fuel_qty}
                  onChange={handleInputChange}
                />
              </div>
              {/* Distance Traveled */}
              <div>
                <label className="block text-gray-700">
                  Distance Traveled (km)*
                </label>
                <input
                  type="text"
                  name="distance_traveled"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelConsumptionDetails.distance_traveled}
                  onChange={handleInputChange}
                />
              </div>
              {/* Fuel Efficiency */}
              <div>
                <label className="block text-gray-700">
                  Fuel Efficiency (km/liter)
                </label>
                <input
                  type="text"
                  name="fuel_efficiency"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelConsumptionDetails.fuel_efficiency}
                  onChange={handleInputChange}
                />
              </div>
              {/* Driver Name */}
              <div>
                <label className="block text-gray-700">Driver Name</label>
                <input
                  type="text"
                  name="driverName"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelConsumptionDetails.driverName}
                  onChange={handleInputChange}
                />
              </div>
              {/* Fuel Cost */}
              <div>
                <label className="block text-gray-700">Fuel Cost</label>
                <input
                  type="text"
                  name="fuel_cost"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={fuelConsumptionDetails.fuel_cost}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

     
    </div>
  );
}

export default FuelConsumptionPopup;
