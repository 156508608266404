/** @format */

import React, { useState, useEffect } from "react";
import Apply_Leave from "../apply_leave";
import { FaBagShopping } from "react-icons/fa6";
import Leave_request_table from "./leave_request_table";
import moment from "moment";
import { TiTick } from "react-icons/ti";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Leave_Request = () => {

  const [submittedFiles, setSubmittedFiles] = useState([]);
 
  const {REACT_APP_API_ENDPOINT} = process.env;
  const fetchLeaveData = () => {
    fetch(`${REACT_APP_API_ENDPOINT}/v1/hris/leave/getleave`)
      .then((response) => response.json())
      .then((data) => {
        setLeaveData(data);
        setSubmittedFiles(new Array(data.length).fill(null));
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  
  useEffect(() => {
    fetchLeaveData();
  }, []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] =
    useState("All Departments");

  const [searchInput, setSearchInput] = useState("");
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };
  // Fetch leave data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/v1/hris/leave/getleave`);
        const data = await response.json();
        setLeaveData(data);
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };
    fetchData();
  }, []);
  // Calculate counts
  const receivedLeaveCount = leaveData.length;
  const acceptedLeaveCount = leaveData.filter(
    (leave) => leave.approved_status_1 === "APPROVED"
  ).length;
  const rejectedLeaveCount = leaveData.filter(
    (leave) => leave.approved_status_1 === "REJECTED"
  ).length;
  const handleSearchChange = (e) => {
    console.log({ handleSearchChange });
    setSearchInput(e.target.value);
  };
  return (
    <div className="bg-background overflow-y-auto h-screen">
      <div className="mx-6 mt-5">
        <div className="mt-6 flex justify-between">
          <div>
            <p className="text-[30px] font-semibold">Leave Request</p>
          </div>
        </div>
        <div className="flex justify-between items-center mt-5">
          <div className="grid grid-cols-3 grid-flow-row gap-8 h-[100px] w-[900px]">
            {/* card1 */}
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <FaBagShopping className="w-8 h-8" />
                </div>
                <div>
                  <p className="font-semibold text-green-400">Count</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Received Leave Requests</p>
              <p className="text-[30px] font-semibold">{receivedLeaveCount}</p>
            </div>
            {/* card2 */}
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <TiTick className="w-8 h-8" />
                </div>
                <div className="">
                  <p className="font-semibold text-green-400">Count</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Accepted Leave Requests</p>
              <p className="text-[30px] font-semibold">{acceptedLeaveCount}</p>
            </div>
            {/* card3 */}
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <IoMdCloseCircleOutline className="w-8 h-8" />
                </div>
                <div className="">
                  <p className="font-semibold text-green-400">Count</p>
                </div>
              </div>
              <p className="mt-5 font-semibold">Rejected Leave Requests</p>
              <p className="text-[30px] font-semibold">{rejectedLeaveCount}</p>
            </div>
          </div>

          <div>
            <Apply_Leave onSuccess={fetchLeaveData}/>
          </div>
        </div>
        <div>
          <Leave_request_table searchInput={searchInput} />
        </div>
      </div>
    </div>
  );
};
export default Leave_Request;
