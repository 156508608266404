import React, { useState, useEffect } from "react";
import Speaker from "../../../Assets/icons/speaker.png";

const fetchCardData = async (id) => {
  // Replace this URL with your actual API endpoint
  const response = await fetch(`new-pos-endpoint`);
  const data = await response.json();
  return data;
};

const Card = ({ id }) => {
  const [data, setData] = useState({ customerName: "", posId: "" });

  useEffect(() => {
    const getData = async () => {
      const result = await fetchCardData(id);
      setData(result);
    };
    getData();
  }, [id]);

  return (
    <div className="ml-[5%] flex gap-10 items-center mt-6">
      <div className="shadow-lg bg-white w-[800px] h-[150px] rounded-[22px] mt-5 p-6 flex items-center gap-[80px]">
        <img src={Speaker} alt="speaker" className="w-[150px] h-[120px]" />
        <div className="flex flex-col gap-6">
          <div className="flex items-center">
            <label className="text-[20px] font-bold mr-2">
              Customer Name:{" "}
            </label>
            <input
              className="border border-black w-[304px] h-[40px] rounded-[30px]"
              value={data.customerName}
              readOnly
            />
          </div>
          <div className="flex items-center">
            <label className="text-[20px] font-bold mr-2">POS ID: </label>
            <input
              className="border ml-[18%] border-black w-[304px] h-[40px] rounded-[30px]"
              value={data.posId}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 grid-flow-row gap-y-3">
        <div>
          <button className="bg-[#EB7700] w-[144px] p-2 rounded-[18px] text-[20px] text-white font-bold">
            View
          </button>
        </div>
        <div>
          <button className="bg-[#5B5B5B] w-[144px] p-2 rounded-[18px] text-[20px] text-white font-bold">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const MobilePos = () => {
  const cardIds = [1, 2, 3]; // Replace with actual IDs

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div className="ml-6">
        <p className="text-black text-[22px] font-semibold font-sans mt-8 ml-6">
          Mobile POS
        </p>
        {cardIds.map((id) => (
          <Card key={id} id={id} />
        ))}
      </div>
    </div>
  );
};

export default MobilePos;