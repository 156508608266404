import React, { useState, useEffect } from "react";
import PDF from "../../../../Assets/icons/png.png"; // Update the path to your PDF icon image
import briefcaseIcon from "../../../../Assets/icons/briefcase-06.png";
import officechair from "../../../../Assets/icons/office-chair.png";
import alert from "../../../../Assets/icons/alert-diamond.png";
import alarm from "../../../../Assets/icons/alarm-clock.png";
import beach from "../../../../Assets/icons/beach.png";

const ConfirmationModal = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
      <div className="relative z-50 w-auto max-w-sm mx-auto my-6">
        <div className="relative bg-white border-0 rounded-[30px] shadow-lg outline-none focus:outline-none">
          <div className="relative p-6">
            <div>
              <button
                className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 mt-3 mr-3 rounded-[20px]"
                onClick={onCancel}
              >
                X
              </button>
            </div>
            <div className="flex justify-center">
              <div className="bg-primary w-[48px] h-[11px] rounded-[22px]"></div>
            </div>
            <p className="my-4 text-blueGray-500 text-[30px] text-headerColor leading-relaxed flex justify-center font-bold">
              {message}
            </p>
          </div>
          <div className="flex items-center justify-center gap-[100px] p-6">
            <div>
              <button
                className="text-black bg-[#E2E2E2] rounded-[22px] font-bold  px-6 py-2 text-[22px] outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onCancel}
              >
                No
              </button>
            </div>
            <div>
              <button
                className="bg-[#E0EEDF] text-black active:bg-green-600 font-bold text-[22px] px-6 py-2 rounded-[22px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Approval1Table = () => {
  const [data, setData] = useState([]);
  const [leavecountdata, setLeavecountData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveTypeFilter, setLeaveTypeFilter] = useState("");
  const [employeeFilter, setEmployeeFilter] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    itemId: null,
    newStatus: "",
    message: "",
    item: null,
  });

  const [reclaimPopupOpen, setReclaimPopupOpen] = useState(false);
  const [reclaimData, setReclaimData] = useState([]);
  const [reclaimConfirmationDialog, setReclaimConfirmationDialog] = useState({
    isOpen: false,
    itemId: null,
    newStatus: "",
    message: "",
  });

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/getleaveapprove1`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const fetchLeaveCountData = async (employee_no) => {
    try {
      const response = await fetch(
        ` ${REACT_APP_API_ENDPOINT}/v1/hris/leave/getleavecounts?employee_no=${employee_no}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setLeavecountData(result);
    } catch (err) {
      console.error("Error fetching leave count data:", err);
    }
  };

  const fetchReclaimData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/getreclaimleave`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setReclaimData(result);
    } catch (err) {
      console.error("Error fetching reclaim data:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [REACT_APP_API_ENDPOINT]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLeaveTypeChange = (e) => {
    setLeaveTypeFilter(e.target.value);
  };

  const handleEmployeeChange = (e) => {
    setEmployeeFilter(e.target.value);
  };

  const handleApprovalChange = async (item, newStatus) => {
    const confirmationMessage =
      newStatus === "REJECT"
        ? "Are you sure you want to Reject?"
        : "Are you sure you want to Approve?";

    await fetchLeaveCountData(item.employee_no);

    setConfirmationDialog({
      isOpen: true,
      itemId: item.id,
      newStatus,
      message: confirmationMessage,
      item: item,
    });
  };

  const updateApprovalStatus = async (itemId, newStatus) => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/approve1`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: itemId,
            approved_status_1: newStatus,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const updatedItem = await response.json();
      setData((prevData) =>
        prevData.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );
      fetchData();
    } catch (err) {
      console.error("Failed to update approval status:", err);
    }
  };

  const confirmApprovalChange = async () => {
    const { itemId, newStatus } = confirmationDialog;
    await updateApprovalStatus(itemId, newStatus);
    setConfirmationDialog({
      isOpen: false,
      itemId: null,
      newStatus: "",
      message: "",
      item: null,
    });
  };

  const cancelApprovalChange = () => {
    setConfirmationDialog({
      isOpen: false,
      itemId: null,
      newStatus: "",
      message: "",
      item: null,
    });
  };

  const handleReclaimApproval = (id, newStatus) => {
    const confirmationMessage =
      newStatus === "REJECT"
        ? "Are you sure you want to reject this reclaim request?"
        : "Are you sure you want to approve this reclaim request?";

    setReclaimConfirmationDialog({
      isOpen: true,
      itemId: id,
      newStatus,
      message: confirmationMessage,
    });
  };

  const confirmReclaimApproval = async () => {
    const { itemId, newStatus } = reclaimConfirmationDialog;
    try {
      const response = await fetch(
        ` ${REACT_APP_API_ENDPOINT}/v1/hris/leave/approvereclaim1`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: itemId,
            reclaim_status_1: newStatus,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      fetchReclaimData();
      setReclaimConfirmationDialog({
        isOpen: false,
        itemId: null,
        newStatus: "",
        message: "",
      });
    } catch (err) {
      console.error("Failed to update reclaim status:", err);
    }
  };

  const cancelReclaimApproval = () => {
    setReclaimConfirmationDialog({
      isOpen: false,
      itemId: null,
      newStatus: "",
      message: "",
    });
  };

  const filteredData = data.filter((item) => {
    const employeeNo = item.employee_no || "";
    const leaveType = item.leave_type || "";
    const matchesSearchQuery = employeeNo
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesLeaveTypeFilter = leaveTypeFilter
      ? leaveType === leaveTypeFilter
      : true;
    const matchesEmployeeFilter = employeeFilter
      ? item.employee_no.toLowerCase().includes(employeeFilter.toLowerCase()) ||
        item.employee_fullname
          .toLowerCase()
          .includes(employeeFilter.toLowerCase())
      : true;
    return (
      matchesSearchQuery && matchesLeaveTypeFilter && matchesEmployeeFilter
    );
  });

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Approve One Leave Details Table
      </p>

      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center mt-[-50px]">
          <div>
            <div className="flex items-center gap-5">
              <div className="ml-6 mt-3">
                <input
                  className="w-[480px] h-[55px] rounded-[22px] placeholder:ml-5"
                  placeholder="Search By Employee Code"
                  value={employeeFilter}
                  onChange={handleEmployeeChange}
                  style={{ paddingLeft: "20px" }}
                />
              </div>
              <div>
                <button
                  className="text-headerColor font-bold"
                  onClick={() => {
                    setSearchQuery("");
                    setLeaveTypeFilter("");
                    setEmployeeFilter("");
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <div className="ml-6 mt-3">
                <select
                  className="w-[480px] h-[55px] rounded-[22px] bg-white"
                  value={leaveTypeFilter}
                  onChange={handleLeaveTypeChange}
                  style={{ paddingLeft: "20px" }}
                >
                  <option value="" disabled hidden>
                    Sort by Leave Type
                  </option>
                  <option value="">All</option>
                  {Array.from(new Set(data.map((item) => item.leave_type))).map(
                    (leaveType) => (
                      <option key={leaveType} value={leaveType}>
                        {leaveType}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="p-[50px] flex justify-end">
          <div>
            <div className="bg-white p-5 w-[354px] rounded-xl relative">
              <p className="font-bold font-sans text-[#797C80] text-[32px] ">
                View Requested <br />
                Reclaim leaves
              </p>
              <div className="flex justify-between items-center mt-2">
                <div>
                  <button
                    className="bg-primary p-2 w-[151px] font-sans font-semibold rounded-[50px] text-white mt-2"
                    onClick={() => {
                      setReclaimPopupOpen(true);
                      fetchReclaimData();
                    }}
                  >
                    View
                  </button>
                </div>
                <div>
                  <div className="bg-primary p-2 rounded-[22px] h-[37px]"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl ">
        <table className="table-auto border-none w-[95%]">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Employee NO</th>
              <th className="text-primary px-4 py-2">Leave Type</th>
              <th className="text-primary px-4 py-2">Full Name</th>
              <th className="text-primary px-4 py-2">Reason</th>
              <th className="text-primary px-4 py-2">Request Date</th>
              <th className="text-primary px-4 py-2">File</th>
              <th className="text-primary px-4 py-2">Approval</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" className="bg-white px-4 py-2 text-center">
                  Loading...
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="7" className="bg-white px-4 py-2 text-center">
                  Error: {error.message}
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td colSpan="7" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            ) : (
              filteredData.map((item) => (
                <tr key={item.id}>
                  <td className="bg-white px-4 py-2">{item.employee_no}</td>
                  <td className="bg-white px-4 py-2">{item.leave_type}</td>
                  <td className="bg-white px-4 py-2">
                    {item.employee_fullname}
                  </td>
                  <td className="bg-white px-4 py-2">{item.reason}</td>{" "}
                  <td className="bg-white px-4 py-2">{item.requested_date}</td>
                  <td className="bg-white px-4 py-2">
                    {item.leave_file_path ? (
                      <div className="h-8 mt-3 flex items-center">
                        <img
                          src={PDF}
                          alt="pdf-icon"
                          className="w-8 h-8 mr-2"
                        />
                        <p className="font-sans text-sm">
                          {item.leave_file_name}
                        </p>
                      </div>
                    ) : (
                      <div className="flex gap-3 items-center">
                        <p className="ml-10 text-[15px]">No file Include</p>
                      </div>
                    )}
                  </td>
                  <td className="bg-white px-4 py-2">
                    <select
                      value={item.approved_status_1 || "NOT APPROVED"}
                      onChange={(e) =>
                        handleApprovalChange(item, e.target.value)
                      }
                      className="rounded bg-white px-2 py-1"
                    >
                      <option value="NOT APPROVED">Not Approved</option>
                      <option value="APPROVED">Approved</option>
                      <option value="REJECT">Reject</option>
                    </select>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {confirmationDialog.isOpen && leavecountdata && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div>
            <div className="bg-white p-6 rounded-lg shadow-lg w-[1699px] h-[639px]">
              <p className=" text-2xl font-semibold mb-6 mt-[3%] ml-[6%]">
                Leaves Taken : Category Wise
              </p>
              <div className="flex gap-10 items-center justify-center mb-6">
                <div className="flex gap-10 items-center  ">
                  <p className="text-lg">Employee ID :</p>
                  <input
                    className="border rounded-lg p-2 text-center w-40"
                    value={confirmationDialog.item.employee_no}
                    readOnly
                  />
                </div>
                <div className="flex gap-10 items-center ">
                  <p className="text-lg">Employee Name :</p>
                  <input
                    className="border rounded-lg p-2 text-center w-[500px]"
                    value={confirmationDialog.item.employee_fullname}
                    readOnly
                  />
                </div>
                <div className="flex gap-10 items-center  ">
                  <p className="text-lg">Department :</p>
                  <input
                    className="border rounded-lg p-2 text-center w-60"
                    value={leavecountdata.actualLeave.department}
                    readOnly
                  />
                </div>
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="flex flex-col items-center border rounded-lg p-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="mr-2 border p-1 rounded-lg">
                      <img
                        src={briefcaseIcon}
                        alt="briefcase-icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <p className="text-lg font-bold text-green-600">
                      Remaining Count
                    </p>
                  </div>
                  <p className="text-xl mt-4">Short Leave Count</p>
                  <p className="text-3xl font-bold mt-2">
                    <span className="text-red-500">
                      {leavecountdata.currentLeave.shortleavecount}
                    </span>{" "}
                    /{" "}
                    <span className="text-black">
                      {leavecountdata.actualLeave.shortleavecount}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col items-center border rounded-lg p-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="mr-2 border p-1 rounded-lg">
                      <img
                        src={officechair}
                        alt="briefcase-icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <p className="text-lg font-bold text-green-600">
                      Remaining Count
                    </p>
                  </div>
                  <p className="text-xl mt-4">Half-Day Leave Count</p>
                  <p className="text-3xl font-bold mt-2">
                    <span className="text-red-500">
                      {leavecountdata.currentLeave.halfdaycount}{" "}
                    </span>{" "}
                    /{" "}
                    <span className="text-black">
                      {leavecountdata.actualLeave.halfdaycount}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col items-center border rounded-lg p-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="mr-2 border p-1 rounded-lg">
                      <img
                        src={alarm}
                        alt="briefcase-icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <p className="text-lg font-bold text-green-600">
                      Remaining Count
                    </p>
                  </div>
                  <p className="text-xl mt-4">Annual Leave Count</p>
                  <p className="text-3xl font-bold mt-2">
                    <span className="text-red-500">
                      {leavecountdata.currentLeave.anualleavecount}
                    </span>{" "}
                    /{" "}
                    <span className="text-black">
                      {leavecountdata.actualLeave.anualleavecount}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col items-center border rounded-lg p-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="mr-2 border p-1 rounded-lg">
                      <img
                        src={alert}
                        alt="briefcase-icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <p className="text-lg font-bold text-green-600">
                      Remaining Count
                    </p>
                  </div>
                  <p className="text-xl mt-4">Sick Leave Count</p>
                  <p className="text-3xl font-bold mt-2">
                    <span className="text-red-500">
                      {leavecountdata.currentLeave.medicalleavecount}
                    </span>{" "}
                    /{" "}
                    <span className="text-black">
                      {leavecountdata.actualLeave.medicalleavecount}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col items-center border rounded-lg p-4">
                  <div className="flex items-center justify-between w-full">
                    <div className="mr-2 border p-1 rounded-lg">
                      <img
                        src={beach}
                        alt="briefcase-icon"
                        className="w-6 h-6"
                      />
                    </div>
                    <p className="text-lg font-bold text-green-600">
                      Remaining Count
                    </p>
                  </div>
                  <p className="text-xl mt-4">Total Casual Leave Count</p>

                  <p className="text-3xl font-bold mt-2">
                    <span className="text-red-500">
                      {leavecountdata.currentLeave.casualleavecount}
                    </span>{" "}
                    /{" "}
                    <span className="text-black">
                      {leavecountdata.actualLeave.casualleavecount}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex justify-end mt-[10%]">
                <button
                  className="bg-[#E2E2E2] text-black px-4 py-2 rounded mr-2"
                  onClick={cancelApprovalChange}
                >
                  No
                </button>
                <button
                  className="bg-primary text-white px-4 py-2 rounded"
                  onClick={confirmApprovalChange}
                >
                  Yes, {confirmationDialog.message}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {reclaimPopupOpen && (
        <div className="fixed top-0 left-0 h-full w-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-[80%] h-[80%] relative">
            <button
              className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded-[20px]"
              onClick={() => setReclaimPopupOpen(false)}
            >
              X
            </button>
            <p className="text-2xl font-bold mb-4">Requested Reclaim Leaves</p>
            <div className="overflow-auto h-[calc(100%-80px)]">
              <table className="table-auto border-none w-full">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2">Leave ID</th>
                    <th className="px-4 py-2">Employee Number</th>
                    <th className="px-4 py-2">Leave Type</th>
                    <th className="px-4 py-2">Requested Date</th>
                    <th className="px-4 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reclaimData.length === 0 ? (
                    <tr>
                      <td colSpan="5" className="px-4 py-2 text-center">
                        No reclaim leave requests available
                      </td>
                    </tr>
                  ) : (
                    reclaimData.map((item) => (
                      <tr key={item.id}>
                        <td className=" px-4 py-2">{item.leave_id}</td>
                        <td className=" px-4 py-2">{item.employee_no}</td>
                        <td className=" px-4 py-2">{item.leave_type}</td>
                        <td className=" px-4 py-2">
                          {new Date(item.requested_date).toLocaleDateString()}
                        </td>
                        <td className=" px-4 py-2 justify-center flex gap-2">
                          <button
                            className="bg-primary text-white px-4 py-2 rounded"
                            onClick={() =>
                              handleReclaimApproval(item.id, "APPROVED")
                            }
                          >
                            Approve
                          </button>
                          <button
                            className="bg-red-600 text-white px-4 py-2 rounded"
                            onClick={() =>
                              handleReclaimApproval(item.id, "REJECT")
                            }
                          >
                            Reject
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {reclaimConfirmationDialog.isOpen && (
        <ConfirmationModal
          message={reclaimConfirmationDialog.message}
          onCancel={cancelReclaimApproval}
          onConfirm={confirmReclaimApproval}
        />
      )}
    </div>
  );
};

export default Approval1Table;
