/** @format */

import { Link } from "react-router-dom";
import React, { useState } from "react";
import usePermissions from "../../../components/permissions/permission";

const Sample_test_reports = () => {

  const { hasPermission } = usePermissions();

  return (
    <div className="h-screen overflow-y-auto bg-background">
      <div className="ml-[6%] mt-[5%] mr-[3%]">
        <p className="text-headerColor text-[28px] font-semibold">
          Training Management
        </p>
        <div className="grid grid-cols-7 gap-8 mt-8">
          {/* card1 */}

          {hasPermission(6000) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px] p-4 flex flex-col justify-between">
              <div className="text-[20px] font-bold">
                <p>Tender Quality Test Reports</p>
              </div>
              <div className="flex justify-center">
                <Link to="/quality-management/tender-quality-test-reports">
                  <div className="bg-primary text-white p-2 rounded-[20px]">
                    Click Here
                  </div>
                </Link>
              </div>
            </div>
          )}

          {/* card2 */}
          {hasPermission(6001) && (
            <div className="bg-white w-[220px] h-[200px] rounded-[30px] p-4 flex flex-col justify-between">
              <div className="text-[20px] font-bold">
                <p>Bulk Quality Test Reports</p>
              </div>
              <div className="flex justify-center mt-3">
                <Link to="/quality-management/bulk-quality-test-reports">
                  <div className="bg-primary text-white p-2 rounded-[20px]">
                    Click Here
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sample_test_reports;
