import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Drug_Logo from "../../../../../Assets/icons/drug-logo.png";
import { useLocation } from "react-router-dom";

const OpeningPricing = () => {
  const location = useLocation();

  const formdata = location.state.formData;
  const instituteNames = location.state.instituteNames;
  console.log("Institutes Name:", instituteNames);

  console.log("first", formdata);

  const formRef = useRef(null);

  const downloadPdf = () => {
    const input = formRef.current;
    html2canvas(input, { useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("OpeningPriceForm.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="flex justify-center mt-10">
      <button
        onClick={downloadPdf}
        className="bg-blue-500 text-white w-[100px] h-[100px] py-2 px-4 rounded"
      >
        Download Form as PDF
      </button>
      <div ref={formRef} id="form-to-print">
        <div className="py-[70px] px-[150px] font-sans">
          <div className="flex justify-center">
            <img
              className="w-[634px] h-[113px]"
              src={Drug_Logo}
              alt="drug-coop-logo"
            />
          </div>
          <div className="flex justify-center mt-8">
            <p className="font-semibold text-[#2D499B] text-[27px]">
              Opening Price Form
            </p>
          </div>
          <hr
            className="bg-[#2D499B] mx-auto mt-2 rounded-xl"
            style={{ height: "5px", width: "200px", border: "none" }}
          />
          <div className="mt-10 text-[18px] font-semibold">
            <div className="flex items-center mb-4">
              <p className="w-1/4 ">Tender ID :</p>
              <input
                className="h-[40px] w-full rounded-[23px] border border-black "
                value={formdata.tender_id}
              />
            </div>
            <div className="flex items-center mb-4">
              <p className="w-1/4 ">Section :</p>
              <input
                className="h-[40px] w-full rounded-[23px] border border-black"
                value={formdata.section}
              />
            </div>
            <div className="flex items-center mb-4">
              <div className="flex">
                <p className="w-1/3">Date of Bid Opening:</p>
                <input
                  className="ml-[150px] h-[40px] w-full rounded-[23px] border border-black"
                  value={formdata.bid_opening_date}
                />
              </div>

              <div className="flex">
                <p className="ml-[150px] w-1/4 ">Time</p>
                <input
                  className="ml-[100px] h-[40px] w-full rounded-[23px] border border-black"
                  value={formdata.bid_opening_time}
                />
              </div>
            </div>

            <div>
              <div className="flex ">
                <p>Reason of Tender Calling :</p>
                <input
                  className="ml-[75px] border border-black h-[40px] w-[100%] rounded-[23px]"
                  value={formdata.reason}
                />
              </div>

              <div className="flex mt-5 items-center">
                <p>Number of Institutions that call for prices:</p>
                <input
                  className="border border-black h-[40px] w-[100%] rounded-[23px]"
                  value={formdata.no_of_institutions}
                />
              </div>

              <div className="flex gap-4 items-center mt-4">
                <p>Number of firms submitted prices:</p>
                <input
                  className="border border-black rounded-[23px] h-[40px] w-full"
                  value={formdata.no_of_firms_submitted_prices}
                />
              </div>

              <div className="flex gap-4 items-center mt-4">
                <p>Letter File:</p>
                <input
                  className="ml-10 border border-black rounded-[23px] h-[40px] w-[100%]"
                  value={formdata.letter_file}
                />
              </div>

              <div className="flex gap-4 items-center mt-4">
                <p>Correspondence file no:</p>
                <input
                  className=" border border-black rounded-[23px] h-[40px] w-[100%]"
                  value={formdata.correspondence_file_no}
                />
              </div>

              <div className=" gap-4 items-center mt-4">
                <p>Name of the Institution :</p>
                <div className="mt-2 border border-black rounded-[23px] h-[80px] w-[100%]">
                  {instituteNames.map((institute, index) => (
                    <div key={index}>
                      <input value={institute} className="ml-2 mt-2"></input>
                    </div>
                  ))}
                </div>
              </div>
              <div className=" gap-4 items-center mt-4">
                <p>Tender Opening Committee</p>
                <div className="grid grid-cols-3 grid-flow-row gap-4 box-border w-full p-4 border-2 rounded-[30px] border-black">
                  <div>
                    <p>Committee Role</p>
                    <input
                      className="border border-black rounded-[30px] h-10"
                      value={formdata.committee_role}
                    ></input>
                  </div>
                  <div>
                    <p>Name</p>
                    <input
                      className="border border-black rounded-[30px] h-10"
                      value={formdata.committee_user_name}
                    ></input>
                  </div>
                  <div>
                    <p>Signature</p>
                    <div className="border border-black rounded-[30px] h-[80px]"></div>
                  </div>

                  <div className="border border-black rounded-[30px] h-10"></div>
                  <div className="border border-black rounded-[30px] h-10"></div>
                  <div className="border border-black rounded-[30px] h-[80px]"></div>
                </div>
              </div>

              <div className=" gap-4 items-center mt-4">
                <p>Other Notes</p>
                <input
                  className="box-border w-full p-4 border-2 h-[122px] rounded-[30px] border-black"
                  value={formdata.note}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpeningPricing;
