import React from "react";
import warning from "../../../Assets/icons/warning.png";

const ShortageItems = ({ shortageItems, onClose }) => {
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-white h-[600px] w-[500px] rounded-md">
                <div className="text-center">
                    <img
                        src={warning}
                        alt="Warning Icon"
                        className="w-20 h-20 mx-auto mt-4 mb-4"
                    />
                    <h2 className="text-lg font-bold">
                        You Got Shortage in Inventory{" "}
                    </h2>
                    <div className="overflow-auto max-h-[300px]">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="px-4 py-2">Item ID</th>
                                    <th className="px-4 py-2">Item Name</th>
                                    <th className="px-4 py-2">
                                        Required Quantity
                                    </th>
                                    <th className="px-4 py-2">
                                        Available Quantity
                                    </th>
                                    <th className="px-4 py-2">Shortfall</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shortageItems.map((item) => (
                                    <tr
                                        key={item.raw_item_id}
                                        className="border-b border-gray-200"
                                    >
                                        <td className="px-4 py-2">
                                            {item.raw_item_id}
                                        </td>
                                        <td className="px-4 py-2">
                                            {item.raw_item_name}
                                        </td>
                                        <td className="px-4 py-2">
                                            {item.raw_item_quantity}
                                            {item.raw_item_unit}
                                        </td>
                                        <td className="px-4 py-2">
                                            {item.available_quantity}
                                            {item.raw_item_unit}
                                        </td>
                                        <td className="px-4 py-2">
                                            {item.shortfall}
                                            {item.raw_item_unit}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-300 px-4 py-2 rounded-md"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShortageItems;
