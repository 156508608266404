import React from 'react';


const Loan_eligibility = () => {
  return (
    <div className="flex justify-center items-center min-h bg-gray-100">
      <div className="bg-white p-8 rounded-lg w-full max-w-3xl">
        <h2 className="text-2xl font-semibold mb-6">Create : Loan</h2>
        <form className="space-y-6">


          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Loan Amount</label>
              <input
                type="text"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Duration</label>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">Joining Date</label>
              <input
                type="date"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
             
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Department</label>
              <select
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              >
                <option>Department 01</option>
                <option>Department 02</option>
                <option>Department 03</option>
                <option>Department 04</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Total Annual Leave Quota</label>
              <input
                type="number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Total Medical Leave Quota</label>
              <input
                type="number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Total Sick Leave Quota</label>
              <input
                type="number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Total Special Leave Quota</label>
              <input
                type="number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Total Casual Leave Quota</label>
              <input
                type="number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
          </div>


          <div className="flex justify-between items-center">
         
            <button
              type="submit"
              className="px-4 py-2 bg-purple-500 text-white rounded-md shadow-sm hover:bg-purple-600"
            >
              Save
            </button>
          </div>

          
        </form>
      </div>
    </div>
  );
};





export default Loan_eligibility;
