/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import usePermissions from "../../../components/permissions/permission";

const Vouchers = () => {
  const { hasPermission } = usePermissions();
  const [decisionId, setdecisionId] = useState("");
  const [data, setdata] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [approvePR, setApprovePR] = useState([]);
  const navigate = useNavigate();

  const getButtonStyle = (status) => {
    switch (status) {
      case "APPROVED":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "REJECTED":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-pendingStat text-pendingStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      default:
        return ""; // Default style
    }
  };

  const getButtonStyleStatus = (decision) => {
    switch (decision) {
      case "CREATED TENDER":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "CREATED PO":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "CREATE A TENDER":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "CREATE A PO":
        return "bg-createdStat text-createdStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-pendingStat text-pendingStattxt border-none rounded-[9px] w-[150px] h-[33px] flex-shrink-0";
    }
  };

  useEffect(() => {
    const fetchPurchaseOrders = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/voucher/get-all-comleted-grn-po`
        );
        const data = await response.json();
        setPurchaseOrders(data);
        console.log("Purchase Orders data", data);
      } catch (error) {
        console.error("Error fetching PurchaseOrders:", error);
      }
    };

    fetchPurchaseOrders();
  }, []);

  const handleViewClick = (item) => {
    navigate("/voucher/create", { state: item });
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Vouchers
      </p>
      <div className="flex flex-col font-poppins font-bold mt-10 ml-16">
        <form className="form relative">
          <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
            <svg
              className="w-5 h-5 text-gray-700"
              aria-labelledby="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="16"
              width="17"
            >
              <path
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="1.333"
                stroke="currentColor"
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
              ></path>
            </svg>
          </button>
          <input
            value={decisionId}
            // onChange={handleTenderIDChange}
            type="text"
            required=""
            placeholder="Search..."
            className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
          />
          <button className="btn btn-primary text-[#797C80] hover:bg-gray-100">
            RESET
          </button>
        </form>
        <div className="bg-[#FFF] rounded-[20px] p-4 w-[97%] max-h-[500px] overflow-y-auto mt-5">
          {purchaseOrders.length > 0 ? (
            <table className="w-full border-collapse border-none">
              <thead>
                <tr className="text-left bg-[#FFF]">
                  <th className="px-4 py-2 font-normal">PO No</th>
                  <th className="px-4 py-2 font-normal">Supplier ID</th>
                  <th className="px-4 py-2 font-normal">Supplier Name</th>
                  <th className="px-4 py-2 font-normal">Total</th>
                  <th className="px-4 py-2 font-normal">Action</th>
                </tr>
              </thead>
              <tbody className="font-bold">
                {purchaseOrders.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">{item.po_id}</td>
                    <td className="px-4 py-2">{item.supplier_id}</td>
                    <td className="px-4 py-2">{item.supplier_name}</td>
                    <td className="px-4 py-2">{item.total}</td>
                    <td className="px-4 py-2">
                      {hasPermission(2610) && (
                        <button
                          className="text-approveStattxt hover:underline"
                          onClick={() => handleViewClick(item)}
                        >
                          View
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center text-gray-500 font-semibold">
              No data available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Vouchers;
