/** @format */

import React, { useState } from "react";
import Tick_Submit from "../../../../Assets/icons/Tick-Submit.png";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import Error_Msg from "../../../../Assets/icons/error-msg.png";
import usePermissions from "../../../../components/permissions/permission";

const OT = () => {
  const { hasPermission } = usePermissions();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // You can adjust the number of items per page here
  const [searchQuery, setSearchQuery] = useState(""); // State variable to hold the search query

  const otData = [
    { id: 1, empNo: "001", fullName: "Data 2", otHours: "Data 3" },
    { id: 1, empNo: "002", fullName: "Data 2", otHours: "Data 3" },
    // Add more data objects as needed
  ];

  const filteredData = otData.filter((item) =>
    item.empNo.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSend = () => {
    // Simulating backend response (Replace with actual fetch logic)
    const success = true; // Change to false to simulate error
    if (success) {
      setShowSuccessPopup(true);
    } else {
      setShowErrorPopup(true);
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };
  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="mt-6 ml-6 font-sans font-bold text-[25px] text-[#797C80]">
        Over Time
      </p>
      <div className="flex justify-between items-center">
        {/* secondary div */}
        <div>
          {/* <div>
            <div className="flex flex-col">
              <label
                htmlFor="nic_number"
                className="text-xl mb-2  font-bold text-[#797C80] font-sans"
              ></label>
              <select
                id={``}
                name={``}
                autoComplete=""
                className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              >
                <option className="font-sans-bold ">Department</option>
                <option>Department</option>
                <option>Active</option>
                <option>Inactive</option>
              </select>
            </div>
          </div> */}

          {/* New input field */}
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <form class="form relative">
              <button class="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  class="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                required=""
                placeholder="Search..."
                class="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
        </div>
        {hasPermission(2040) && (
          <div className="ml-[57%]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6 mt- ">
              <div
                key=""
                className="bg-white rounded-[32px] w-[350px] h-[180px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  {/* Conditional rendering based on header words */}
                  <h2 className="text-[20px] font-semibold mb-1 font-sans">
                    Monthly{" "}
                  </h2>
                  <h2 className="text-[20px] font-semibold mb-1 font-sans">
                    OT Reports
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                    onClick={handleSend} // Call handleSend when the button is clicked
                  >
                    Send
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>{" "}
      <div>
        <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl mr-[5%] ">
          <div>
            <p className="text-[16px] text-[black] mb-3">OT Sheet</p>
            <hr className="w-[5%] border-b-2 border-primary mb-5 " />
          </div>
          <table className="table-auto border-none ">
            <thead>
              <tr className="bg-white text-[20px]">
                <th className="text-primary px-4 py-2 ">Emp NO</th>
                <th className="text-primary px-4 py-2">Full Name</th>
                <th className="text-primary px-4 py-2">Total OT Hours</th>
              </tr>
            </thead>
            <tbody>
              {/* Display current items */}
              {currentItems.map((item) => (
                <tr className="text-center" key={item.id}>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.empNo}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.fullName}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {item.otHours}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-white p-[80px] rounded-[30px] relative">
            <div className="mb-5 flex justify-center">
              <div className="bg-primary w-[48px] h-[11px] rounded-[22px]"></div>
            </div>
            <button
              className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 mt-3 mr-3 rounded-[20px]"
              onClick={handleCloseSuccessPopup} // Call handleClosePopup to close the popup
            >
              X
            </button>
            <div className="bg-[#E0EEDF] rounded-[32px] p-10">
              <p className="text-[30px] text-headerColor font-bold text-center font-sans">
                Sent
              </p>
              <p className="text-[30px] font-bold text-headerColor font-sans">
                Successfully
              </p>
            </div>

            <div className="flex items-center ml-[35%] mt-5">
              <img
                className="w-[80px] h-[100px] text-center"
                src={Tick_Submit}
                alt="submit-tick"
              />
            </div>
          </div>
        </div>
      )}
      {/* Error Popup */}
      {showErrorPopup && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-white p-[80px] rounded-[30px] relative">
            <div className="mb-5 flex justify-center">
              <div className="bg-primary w-[48px] h-[11px] rounded-[22px]"></div>
            </div>
            <button
              className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 mt-3 mr-3 rounded-[20px]"
              onClick={handleCloseErrorPopup} // Call handleClosePopup to close the popup
            >
              X
            </button>
            <div className="bg-[#E0EEDF] rounded-[32px] p-10">
              <p className="text-[30px] font-bold text-center font-sans text-headerColor ">
                Sent
              </p>
              <p className="text-[30px] font-bold font-sans text-headerColor">
                Unsuccessful
              </p>
            </div>

            <div className="flex items-center ml-[35%] mt-5">
              <img
                className="w-[80px] h-[100px] text-center"
                src={Error_Msg}
                alt="Hazzard"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default OT;
