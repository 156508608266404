import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Notification from "../../../components/notification/notification";

const DetailsPreview = ({ detailsPopup, details }) => {
  console.log(details)
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [description, setDescription] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [row, setRow] = useState({}); // Initialize row as an empty object

  const checkPaymentEntry = async () => {
    const url = `${backendUrl}/paymentEntry/checkOrApprovePaymentEntry/${row.id}`;
    const user = getCookieValue("employee_fullname");
    const requestBody = {
      approval_status: "Checked",
      action_by: user,
      description: description,
    };
    try {
      const response = await axios.put(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
      setNotification({
        message: error.response.data.error,
        type: "error",
      });

      console.error("Error:", error);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const approvedFunction = async () => {
    const url = `${backendUrl}/paymentEntry/checkOrApprovePaymentEntry/${row.id}`;
    const user = getCookieValue("employee_fullname");

    const requestBody = {
      approval_status: "Approved",
      action_by: user,
      description: description,
    };

    try {
      // Using axios.put to send the PUT request with the request body
      const response = await axios.put(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      setNotification({
        message: error.response.data.error,
        type: "error",
      });
    }
  };

  const rejectedFunction = async () => {
    const url = `${backendUrl}/paymentEntry/checkOrApprovePaymentEntry/${row.id}`;
    const user = getCookieValue("employee_fullname");

    const requestBody = {
      approval_status: "Rejected",
      action_by: user,
      description: description,
    };

    try {
      // Using axios.put to send the PUT request
      const response = await axios.put(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      setNotification({
        message: error.response.data.error,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (details) {
      setRow(details);
    }
  }, [details]); // Add 'details' as a dependency

  // Function to determine which buttons to show based on approval_status
  const renderButtons = () => {
    const { approval_status } = row;

    if (approval_status === "Prepared") {
      return (
        <div className="max-w-xl w-[5000px]">
          {/* Centers the container */}
          <textarea
            id="description"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter a brief description of the action taken..."
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* Action Buttons */}
          <div className="mt-4 flex space-x-2">
            <button
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-gray-400"
              disabled={!row.approval_status}
              onClick={checkPaymentEntry}
              aria-label="Approve payment entry"
            >
              Ckeck
            </button>
            <button
              className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 disabled:bg-gray-400"
              disabled={!row.approval_status}
              onClick={rejectedFunction}
              aria-label="Reject payment entry"
            >
              Reject
            </button>
          </div>
        </div>
      );
    }

    if (approval_status === "Checked") {
      return (
        <>
          <div className="max-w-xl w-[5000px]">
            {/* Centers the container */}
            <textarea
              id="description"
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter a brief description of the action taken..."
              onChange={(e) => setDescription(e.target.value)}
            />
            {/* Action Buttons */}
            <div className="mt-4 flex space-x-2">
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-gray-400"
                disabled={!row.approval_status}
                onClick={approvedFunction}
                aria-label="Approve payment entry"
              >
                Approved
              </button>
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 disabled:bg-gray-400"
                disabled={!row.approval_status}
                onClick={rejectedFunction}
                aria-label="Reject payment entry"
              >
                Rejected
              </button>
            </div>
          </div>
        </>
      );
    }

    // If the status is 'Rejected', no buttons should be shown
    if (approval_status === "Rejected") {
      return null; // No buttons
    }

    return null; // Default: show no buttons if approval_status is not matched
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Payment Details</h2>
          <button
            type="button"
            onClick={detailsPopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <strong>Amount:</strong>{" "}
            {parseFloat(row.amount || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>

          <div>
            <strong>Approval Status:</strong>{" "}
            <span  
              className={
                row.approval_status === "Rejected" ? "text-red-500" : ""
              }
            >
              {row.approval_status || "N/A"}
            </span>
          </div>

          <div>
            <strong>Approved By:</strong> {row.approved_by || "N/A"}
          </div>
          <div>
            <strong>Approved Date:</strong> {row.approved_date || "N/A"}
          </div>
          <div>
            <strong>Checked By:</strong> {row.checked_by || "N/A"}
          </div>
          <div>
            <strong>Checked Date:</strong> {row.checked_date || "N/A"}
          </div>
          <div>

            <strong>Description:</strong> {row.approval_remark || "N/A"}

          </div>
          {/* <div>
            <strong>Document URL:</strong>{" "}
            <a
              href={row.document_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              View Document
            </a>
          </div> */}
          <div>
            <strong>Entity ID:</strong> {row.entity_id || "N/A"}
          </div>
          <div>
            <strong>Invoice ID:</strong> {row.invoice_id || "N/A"}
          </div>
          <div>
            <strong>Paid From Account:</strong>{" "}
            {row.paid_from_account_name || "N/A"}
          </div>
          <div>
            <strong>Paid To Account:</strong>{" "}
            {row.paid_to_account_name || "N/A"}
          </div>
          <div>
            <strong>Party Type:</strong> {row.party_type || "N/A"}
          </div>
          <div>
            <strong>Payment Date:</strong>{" "}
            {row.payment_date
              ? new Date(row.payment_date).toLocaleDateString()
              : "N/A"}
          </div>
          <div>
            <strong>Payment ID:</strong> {row.payment_id || "N/A"}
          </div>
          <div>
            <strong>Payment Method:</strong> {row.payment_method || "N/A"}
          </div>
          <div>
            <strong>Payment Type:</strong> {row.payment_type || "N/A"}
          </div>
          <div>
            <strong>PO ID:</strong> {row.po_id || "N/A"}
          </div>
          <div>
            <strong>POS ID:</strong> {row.pos_id || "N/A"}
          </div>
          <div>
            <strong>Prepared By:</strong> {row.prepared_by || "N/A"}
          </div>
          <div>
            <strong>Prepared Date:</strong>{" "}
            {row.prepared_date
              ? new Date(row.prepared_date).toLocaleString()
              : "N/A"}
          </div>
          <div>
            <strong>Rejected By:</strong> {row.rejected_by || "N/A"}
          </div>
          <div>
            <strong>Rejected Date:</strong> {row.rejected_date || "N/A"}
          </div>
          <div>
            <strong>Transaction Type:</strong> {row.transaction_type || "N/A"}
          </div>
          <div>
            <strong>Upload Time:</strong>{" "}
            {row.upload_time
              ? new Date(row.upload_time).toLocaleString()
              : "N/A"}
          </div>
        </div>

        {/* Conditionally render buttons */}
        <div className="flex justify-between">{renderButtons()}</div>
      </div>
    </div>
  );
};

export default DetailsPreview;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
