import { Link } from "react-router-dom";
import React from "react";
import usePermissions from "../../../components/permissions/permission";

const TrainingManagement = () => {
  const { hasPermission } = usePermissions();

  return (
    <div className="h-screen overflow-y-auto bg-background p-6">
      <h1 className="text-4xl font-bold text-[#071C50] mb-8">
        Training Management
      </h1>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Card 1 */}
        {hasPermission(6002) && (
          <Link
            to="/quality-management/training-management/completed-training"
            className="transform hover:scale-105 transition-transform duration-200"
          >
            <div className="bg-white rounded-3xl p-6 shadow-lg flex flex-col justify-between">
              <div className="min-h-[110px] min-w-[150px] mb-4">
                <h2 className="text-3xl text-gray-600 font-semibold mb-2">
                  Completed Trainings
                </h2>
              </div>
              <button className="bg-primary w-1/2 mx-auto text-white font-bold py-2 rounded-full hover:bg-primary-dark">
                Click Here
              </button>
            </div>
          </Link>
        )}

        {/* Card 2 */}
        {hasPermission(6003) && (
          <Link
            to="/quality-management/training-management/upcomming-training"
            className="transform hover:scale-105 transition-transform duration-200"
          >
            <div className="bg-white rounded-3xl p-6 shadow-lg flex flex-col justify-between">
              <div className="min-h-[110px] min-w-[150px] mb-4">
                <h2 className="text-3xl text-gray-600 font-semibold mb-2">
                  Upcoming Trainings
                </h2>
              </div>
              <button className="bg-primary w-1/2 mx-auto text-white font-bold py-2 rounded-full hover:bg-primary-dark">
                Click Here
              </button>
            </div>
          </Link>
        )}

        {/* Card 3 */}
        {hasPermission(6004) && (
          <div className="bg-white rounded-3xl p-6 shadow-lg flex flex-col justify-between transform hover:scale-105 transition-transform duration-200">
            {/* Total Completed Trainings */}
            <div className="mb-8 text-center">
              <h2 className="text-3xl text-gray-600 font-semibold mb-2">
                Total Completed Trainings
              </h2>
              <p className="text-4xl font-extrabold text-primary bg-gray-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto shadow-md">
                0
              </p>{" "}
              {/* Replace 20 with dynamic count */}
            </div>

            {/* Upcoming Trainings */}
            <div className="text-center">
              <h2 className="text-3xl text-gray-600 font-semibold mb-2">
                Total Upcoming Trainings
              </h2>
              <p className="text-4xl font-extrabold text-primary bg-gray-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto shadow-md">
                2
              </p>{" "}
              {/* Replace 5 with dynamic count */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingManagement;
