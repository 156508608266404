/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DialogEditForm = ({ onClose, tableDate, item, dropdown }) => {
  const [supplierNumber, setSupplierNumber] = useState(item.supplier_id);
  const [supplierName, setSupplierName] = useState(item.supplier_name);
  const [address, setAddress] = useState(item.supplier_address);
  const [phoneNo, setPhoneNo] = useState(item.supplier_contact_no);
  const [faxNo, setFaxNo] = useState(item.supplier_contact_no);
  const [email, setemail] = useState(item.supplier_email);
  const [vatNo, setvatNo] = useState(item.supplier_status);
  const [category, setcategory] = useState(item.supplier_category_id);
  const [date, setdate] = useState(new Date());
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleSubmit = async () => {
    const data = {
      supplier_id: supplierNumber,
      supplier_name: supplierName,
      supplier_address: address,
      supplier_contact_no: phoneNo,
      supplier_fax: faxNo,
      supplier_email: email,
      supplier_vat_no: vatNo,
      supplier_category_id: category,
      supplier_update_date: date.toLocaleDateString("en-GB"),
      supplier_update_user_id: "EMP-00001",
    };
    try {
      const response = await axios.put(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/bid_evaluation_form/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      alert("Success fully updated Suppliers Data");
      tableDate();
      onClose();
    } catch (error) {
      console.log(error);
      alert("Unable to update Suppliers Data");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[80%] h-[85%] min-h-[650px]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>

        <div className="flex justify-center items-center h-[80%]">
          <div className="bg-dialogBoxbg rounded-[19px] w-[90%] h-[95%] flex-shrink-0">
            <div className="flex justify-center items-center h-[100%]">
              <div className="bg-dialogBoxbg rounded-[19px] w-[100%] h-[100%] flex-shrink-0">
                <div className=" items-center justify-between grid grid-cols-4 mt-10 gap-4">
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Supplier Number:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={supplierNumber}
                      onChange={(e) => setSupplierNumber(e.target.value)}
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Supplier Name:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={supplierName}
                      onChange={(e) => setSupplierName(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Address:
                    </label>
                  </div>
                  <div>
                    {" "}
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Phone Number:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      FAX Number:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={faxNo}
                      onChange={(e) => setFaxNo(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Email Address:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      VAT NO:
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="border border-gray-300 rounded-[22px] px-2 py-2 ml-4 "
                      value={vatNo}
                      onChange={(e) => setvatNo(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="ml-4 text-black font-poppins font-bold text-xl ">
                      Categor:
                    </label>
                  </div>
                  <div>
                    <select
                      value={category}
                      onChange={(e) => setcategory(e.target.value)}
                      className="border border-gray-300 rounded-lg px-2 py-2 w-full text-xl font-bold"
                    >
                      {dropdown.map((category) => (
                        <option
                          key={category.supplier_category_id}
                          value={category.supplier_category_id}
                        >
                          {category.supplier_category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className=" justify jsutify-center mt-10">
                  <button
                    className=" text-white bg-primary rounded-[22px] font-bold min-w-24 text-[22px]"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sticky footer */}
      </div>
    </div>
  );
};

export default DialogEditForm;