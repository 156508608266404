import React from "react";

const PurchaseInvoiceDetailModal = ({ invoice, isOpen, onClose }) => {
    if (!isOpen || !invoice) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-2/3">
                <h2 className="text-2xl font-bold mb-4">Purchase Invoice Details</h2>

                {/* Invoice Details */}
                <p><strong>Invoice ID:</strong> {invoice.invoice_id}</p>
                <p><strong>PO ID:</strong> {invoice.po_id}</p>
                <p><strong>Invoice Date:</strong> {new Date(invoice?.invoice_date).toLocaleDateString()}</p>
                <p><strong>Total Amount:</strong> {invoice?.total_amount}</p>
                <p><strong>Settled Amount:</strong> {invoice?.settled_amount}</p>
                <p><strong>Unsettled Amount:</strong> {invoice?.unsettled_amount}</p>
                <p><strong>Debited account:</strong> {`${invoice?.debit_account_code} - ${invoice.debit_account_name}`}</p>
                <p><strong>Credited account:</strong> {`${invoice?.credit_account_code} - ${invoice.credit_account_name}`}</p>
                <p><strong>Transaction Type:</strong> {invoice?.transaction_type}</p>
                <p><strong>Entity ID (Supplier):</strong> {invoice?.entity_id}</p>
                <p><strong>Description:</strong> {invoice?.description}</p>
                <p><strong>Draft by:</strong> {`EMP No: ${invoice.draft_by_no} - ${invoice.draft_by_name}`} </p>
                <p><strong>Draft date:</strong> {invoice.draft_date ? new Date(invoice.draft_date).toLocaleDateString() : "N/A"} </p>
                <p><strong>Submitted by:</strong> {`EMP No: ${invoice.submitted_by_no} - ${invoice.submitted_name}`}</p>
                <p><strong>Submitted date:</strong> {invoice.submitted_date ? new Date(invoice.submitted_date).toLocaleDateString() : "N/A"} </p>
                <p><strong>Approved by:</strong> {`EMP No: ${invoice.approved_by_no} - ${invoice.approved_by_name}`}</p>
                <p><strong>Approved date:</strong> {invoice.approved_date ? new Date(invoice.approved_date).toLocaleDateString() : "N/A"} </p>
                <p><strong>Rejected by:</strong> {`EMP No: ${invoice.rejected_by_no} - ${invoice.rejected_by_name}`}</p>
                <p><strong>Rejected date:</strong> {invoice.rejected_date ? new Date(invoice.rejected_date).toLocaleDateString() : "N/A"} </p>

                {/* Display File Link if exists */}
                {invoice.file_url && (
                    <div>
                        <a
                            href={invoice.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                        >
                            View Attached Document
                        </a>
                    </div>
                )}

                {/* Close Button */}
                <button
                    className="mt-4 px-4 py-2 bg-blue-900 text-white rounded hover:bg-blue-700"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default PurchaseInvoiceDetailModal;
