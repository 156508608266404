import React, { useState } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";

const ReviewGuarantors = () => {
  // State for the entered employee ID
  const [employeeId, setEmployeeId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items per page

  // Sample data (replace it with your actual data)
  const data = [
    // Your data objects here
    {
      id: 1,
      empNo: "EMP001",
      employeeName: "John Doe",
      debtorName01: "Debtor 1",
      debtorId01: "DEB001",
      debtorName02: "Debtor 2",
      debtorId02: "DEB002",
    },

    {
      id: 1,
      empNo: "EMP002",
      employeeName: "Joh",
      debtorName01: "Debtor 1",
      debtorId01: "DEB001",
      debtorName02: "Debtor 2",
      debtorId02: "DEB002",
    },

    // Add more data objects as needed
  ];

  // Filter data based on the entered employee ID
  const filteredData = data.filter(
    (item) =>
      item.empNo.includes(employeeId) ||
      item.employeeName.includes(employeeId) ||
      item.debtorName01.includes(employeeId) ||
      item.debtorId01.includes(employeeId) ||
      item.debtorName02.includes(employeeId) ||
      item.debtorId02.includes(employeeId)
  );

  // Calculate indexes for current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="font-sans bg-background overflow-auto h-screen">
      {/* Your existing JSX */}
      <div>
        <p className="mt-6 ml-6 text-headerColor text-[30px] font-semibold">
          Review Already Assigned Guarantors
        </p>
      </div>

      <div>
        <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
          <form className="form relative">
            <input
              type="text"
              required=""
              placeholder="Search Employee ID"
              className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
          </form>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-[50px] rounded-xl">
        <table className="table-auto border-none w-[95%] rounded-2xl border-2">
          {/* Table headers */}
          <thead>
            <tr className="bg-white rounded-xl">
              <th className="text-primary px-4 py-2 w-[10%]">#</th>
              <th className="text-primary px-4 py-2 w-[16%]">EMP NO</th>
              <th className="text-primary px-4 py-2 w-[16%]">Employee Name</th>
              <th className="text-primary px-4 py-2 w-[16%]">
                Name of the Debtor 01
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">
                EMP ID of Debtor 01
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">
                Name of the Debtor 02
              </th>
              <th className="text-primary px-4 py-2 w-[16%]">
                EMP ID of Debtor 02
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody>
            {/* Map over the currentItems array */}
            {currentItems.map((item, index) => (
              <tr key={item.id}>
                <td className="bg-white px-4 py-2 w-[10%]">
                  {indexOfFirstItem + index + 1}
                </td>
                <td className="bg-white px-4 py-2 text-start w-[16%]">
                  {item.empNo}
                </td>
                <td className="bg-white px-4 py-2 w-[16%]">
                  {item.employeeName}
                </td>
                <td className="bg-white px-4 py-2 w-[16%]">
                  {item.debtorName01}
                </td>
                <td className="bg-white px-4 py-2 w-[16%]">
                  {item.debtorId01}
                </td>
                <td className="bg-white px-4 py-2 w-[16%]">
                  {item.debtorName02}
                </td>
                <td className="bg-white px-4 py-2 w-[16%]">
                  {item.debtorId02}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredData.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default ReviewGuarantors;
