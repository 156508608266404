import React from "react";
import tick from "../../../Assets/icons/tick.png";

const Inventory_Matched = ({ rawItemName }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md">
        <div className="text-center">
          <img src={tick} alt="Tick Icon" className="w-[200px] h-[200px]" />
          <p className="text-bold text-2xl">
            Ingredients are ready!!!, {rawItemName}{" "}
            {/* Access rawItemName prop */}
            <br /> and the stage is set for your masterpiece
          </p>
        </div>
      </div>
    </div>
  );
};

export default Inventory_Matched;
