import React, { useEffect, useState } from "react";
import "./addCustomer.css";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import Alert from "../../../components/alert";

export default function AddCustomer(props) {
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format the date to 'YYYY-MM-DD'
  const [userId, setUserId] = useState("USER-000000");

  const [id, setId] = useState(IdGenerate("CUSTOMER"));
  const [name, setName] = useState("");
  const [nic, setNic] = useState("");
  const [address, setAddress] = useState("");

  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [customerType, setCustomertype] = useState("");
  const [regNo, setRegNo] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [creditLimit, setCreditLimit] = useState("");

  const [nameStatus, setNameStatus] = useState(false);
  const [contactStatus, setContactStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [customerTypeStatus, setCustomerTypeStatus] = useState(false);
  const [regNoStatus, setRegNoStatus] = useState(false);
  const [paymentMethodStatus, setPaymentMethodStatus] = useState(false);
  const [creditLimitStatus, setCreditLimitStatus] = useState(false);

  const [regNoView, setRegNoView] = useState(false);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [condition, setCondition] = useState("");
  const [location, setLocation] = useState("");

  const [discount, setDiscount] = useState([]);

  // Fetch customer types on component mount
  useEffect(() => {
    const fetchCustomerTypes = async () => {
      try {
        const response = await fetch(
          `https://back-ayurveda.erp-quortech.com/v1/inventory/customer/customer-types`
        );
        const data = await response.json();
        setCustomerTypes(data); // Assuming the data is an array of customer types
      } catch (error) {
        console.error("Error fetching customer types:", error);
      }
    };

    fetchCustomerTypes();
  }, []);

  // Handle customer type selection change
  useEffect(() => {
    if (customerType) {
      const selectedCustomerType = customerTypes.find(
        (type) => type.customer_type === customerType
      );
      if (selectedCustomerType) {
        setCondition(selectedCustomerType.condition);
        setLocation(selectedCustomerType.location);

        // Map the rates to correctly populate the discount fields
        const mappedDiscount = selectedCustomerType.rates.map((rate) => {
          const [from, to] = rate.value_range.split("-"); // Split value_range into from and to
          return {
            from: parseFloat(from), // Convert from to a number
            to: parseFloat(to), // Convert to a number
            rate: rate.approved_rate,
          };
        });

        setDiscount(mappedDiscount); // Set the discount with the mapped rates
      }

      // Set RegNoView to true if customerType is 'doctor', otherwise set it to false
      setRegNoView(customerType === "Doctors");
    }
  }, [customerType, customerTypes]);

  const AddHandler = async () => {
    // Validation checks (same as before)

    // Find the selected customer type's ID
    const selectedCustomerType = customerTypes.find(
      (type) => type.customer_type === customerType
    );

    // Data to be sent in the request body
    const data = {
      customer_id: id,
      customer_name: name,
      customer_nic: nic,
      customer_address: address,
      customer_contact_no: contact,
      customer_email: email,
      customer_type: selectedCustomerType ? selectedCustomerType.id : null, // Use the customer type ID
      reg_no: regNo,
      customer_update_user_id: userId,
      customer_update_date: formattedDate,
      payment_method: paymentMethod,
      credit_limit: creditLimit,
      category: "registered",
    };

    // Log the data to console before sending the request
    console.log("Data to be sent:", data);

    if (
      nameStatus ||
      contactStatus ||
      emailStatus ||
      customerTypeStatus ||
      regNoStatus ||
      paymentMethodStatus
    ) {
      setAlert({
        status: "error",
        alert: "Please fill the required fields",
        show: true,
      });
    } else {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/customer/add`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (res.ok) {
          setAlert({
            status: "success",
            alert: "Customer added successfully",
            show: true,
          });
          setId(IdGenerate("CUSTOMER"));
          setName("");
          setNic("");
          setAddress("");
          setContact("");
          setEmail("");
          setCustomertype("");
          setRegNo("");
          setPaymentMethod("");
          setDiscount([]);
        }
      } catch (error) {
        console.log(error);
        setAlert({
          status: "error",
          alert: "Error adding customer",
          show: true,
        });
      }
    }
  };

  const CancelHandler = () => {
    setId(IdGenerate("CUSTOMER"));
    setName("");
    setNic("");
    setAddress("");
    setContact("");
    setEmail("");
    setCustomertype("");
    setRegNo("");
    setPaymentMethod("");
    setDiscount([]); // Reset discount on cancel
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center px-20 bg-background ">
      <Alert
        show={alert.show}
        alert={alert.alert}
        status={alert.status}
        ok={() => {
          setAlert({
            status: "",
            alert: "",
            show: false,
          });
        }}
      />
      <div className="w-full flex justify-start">
        <p className="header-1 mt-5">Add Customer</p>
      </div>

      <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center mx-16 mt-5">
        <p className="header-1">Enter Customer Details</p>
        <div className="flex items-center justify-between flex-wrap w-full gap-y-10 py-10">
          {/* Customer Name Input */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Customer Name :</p>
            <input
              className={
                nameStatus
                  ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold"
                  : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              }
              placeholder="Enter Customer Name *"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {/* NIC Input */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">NIC :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              placeholder="XXXXXXXXXXXX"
              type="text"
              value={nic}
              onChange={(e) => setNic(e.target.value)}
            />
          </div>
          {/* Address Input */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Address :</p>
            <input
              className="w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              placeholder="Enter Address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          {/* Contact Number Input */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Contact number :</p>
            <input
              className={
                contactStatus
                  ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold "
                  : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              }
              placeholder="Enter Contact Number *"
              type="number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          {/* E-mail Address Input */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">E-mail Address :</p>
            <input
              className={
                emailStatus
                  ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold"
                  : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              }
              placeholder="Enter Email Address *"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* Customer Type Dropdown */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Customer type :</p>
            <select
              className={
                customerTypeStatus
                  ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold "
                  : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              }
              value={customerType}
              onChange={(e) => setCustomertype(e.target.value)}
            >
              <option value="">select</option>
              {customerTypes.map((type) => (
                <option key={type.customer_type} value={type.customer_type}>
                  {type.customer_type}
                </option>
              ))}
            </select>
          </div>
          {/* Registration Number Input */}
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Reg. No. :</p>
            <input
              className={
                regNoStatus
                  ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold"
                  : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
              }
              placeholder="Enter Registration No"
              type="text"
              value={regNo}
              onChange={(e) => setRegNo(e.target.value)}
              disabled={!regNoView}
            />
          </div>
        </div>

        <div className="flex items-center justify-between w-full py-10">
          <div className="flex items-center justify-between w-[600px]">
            <p className="label-1">Payment Method :</p>
            <select
              className={
                paymentMethodStatus
                  ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold "
                  : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold "
              }
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">select</option>
              <option value="cash">Cash</option>
              <option value="credit">Credit</option>
            </select>
          </div>
          {/* Credit Limit Input (shown only if payment method is credit) */}
          {paymentMethod === "credit" && (
            <div className="flex items-center justify-between w-[600px]">
              <p className="label-1">Credit Limit :</p>
              <input
                className={
                  creditLimitStatus
                    ? "w-[285px] h-[46px] rounded-[23px] border-2 border-[#ff000036] px-4 font-bold"
                    : "w-[285px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                }
                placeholder="Enter Credit Limit"
                type="number"
                value={creditLimit}
                onChange={(e) => setCreditLimit(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] flex flex-col items-center justify-center mx-16 mt-5">
          <p className="header-1 mb-10">Enter Discount Details</p>

          {discount.length > 0
            ? discount.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center my-2"
                  >
                    <div className="flex flex-col gap-y-3">
                      <p className="label-1">From :</p>
                      <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold flex items-center">
                        {data.from}
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-3">
                      <p className="label-1">To :</p>
                      <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold flex items-center">
                        {data.to}
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-3">
                      <p className="label-1">Discount Rate : </p>
                      <p className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold flex items-center">
                        {data.rate}%
                      </p>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className="flex w-full items-center justify-end gap-x-10 my-10">
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={CancelHandler}
          >
            Cancel
          </button>
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
            onClick={AddHandler}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
