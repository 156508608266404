import React from "react";
import AnimatedCard from "../../../components/vehicleFleetCard/card"
import managerDispaches from '../../../Assets/vehicleFleetImg/managerdispsches.svg';
import GatepassDispaches from '../../../Assets/vehicleFleetImg/gatepassdispaches.svg';


const Home = () => {


  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      <AnimatedCard
        imageSrc= {managerDispaches}
        title="Manager Transportation Dispatches"
        link='/current-vehicle-dispatches/manager-transportation-dispatches'
       
      />

      {/* Card 2 */}
      <AnimatedCard
        imageSrc={GatepassDispaches}
        title="Gatepass Transportation Dispatches"
        link='/current-vehicle-dispatches/gatepass-transportation-dispatches'
      />


      {/* Card 3 */}
      {/* <AnimatedCard
        imageSrc={vehiclePartDetails}
        title="Vehicel Part Details"
        description="Feel the power of towering mountains.dhhhdfhhfff"
        buttonText="Vehicle Part Details"
        link='/vehicle-fleet-management/vehicle-part-details'  
      /> */}

    </div>
  );
};

export default Home;
