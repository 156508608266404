import { useNavigate } from "react-router-dom";

const AnimatedCard = ({ imageSrc, title, description, buttonText, link }) => {
  const navigate = useNavigate(); // Create the navigate function

  const handleCardClick = () => {
    if (link) {
      navigate(link); // Navigate to the route specified in the link prop
    }
  };

    return (
      <div
      className="w-80 h-96 bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl cursor-pointer"
      onClick={handleCardClick} // Redirect on card click
    >
      {/* Card Image */}
      <div className="w-full h-2/3">
        <img
          className="w-full h-full object-contain" // Ensures image fits inside the div without distortion
          src={imageSrc}
          alt="Card"
        />
      </div>
    
      {/* Card Content */}
      <div className="p-4 h-1/3 flex items-center justify-center">
        <h2 className="text-xl font-bold text-gray-800">{title || "Card Title"}</h2>
      </div>
    </div>
    
    );
  };

  export default AnimatedCard;