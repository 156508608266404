/** @format */

import React, { useEffect, useState, useRef } from "react";
import AsyncSelect from "react-select/async";
import { useReactToPrint } from "react-to-print";

import Arrow from "../../Assets/icons/drop down correct 1.png";
import Printer from "../../Assets/icons/print 1.png";
import Cookies from "js-cookie";
import axios from "axios";
import IdGenerate from "../../utils/id_generate";
// import { socket } from '../../socket';

// import TopNaw from '../../components/top nav/topNaw';

export default function BranchItemReturnReq() {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const userId = Cookies.get("employee_no"); // Format the date to ISO string
  const branchId = Cookies.get("branch");
  // const [userId, setUserId] = useState("USER-000000");
  const [userName, setUserName] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [netTotal, setNetTotal] = useState(0);

  const [reqID, setReqID] = useState("");

  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });
  useEffect(() => {
    setReqID(IdGenerate("RETURN"));
  }, []);

  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/pos/getBranchStockBatchByBatchId/${branchId}/${inputValue}`
        );

        console.log(res.data);

        // Extract relevant data from response to populate options
        const options = res.data.map((item) => ({
          value: item.item_id,
          label: `${item.inventory_batch_id} - ${item.item_name}`, // Format label as "item_batch_id - item_name"
          fullData: item, // Store full item data for later use
        }));
        // setItemOptions(options);
        // console.log(options)
        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      // setItemOptions([]);
      return [];
    }
  };
  // Function to handle item selection
  const handleItemSelect = (selectedOption) => {
    // Set the selected item data in tableData
    setTableData((prevData) => [...prevData, selectedOption.fullData]);
  };

  const [itemArrow, setItemArrow] = useState(false);

  // Counter to keep track of unique IDs

  const SubmitHandler = async () => {
    // Validation: Check if no items or any requested quantity is missing
    if (
      tableData.length === 0 ||
      tableData.some(
        (item) =>
          !item.item_requested_qty || parseFloat(item.item_requested_qty) <= 0
      )
    ) {
      window.alert(
        "Please input items and ensure each item has a requested quantity greater than 0."
      );
      return;
    }

    const items = tableData.map((item) => ({
      batch_id: item.inventory_batch_id,
      return_qty: parseFloat(item.item_requested_qty), // Ensure it's a number
      return_description: item.item_remarks,
    }));

    const data = {
      branch_id: branchId,
      return_by: userId,
      items,
    };

    console.log("send data", data);
    //check conditions

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/BracnReturn/add-stock-return`,
        data
      );
      console.log(res.data);
      console.log(res.status);

      if (res.status === 200 || res.status === 201) {
        console.log("hello:");
        window.alert("Successfully Added Branch Item Return Request");
        ResetHandler();
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };
  const ResetHandler = () => {
    setReqID(IdGenerate("RETURN")); // Reset the request ID with a new generated ID
    // setUserId("USER-000000");
    setDateFrom("");
    setSelectedItem(null);
    setTableData([]);
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };
  // Set the Date
  const BillSearchDateHandler = async (e) => {
    if (e.target.id === "dateFrom") {
      setDateFrom(e.target.value);
    }
  };

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  useEffect(() => {
    // Calculate net total from the line_amount values
    const total = tableData.reduce(
      (acc, item) => acc + (item.line_amount || 0),
      0
    );
    setNetTotal(total);
  }, [tableData]);

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Item Return Request From Branch</p>

      <div className="w-full flex items-center justify-center px-12 mt-[5%]">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item name :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 fill-white"
                : "mr-7 size-6 transition rotate-180 duration-500"
            }
          />
        </div>
      </div>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Available Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Return Request Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Units</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Price(LKR)
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Line Amount
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[120px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Action</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3 "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.current_qty}
                    </p>
                    <p className="w-[100px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_requested_qty}
                        min="0"
                        className="focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        onChange={(e) => {
                          let temp = [...tableData];
                          let inputValue = parseFloat(e.target.value);

                          // Validate input to block values higher than current_qty or less than 0
                          if (inputValue > item.current_qty) {
                            inputValue = item.current_qty; // Set to current_qty if input exceeds it
                          } else if (inputValue < 0) {
                            inputValue = 0; // Set to 0 if input is negative
                          }

                          // Update item_requested_qty with the validated value
                          temp[index].item_requested_qty = inputValue;

                          // Calculate line_amount
                          temp[index].line_amount =
                            inputValue * item.batch_unit_price;

                          setTableData(temp);
                        }}
                      />
                    </p>

                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.item_measure_unit}
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.batch_unit_price}
                    </p>
                    <p className="w-[170px] p1 text-[#656565] text-center">
                      <textarea
                        className="w-[170px] border border-[#656565] my-2 rounded-2xl"
                        value={item.item_remarks}
                        onChange={(e) => {
                          const temp = [...tableData];
                          temp[index].item_remarks = e.target.value;
                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      {item.line_amount}
                    </p>
                    <p className="w-[120px] p1 text-[#656565] text-center">
                      <button
                        className="w-[120px] h-[46px] rounded-[100px] bg-[#EDC2C2]"
                        onClick={() => {
                          let temp = [...tableData];
                          temp.splice(index, 1);
                          setTableData(temp);
                        }}
                      >
                        Delete
                      </button>
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end items-start px-16 pt-3 ">
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">{netTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
          >
            Print
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Cancel
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={SubmitHandler}
        >
          Submit
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
             
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
