import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaFilePdf } from "react-icons/fa"; // Importing an icon for the document

const DialogBoxInvoiceDocView = ({ onClose, data }) => {
  const [files, setFiles] = useState("");
  const [filepath, setFilePath] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleDocumentClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  console.log("File Path:", filepath);
  // Function to handle click outside the dialog
  const handleClickOutside = (event) => {
    const dialog = document.getElementById("dialogBox");
    if (dialog && dialog.contains(event.target)) {
      onClose();
    }
  };

  // Add event listener when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    const handelFileGet = async () => {
      const query = data;
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/purchase_order/search/invoice/${query}`
        );

        setFiles(response.data[0].invoice_file);
        setFilePath(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/purchase_order/get/file/` +
            response.data[0].invoice_file
        );
      } catch (error) {
        console.log(console.error(error));
      }
    };

    handelFileGet();
  }, [data]); // Add data as a dependency

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div
        id="dialogBox"
        className="bg-gray-800 bg-opacity-75 fixed inset-0"
      ></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[50%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-addBtn font-poppins font-semibold text-[26px] text-center mt-4">
            View
          </h2>
        </div>
        <div className="flex justify-center items-center mt-4 h-[80%]">
          <div className="bg-dialogBoxdocView rounded-[19px] w-[90%] h-[80%] flex-shrink-0">
            {files ? (
              <div className="flex items-center justify-center h-full">
                <FaFilePdf
                  className="text-red-500 cursor-pointer"
                  size={100}
                  onClick={() => handleDocumentClick(filepath)}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p>No document available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBoxInvoiceDocView;
