import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx"; // Import the xlsx library

const AccountTable = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const [seletedValue, setSeletedValue] = useState({
    start_date: "",
    end_date: "",
  });

  const [accounts, setAccounts] = useState([]);

  
  const [expandedAccounts, setExpandedAccounts] = useState({});

  const toggleAccount = (accountId) => {
    setExpandedAccounts((prev) => ({
      ...prev,
      [accountId]: !prev[accountId],
    }));
  };

  const renderAccounts = (accountList) => {
    return accountList.map((account, index) => (
      <React.Fragment key={account.account_id}>
        <tr
          className={`border-b ${
            index % 2 === 0 ? "bg-gray-50" : "bg-white"
          } hover:bg-gray-100 transition-all duration-150`}
        >
          <td
            className="px-6 py-4 text-sm font-medium text-gray-700 cursor-pointer flex items-center space-x-2"
            onClick={() => toggleAccount(account.account_id)}
          >
            {account.children.length > 0 ? (
              <span className="text-lg">
                {expandedAccounts[account.account_id] ? "▼" : "▶"}
              </span>
            ) : (
              <span className="text-lg text-transparent">▶</span>
            )}
            <span>{account.account_name}</span>
          </td>

          <td className="px-6 py-4 text-right text-sm font-medium text-gray-600 w-1/6">
            {parseFloat(account.total_debit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
          <td className="px-6 py-4 text-right text-sm font-medium text-gray-600 w-1/6">
            {parseFloat(account.total_credit).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>

        {expandedAccounts[account.account_id] &&
          account.children.length > 0 && (
            <tr>
              <td colSpan="3" className="px-6 py-4">
                <table className="min-w-full bg-gray-50 border rounded-lg">
                  <tbody>{renderAccounts(account.children)}</tbody>
                </table>
              </td>
            </tr>
          )}
      </React.Fragment>
    ));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const exportToExcel = () => {
    // Create an array of objects to represent the data in the Excel file
    const data = [];

    const extractAccounts = (accountList) => {
      accountList.forEach((account) => {
        data.push({
          AccountName: account.account_name,
          Debit: parseFloat(account.total_debit),
          Credit: parseFloat(account.total_credit),
        });
        // Check for children and recursively extract them
        if (account.children.length > 0) {
          extractAccounts(account.children);
        }
      });
    };

    extractAccounts(accounts); // Extract the account data

    const worksheet = XLSX.utils.json_to_sheet(data); // Convert to worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts"); // Append the worksheet to the workbook

    // Export the Excel file
    XLSX.writeFile(workbook, "Accounts_Trial_Balance.xlsx");
  };

  const trialBalanceData = async () => {
    try {
      const params = {
        ...seletedValue,
      };
      const response = await axios.get(
        `${backendUrl}/financeReports/getTrialBalance`,
        { params }
      );
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };
  useEffect(() => {
    trialBalanceData();
  }, [seletedValue]);
  

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">Trial Balance</p>
          </div>
          <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
            onClick={exportToExcel}
          >
            <IoAdd className="text-xl" />
            Excel Export
          </button>
        </div>
      </div>

      <div className="mt-5">
        <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:gap-4">
            <div className="w-full md:w-[250px] relative">
              <label htmlFor="start_date" className="block mb-2 text-sm font-medium text-black">
                Start Date
              </label>
              <input
                id="start_date"
                name="start_date"
                type="date"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                onChange={handleChange}
              />
            </div>
            <div className="w-full md:w-[250px] relative">
              <label htmlFor="end_date" className="block mb-2 text-sm font-medium text-black">
                End Date
              </label>
              <input
                id="end_date"
                name="end_date"
                type="date"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-8">
        <div className="max-h-[600px] overflow-y-auto">
          <table className="min-w-full bg-white border border-black mt-12">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Account Name
                </th>
                <th className="px-6 py-3 font-bold text-right text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Debit
                </th>
                <th className="px-6 py-3 font-bold text-right text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>{renderAccounts(accounts)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AccountTable;
