import React, { useState } from 'react';

import Success from './../Assets/icons/success.png';
import Unsuccess from './../Assets/icons/unsuccess.png';

export default function Alert(props) {
    


  return (
    <div className={props.show ? 'w-full min-h-[700px] h-full flex items-center justify-center z-30 fixed top-0  bg-[#8dd09032] '
    :'w-full min-h-[700px] h-full items-center justify-center z-10 absolute top-0  bg-[#8dd09032] hidden'}>
        <div className={props.status === "success" ? 'w-[500px] h-[400px] bg-white p-5 rounded-[25px] flex flex-col items-center justify-between border-[#3bffb4] border-[2px]':
        'w-[500px] h-[400px] bg-white p-5 rounded-[25px] flex flex-col items-center justify-between border-[#ff3939] border-[2px]'}>
            <h1 className='text-2xl font-bold text-center'>{props.alert}</h1>
            <img src={props.status ==="success"? Success : Unsuccess} alt="" className='w-[200px] h-[200px] mx-auto' />
            <button className={props.status === "success" ? 'bg-[#7179720a]  px-10 py-[8px] rounded-[15px] mt-5 border-[3px] border-[#8dd090] text-[#110606] text-[20px] font-bold':
            'bg-[#7179720a]  px-10 py-[8px] rounded-[15px] mt-5 border-[3px] border-[#ff4a4a] text-[#110606] text-[20px] font-bold'}
            onClick={props.ok}>Ok</button>
        </div>
    </div>
  )
}
