 

import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import "./inventoryRequest.css";
import axios from "axios";
import Arrow from "./../../../../Assets/icons/down-arrow.png";
import IdGenerate from "../../../../utils/id_generate";
import usePermissions from "../../../../components/permissions/permission";

export default function InventoryRequest() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");
  const [inventoryRequestId, setInventoryRequestId] = useState(
    IdGenerate("REQ")
  );
  const [requestDescription, setRequestDescription] = useState("");
  const [requestType, setRequestType] = useState("");

  const [jobPreviewShow, setJobPreviewShow] = useState(false);
  const [transferPreviewShow, setTransferPreviewShow] = useState(false);
  const [posPreviewShow, setPosPrviewShow] = useState(false);
  const [itemSelectShow, setItemSelectShow] = useState(false);

  const [searchJobShow, setSearchJobShow] = useState(false);
  const [searchTransferShow, setSearchTransferShow] = useState(false);
  const [searchPosShow, setSearchPosShow] = useState(false);
  const [searchItemShow, setSearchItemShow] = useState(false);

  const [tableData, setTableData] = useState([]);

  const customStyles = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "white",
      borderRadius: "100px",
      //   borderColor: state.isFocused ? 'grey' : 'red',
      //   border:state.isFocused ? 'none' : 'none',
      //   borderColor:'black'
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "Search",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  //item request
  const [items, setItems] = useState([]);
  const ItemSearchHandler = async (e) => {
    console.log(e);
    if (e !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/inventory/searchByPoIdAndStatus/${e}/ACTIVE/BRANCH-06247866 `
        );
        console.log(res.data);
        setItems(res.data);
        const options = res.data.map((item) => ({
          value: item.inventory_batch_id,
          label: item.item_name,
        }));
        return options;
      } catch (error) {}
    }
  };
  const ItemSelectHandler = async (item) => {
    console.log(item);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inventory/inventory/InventoryBatchId/${item.value}`
      );
      console.log(res.data);
      const data = [...tableData];
      data.push({
        item_name: res.data[0].item_name,
        item_inventory_batch_id: res.data[0].inventory_batch_id,
        available_qty: res.data[0].shadow_qty,
        qty: "",
        measure_unit: res.data[0].item_measure_unit,
      });
      setTableData(data);
      setRequestType("ITEM");
    } catch (error) {}
  };

  //pos request
  const [pos, setPos] = useState([]);
  const SearchPosHandler = async (e) => {
    if (e !== "") {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/pos/search/PosId/Status/${e}/PENDING`
        );
        console.log(res.data);
        const options = res.data.map((item) => ({
          value: item.pos_id,
          label: item.pos_id,
        }));
        return options;
      } catch (error) {}
    }
  };

  const [posPreviewData, setPosPreviewData] = useState([]);
  const PosSelectHandler = async (posId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/pos/getPos/${posId.value}`
      );
      console.log(res.data);
      const data = [...tableData];
      data.push({
        item_name: res.data[0].item_name,
        item_inventory_batch_id: res.data[0].inventory_batch_id,
        available_qty: res.data[0].shadow_qty,
        req_qty: res.data[0].pos_item_qty,
        qty: "",
        measure_unit: res.data[0].item_measure_unit,
      });
      setTableData(data);
    } catch (error) {}
  };
  const PosSelectBtnHandler = () => {
    //add data to table
    const data = [];
    data.push({
      item_name: posPreviewData[0].item_name,
      item_inventory_batch_id: posPreviewData[0].inventory_batch_id,
      available_qty: posPreviewData[0].shadow_qty,
      qty: posPreviewData[0].pos_item_qty,
      measure_unit: posPreviewData[0].item_measure_unit,
    });
    setTableData(data);
    setPosPrviewShow(false);
  };

  //job request

  //transfer request

  //request
  const RequestHandler = async () => {
    if (tableData.length > 0) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/inventoryRequest/add`,
          {
            inventory_request_type: requestType,
            inventory_request_id: inventoryRequestId,
            inventory_request_user_id: userId,
            inventory_request_date: formattedDate,
            inventory_request_status: "PENDING",
            inventory_request_description: requestDescription,
            inventory_request_items: tableData,
          }
        );
        if (res.status === 200 || res.status === 201) {
          alert("Request Added Successfully");
          setRequestType("");
          setRequestDescription("");
          setInventoryRequestId(IdGenerate("REQ"));
          setTableData([]);
        }
        console.log(res.data);
      } catch (error) {
        if (error.response.status === 409) {
          alert("Already Exist");
        } else if (error.response.status === 400) {
          alert("Not Added");
        } else if (error.response.status === 500) {
          alert("Internal Server Error");
        } else if (error.response.status === 404) {
          alert("Not Found");
        } else if (error.response.status === 403) {
          alert("Forbidden");
        } else if (error.response.status === 401) {
          alert("Unauthorized");
        }
      }
    }
  };

  const CancelHandler = () => {
    setTableData([]);
    setRequestType("");
    setRequestDescription("");
    setInventoryRequestId(IdGenerate("REQ"));
  };

  return (
    <>
      <div className="p-10 bg-background">
        <p className="header-1"> Inventory Request</p>

        <div className="w-full  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] flex flex-col py-5 px-10 ">
          <p className="header-1">Find Requests</p>
          <div className="w-full flex justify-between flex-wrap gap-y-10 my-5">
            <div className="flex items-center justify-between w-[650px]">
              <p className="label-1">Item Name :</p>
              <AsyncSelect
                className="w-80"
                styles={customStyles}
                loadOptions={ItemSearchHandler}
                onChange={ItemSelectHandler}
                value={items}
              />
            </div>
            <div className="flex items-center justify-between w-[650px]">
              <p className="label-1">Job ID :</p>
              <AsyncSelect
                className="w-80"
                styles={customStyles}
                loadOptions={SearchPosHandler}
                onChange={PosSelectHandler}
                value={items}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
          <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Available Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            {tableData.length > 0 && tableData[0].req_qty && (
              <div className="w-[250px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  req. Quantity
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
            )}
            <div className="w-[250px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Quantity</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[100px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Unit</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[150px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Action</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>

          <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div key={index} className="w-full  flex justify-around ">
                    <div className="w-10 flex items-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {index + 1}
                      </p>
                    </div>
                    <div className="w-[350px] flex  items-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_name}
                      </p>
                    </div>
                    <div className="w-[200px] flex items-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.available_qty}
                      </p>
                    </div>
                    {item.req_qty && (
                      <div className="w-[200px] flex items-center">
                        <p className="w-full p1 text-[#656565] text-center ">
                          {item.req_qty}
                        </p>
                      </div>
                    )}
                    <div className="w-[250px] flex  items-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        <input
                          className="w-full border border-[#797979] rounded-[40px]"
                          type="number"
                          value={item.qty}
                          onChange={(e) => {
                            const data = [...tableData];
                            data[index].qty = e.target.value;
                            setTableData(data);
                          }}
                        />
                      </p>
                    </div>
                    <div className="w-[100px] flex  items-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.measure_unit}
                      </p>
                    </div>
                    <div className="w-[150px] flex  items-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {hasPermission(2300) && (
                          <button
                            className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4"
                            onClick={() => {
                              const Data = [...tableData];
                              Data.splice(index, 1);
                              setTableData(Data);
                            }}
                          >
                            <p className="p2 text-white">Delete</p>
                          </button>
                        )}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>

        <div className="w-full flex justify-end items-center gap-x-10 my-10">
          {hasPermission(2301) && (
            <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4">
              <p className="p2 text-white" onClick={(e) => RequestHandler()}>
                Request
              </p>
            </button>
          )}
          <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4">
            <p className="p2 text-white" onClick={(e) => CancelHandler()}>
              Cancel
            </p>
          </button>
        </div>
      </div>

      {/* <div className={jobPreviewShow ? 'InventoryRequest-job-preview-div':'InventoryRequest-preview-hide'}>
        <p className='Title'>Job Request</p>
        <div className='Container'></div>
    </div>
    <div className={transferPreviewShow ? 'InventoryRequest-transfer-preview-div': 'InventoryRequest-preview-hide'}>
        <p className='Title'> Transfer Request</p>
        <div className='Container'></div>
    </div>
    <div className={posPreviewShow ? 'InventoryRequest-pos-preview-div':'InventoryRequest-preview-hide'}>
        <p className='Title'> Pos Request</p>
        <div className='Container'>

            <div className='InventoryRequest-pos-preview-info'>
                <div>
                    <label className='label'>Bill id </label>
                    <label className='label'>:</label>
                    <input className='form-input' value={posPreviewData.length>0 && posPreviewData[0].pos_id } onChange={()=>{}} disabled/>
                </div>
                <div>
                    <label className='label'>Bill issued Date </label>
                    <label className='label'>:</label>
                    <input className='form-input' value={posPreviewData.length>0 && posPreviewData[0].pos_date }  onChange={()=>{}} disabled/>
                </div>
                
            </div>
            <div className='InventoryRequest-pos-preview-table'>
            <table> 
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>item name</td>
                            <td>request_qty</td>
                            <td>measure_unit</td>
                        </tr>
                    </thead>
                    <tbody>
                        {posPreviewData.length > 0 ? posPreviewData.map((item, index)=>(
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item.item_name}</td>
                                <td>{item.pos_item_qty}</td>
                                <td>{item.item_measure_unit}</td>
                            </tr> )):null}
                    </tbody>
                </table>
            </div>
            <div className='InventoryRequest-pos-preview-btn'>
                <button className='btn-submit' onClick={()=>PosSelectBtnHandler()}>Select</button>
                <button className='btn-cancel'  onClick={()=>setPosPrviewShow(false)}>Cancel</button>
            </div>
        </div>
    </div>
    <div className={itemSelectShow ? 'InventoryRequest-items-select-div':'InventoryRequest-preview-hide'}></div> */}
    </>
  );
}
