import React from "react";
import AnimatedCard from "../../../components/vehicleFleetCard/card"
import UPFrom from '../../../Assets/vehicleFleetImg/up_forms_re_pkrt.svg'
import MR from '../../../Assets/vehicleFleetImg/manegerroute.svg'
import BR from "../../../Assets/vehicleFleetImg/breakdown.svg"


const Home = () => {
  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      <AnimatedCard
        imageSrc= {UPFrom}
        title="Upload Request Letter Form"
        link='/maintenance-manegement/upload-request-letter-form'
       
      />

      {/* Card 2 */}
      <AnimatedCard
        imageSrc={MR}
        title="Maintenance Records"
        link='/maintenance-manegement/maintenance-records'
      />


      {/* Card 3 */}
      <AnimatedCard
        imageSrc={BR}
        title="breakdown_records"
        link='/maintenance-manegement/breakdown-records'  
      />

    </div>
  );
};

export default Home;
