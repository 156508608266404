import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";

const Retirement = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the popup visibility
  const [retirementData, setRetirementData] = useState([]); // State to store retirement data
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchRetirementData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/employees/retirementdata`
        );
        if (response.ok) {
          const data = await response.json();

          // Get the current year
          const currentYear = new Date().getFullYear();

          // Filter the data to include only employees whose retirement date is in the current year
          const filteredData = data.filter((employee) => {
            const retirementYear = new Date(employee.employee_retirement_date).getFullYear();
            return retirementYear === currentYear;
          });

          setRetirementData(filteredData);
        } else {
          console.error("Failed to fetch retirement data");
        }
      } catch (error) {
        console.error("Error occurred while fetching retirement data:", error);
      }
    };

    fetchRetirementData();
  }, [REACT_APP_API_ENDPOINT]);

  return (
    <div className="bg-white p-5 rounded-xl relative">
      <p className="font-bold text-[#797C80] text-[32px] ">
        Upcoming <br />
        Retirements
      </p>

      <div className="flex justify-between items-center mt-2">
        <div>
          <button
            className="bg-primary p-2 rounded-[50px] text-white mt-2 font-sans font-semibold "
            onClick={() => setIsOpen(true)}
          >
            Click me
          </button>
        </div>

        <div>
          <div className="bg-primary p-2 rounded-[22px] h-[37px]"></div>
        </div>
      </div>

      {/* Popup/Modal */}
      {isOpen && (
        <div className="fixed top-0 left-0 h-full w-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-[80%] h-[80%] relative">
            <button
              className="absolute top-2 right-2 bg-white text-[#797C80] rounded-full p-1"
              onClick={() => setIsOpen(false)}
            >
              <div className="bg-[#EFA5A5] rounded-full p-2">
                <IoCloseSharp />
              </div>
            </button>
            <p className="text-center font-sans font-bold text-[24px] text-[#797C80] mb-4">
              Upcoming Retirements
            </p>
            <div className="h-[90%] overflow-y-auto  scrollbar-thin scrollbar-thumb-[#c0c0c0]">
              <table className="w-full border-none">
                <thead>
                  <tr className="text-left">
                    <th className="bg-white text-[16px] font-normal font-sans py-2 w-[10%]">
                      Emp Num
                    </th>
                    <th className="bg-white text-[16px] font-normal font-sans py-2 w-[35%]">
                      Name & Position
                    </th>
                    <th className="bg-white text-[16px] font-normal font-sans py-2 w-[25%]">
                      Onboarded On
                    </th>
                    <th className="bg-white text-[16px] font-normal font-sans py-2 w-[30%]">
                      Retirement Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {retirementData.map((data, index) => (
                    <tr
                      key={index}
                      className={`text-left ${
                        index % 2 === 0 ? "bg-gray-100" : ""
                      }`}
                    >
                      <td className="py-2 px-4 text-[#1F2937] w-[5%]">
                        {data.employee_no}
                      </td>
                      <td className="py-2 px-4 w-[25%]">
                        <div className="font-bold text-[#1F2937] text-[16px] truncate">
                          {data.employee_fullname}
                        </div>
                        <div className="text-sm text-[#6B7280] truncate">
                          {data.employee_designation}
                        </div>
                      </td>
                      <td className="py-2 px-4 text-[#1F2937] w-[25%]">
                        {data.date_of_appoinment}
                      </td>
                      <td className="py-2 px-4 w-[30%]">
                        <span className="bg-red-200 text-red-700 font-bold p-1 rounded-lg">
                          {data.employee_retirement_date}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Retirement;
