import React from "react";
import  documentUploading from "../../../Assets/vehicleFleetImg/document_approval.svg";
import systemEditing from "../../../Assets/vehicleFleetImg/system_editing.svg";
import gatePass from "../../../Assets/vehicleFleetImg/gatepassdispaches.svg";

import AnimatedCard from "../../../components/vehicleFleetCard/card";


const Home = () => {

  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      <AnimatedCard
        imageSrc= {documentUploading}
        title="Document Uploading"
        link="/Approval-process-02/document-uploading"
      />


    {/* <AnimatedCard
        imageSrc= {systemEditing}
        title="System Editing"
        link="/Approval-process-02/system-editing"
      /> */}

    <AnimatedCard
        imageSrc= {gatePass}
        title="Gate Pass Request"
        link="/Approval-process-02/gate-pass-request"
      />
    </div>
  );
};

export default Home;
