/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Cookies from "js-cookie";
import cloudiccon from "../../../../../Assets/icons/cloud-add.png";
import FileIcon from "../../../../../Assets/icons/pdf_file.png";

const Opening_Price_Quo_Form_View = () => {
  const [file, setFile] = useState(null);

  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];

  const [category, setCategory] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const location = useLocation();
  const { selectedRecord = {} } = location.state || {};
  const [tableData, setTableData] = useState([]);
  console.log("selectedRec", selectedRecord.qoutation_id);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/get-open-qoutation/${selectedRecord.qoutation_id}`
        );
        const data = await response.json();

        setTableData(data);
        console.log("tableData", data);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchQuotationData();
  }, []);

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Opening Price Quotation Form
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] relative">
        <div className="mt-4 ml-6 flex items-center">
          <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
            Purchase Request No:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            type="text"
            value={tableData.pr_id}
            readOnly
          />
          <p className="text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Purchase Request Title:
          </p>
          <input
            className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tableData.title}
            readOnly
          />
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-[#F3F8FF] w-[90%] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Quotation</p>
          {/* Search input for supplier name */}
          <div className="w-[600px] flex items-center justify-between my-10 relative">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tableData.supplier_name}
              readOnly
            />
          </div>

          {/* Supplier ID input (auto-populated) */}
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tableData.supplier_id}
              readOnly
            />
          </div>

          <p className="header-1 mb-10">Purchase Request Item Details</p>

          <div className="flex flex-col justify-start w-[130%]  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
            <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
              <p className="w-10 p1 text-[#656565] text-center">#</p>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Name
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Qty
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Measure Unit
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Unit Price
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Discount Rate
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">VAT</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">SES</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Other TAX
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Line Amount
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
            </div>
            <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
              {tableData?.items?.length > 0 ? (
                tableData.items.map((item, index) => (
                  <div key={index} className="w-full  flex justify-around ">
                    <div className="w-10 flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {index + 1}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_id}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_name}
                      </p>
                    </div>
                    <div className="w-[200px] flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_quantity}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 ml-5 text-[#656565] text-center ">
                        {item.item_measure_unit}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.unit_price}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.discount_rate}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.vat}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.ses}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.other_tax}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 ml-5 text-[#656565] text-center ">
                        {item.line_amount}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No items available</p>
              )}
            </div>
          </div>

          <div className="w-full flex justify-end items-start px-16 pt-3 ml-[15%]">
            <div className="w-[350px] h-full  flex flex-col gap-y-2">
              <div className="w-[350px] h-full rounded-[23px] bg-white flex flex-col px-4 py-3">
                <div className="flex items-center justify-between">
                  <p className="p1 text-[#656565]"> Net Total</p>
                  <p className="p1 text-[#656565]">{tableData.net_total}</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex items-center justify-center mt-5">
            <div>
              <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center">
                Upload Files
              </h2>
              <hr className=" ml-[20%] w-[60%] border-solid border-b-2 border-black"></hr>
            </div>
          </div>
          <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[60%] flex-shrink-0 mt-5 ml-[10%]">
            <main className="container mx-auto max-w-screen-lg h-full">
              <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                Uploaded File
              </h1>

              {tableData.qoutation_document_path ? (
                <div className="flex justify-center">
                  <a
                    href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/files/${tableData.qoutation_document_path}`}
                    download={tableData.qoutation_document_path} // Make the file downloadable
                    className="cursor-pointer"
                  >
                    <img
                      className="h-[100px] w-[100px] object-contain"
                      src={FileIcon}
                      alt="File Icon"
                    />
                  </a>
                </div>
              ) : (
                <p>No file uploaded</p>
              )}
            </main>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={""}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default Opening_Price_Quo_Form_View;
