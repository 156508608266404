import React, { useState, useEffect } from "react";
import axios from "axios";
import TableApprovel from "./approvelTable";

const Sent_For_Approvals = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [data, setData] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/all/members/status/PENDING`
      );
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectOption = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50]">
        Sent for Approvals
      </p>
      <div className="w-1/2 pl-6 flex items-center mt-6">
        <select
          value={selectedOption}
          onChange={handleSelectOption}
          className="border border-gray-300 px-2 w-2/6 h-10 rounded-lg"
        >
          <option value="">Select To</option>
          <option value="Option 1">Option 1</option>
          <option value="Option 2">Option 2</option>
          <option value="Option 3">Option 3</option>
        </select>
        <label className="ml-4 text-[#BBC5DC] font-poppins font-bold text-[24px]">
          Select
        </label>
      </div>
      <div className="mt-6 w-[95%] ml-6">
        <TableApprovel data={data} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default Sent_For_Approvals;
