import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiFile, FiCalendar } from "react-icons/fi";

const TableActivity = ({ data, navigate }) => {
  const [showDialogIndex, setShowDialogIndex] = useState(null);

  const openDialog = (index) => {
    setShowDialogIndex(index);
  };

  const closeDialog = () => {
    setShowDialogIndex(null);
  };
  // Dummy table data array
  const dummyData = [
    {
      activity: "Delete",
      activitydate: "DD-MM-YYYY",
      decisionName: "Test",
      decisionMadeDate: "DD-MM-YYYY",
      time: "12:00 PM",
      uploads: "Created",
      count: "3d ago",
    },
    {
      activity: "Update",
      activitydate: "DD-MM-YYYY",
      decisionName: "Test",
      decisionMadeDate: "DD-MM-YYYY",
      time: "12:00 PM",
      uploads: "Pending",
      count: "3d ago",
    },
    {
      activity: "Update",
      activitydate: "DD-MM-YYYY",
      decisionName: "Test",
      decisionMadeDate: "DD-MM-YYYY",
      time: "12:00 PM",
      uploads: "Created",
      count: "1m ago",
    },

    // Add more rows as needed
  ];

  // Use the provided data prop if available, otherwise fallback to dummy data
  const tableData = data || dummyData;

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 ml-6 w-[95%] max-h-[500px] overflow-y-auto mt-8">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-4 py-6 ">Activity</th>
            <th className="px-4 py-6 ">Activity Date</th>
            <th className="px-1 py-6 ">Decision Name</th>
            <th className="px-4 py-6 ">
              Decision
              <br /> Made Date
            </th>
            <th className="px-4 py-6 ">Time</th>
            <th className="px-4 py-6 ">Uploads</th>
            <th className="px-4 py-6 "></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {tableData.map((item, index) => (
            <tr
              key={index}
              className={`${
                index !== tableData.length - 1 ? "border-b border-gray-300" : ""
              }`}
            >
              <td className="px-4 py-2">{item.activity}</td>
              <td className="px-4 py-2 relative">
                <div className="flex items-center justify-center">
                  <div className="bg-[#E7F1FF] rounded-lg mr-2 px-2 py-1">
                    {item.activitydate}
                  </div>
                  <div>
                    <FiCalendar className="text-gray-600" />
                  </div>
                </div>
              </td>
              <td className="px-4 py-2">{item.decisionName}</td>
              <td className="px-4 py-2 relative">
                <div className="flex items-center justify-center">
                  <div className="bg-[#E7F1FF] rounded-lg mr-2 px-2 py-1">
                    {item.decisionMadeDate}
                  </div>
                  <div>
                    <FiCalendar className="text-gray-600" />
                  </div>
                </div>
              </td>
              <td className="px-4 py-2 relative">
                <div className="bg-[#E7F1FF] rounded-lg mr-2 px-2 py-1">
                  {item.time}
                </div>
              </td>

              <td className="px-4 py-2 relative">
                {/* Upload section with file icon */}
                <div className="flex items-center bg-[#E7F1FF] rounded-lg">
                  <div className="flex items-center">
                    <FiFile className="text-gray-600" />
                  </div>
                  <div className="mr-2">{item.uploads}</div>
                  <div className="ml-4 text-[#071C50] text-opacity-20">
                    {item.count}
                  </div>
                </div>
                {/* End of Upload section */}
              </td>
              <td className="px-4 py-2">
                <button className="text-primary hover:underline">View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableActivity;
