import React, { useState } from "react";
import { MdDelete } from "react-icons/md";

const CustomerType = () => {
  const [customerType, setCustomerType] = useState("");
  const [condition, setCondition] = useState("");
  const [location, setLocation] = useState("");
 
  const [discount, setDiscount] = useState([{ from: 0, to: 0, rate: 0 }]);

  // Function to handle the save button click
  const handleSave = async () => {
    const data = {
      customer_type: customerType,
      condition,
      location,
     
      rates: discount.map((item) => ({
        from: item.from,
        to: item.to,
        approved_rate: item.rate,
      })),
    };

    try {
      const response = await fetch(
        "https://back-ayurveda.erp-quortech.com/v1/inventory/customer/customer-types",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert("Customer type added successfully");
      } else {
        alert("Failed to add customer type");
      }
    } catch (error) {
      console.error("Error saving customer type:", error);
      alert("An error occurred while saving");
    }
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[35px] font-bold ml-10 mt-5">Customer Types</p>
      <div className="flex justify-center mt-[5%]">
        <div className="bg-white p-5 w-[70%] rounded-xl">
          <div className="flex justify-center">
            <p className="text-[35px] font-bold">Add Customer Type</p>
          </div>
          <div className="mt-10 flex justify-between">
            <div className="flex gap-5 items-center">
              <p className="text-[25px] font-bold">Customer Type :</p>
              <input
                type="text"
                className="border border-black rounded-md p-2"
                value={customerType}
                onChange={(e) => setCustomerType(e.target.value)}
              />
            </div>

            <div className="flex gap-5 items-center">
              <p className="text-[25px] font-bold">Condition :</p>
              <input
                type="text"
                className="border border-black rounded-md p-2"
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
              />
            </div>

            <div className="flex gap-5 items-center">
              <p className="text-[25px] font-bold">Location :</p>
              <input
                type="text"
                className="border border-black rounded-md p-2"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
          </div>

          {/* Discount details section */}
          <div className="w-full bg-[#F3F8FF] px-20 py-5 rounded-[46px] items-center justify-center mt-5">
            <p className="header-1 mb-10">Enter Discount Details</p>

            {discount.length > 0 &&
              discount.map((data, index) => (
                <div
                  key={index}
                  className="w-full flex justify-between items-center my-2"
                >
                  <div className="flex flex-col gap-y-3">
                    <p className="label-1">From:</p>
                    <input
                      className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                      value={data.from}
                      type="number"
                      onChange={(e) => {
                        const updatedData = [...discount];
                        updatedData[index].from = e.target.value;
                        setDiscount(updatedData);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <p className="label-1">To:</p>
                    <input
                      className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                      value={data.to}
                      type="number"
                      onChange={(e) => {
                        const updatedData = [...discount];
                        updatedData[index].to = e.target.value;
                        setDiscount(updatedData);
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-y-3">
                    <p className="label-1">Discount Rate:</p>
                    <input
                      className="w-[255px] h-[46px] rounded-[23px] border border-[#00000036] px-4 font-bold"
                      value={data.rate}
                      type="number"
                      onChange={(e) => {
                        const updatedData = [...discount];
                        updatedData[index].rate = e.target.value;
                        setDiscount(updatedData);
                      }}
                    />
                  </div>
                  <div className="pt-12">
                    <button
                      className="text-red-600"
                      onClick={() => {
                        const updatedData = [...discount];
                        updatedData.splice(index, 1);
                        setDiscount(updatedData);
                      }}
                    >
                      <MdDelete size={30} />
                    </button>
                  </div>
                </div>
              ))}

            <div className="w-full flex justify-start my-9">
              <button
                className="w-[118px] h-[45px] flex items-center justify-center bg-[#43aa43] border rounded-[23px] px-4 text-white font-bold text-xl"
                onClick={() => {
                  const updatedData = [...discount];
                  updatedData.push({
                    from:
                      updatedData.length > 0
                        ? updatedData[updatedData.length - 1].to
                        : 0,
                    to: 0,
                    rate: 0,
                  });
                  setDiscount(updatedData);
                }}
              >
                Add
              </button>
            </div>
          </div>

          <div className="flex justify-end mt-5">
            <button
              className="w-[118px] h-[45px] flex items-center justify-center bg-green-600 border rounded-[23px] px-4 text-white font-bold text-xl"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerType;
