import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../../components/permissions/permission";

const GeneratedPayroll = () => {
  const { hasPermission } = usePermissions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [employees, setEmployees] = useState([]);
  const [toggleMonthly, setToggleMonthly] = useState(false); // Monthly toggle
  const [toggleIncentive, setToggleIncentive] = useState(false); // Incentive toggle

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenClearModal = () => {
    setIsClearModalOpen(true);
  };

  const handleCloseClearModal = () => {
    setIsClearModalOpen(false);
  };

  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  useEffect(() => {
    // Fetch the list of employees when the component mounts
    const fetchEmployees = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeebasicdetails`
        );
        if (response.ok) {
          const data = await response.json();
          setEmployees(data);
        } else {
          console.error("Failed to fetch employees");
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleEmployeeNumberChange = (e) => {
    const value = e.target.value;
    setEmployeeNumber(value);

    const selectedEmployee = employees.find((emp) => emp.employee_no === value);
    if (selectedEmployee) {
      setEmployeeName(selectedEmployee.employee_name_initial);
      setEmployeeId(selectedEmployee.id);
    } else {
      setEmployeeName("");
      setEmployeeId("");
    }
  };

  const handleDownload = async () => {
    if (!employeeNumber || !year || !month) {
      alert("Please fill all fields");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/download-payslip?employee_no=${employeeNumber}&year=${year}&month=${month}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Payslip_${employeeNumber}_${year}_${month}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        const errorData = await response.json();
        console.error("Failed to download payslip:", errorData);
        alert("Failed to download payslip.");
      }
    } catch (error) {
      console.error("Error occurred while downloading payslip:", error);
      alert("Error occurred while downloading payslip.");
    }

    handleCloseModal();
  };

  const handleClear = () => {
    if (!year || !month) {
      alert("Please fill all fields");
      return;
    }

    handleOpenConfirmModal();
  };

  const handleConfirmClear = async () => {
    if (toggleMonthly) {
      await clearData(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/clear-monthly-payroll`,
        year,
        month
      );
    }

    if (toggleIncentive) {
      await clearData(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/clear-final-incentive`,
        year,
        month
      );
    }

    handleCloseConfirmModal();
    handleCloseClearModal();
  };

  const clearData = async (endpoint, year, month) => {
    try {
      const response = await fetch(`${endpoint}?year=${year}&month=${month}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
      } else {
        const errorData = await response.json();
        console.error("Failed to clear data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while clearing data:", error);
    }
  };

  return (
    <div>
      <div className="font-sans bg-background h-screen overflow-y-auto">
        <div>
          <p className="text-[40px] text-headerColor font-semibold ml-6 mt-6">
            Generated Payroll Management
          </p>
        </div>

        <div>
          <div className="grid grid-cols-2 grid-flow-col gap-[100px] ml-[200px] mr-[200px] mt-[150px]">
            {hasPermission(1060) && (
              <div className="bg-white rounded-[30px]">
                <div className="p-3 ">
                  <div>
                    <div className="text-[25px] font-bold mb-1 mt-1 ml-4">
                      <p>Pay Slip Information</p>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="bg-primary rounded-[30px] text-white p-2 mr-[5%] font-semibold mt-10 w-[100px]"
                      onClick={handleOpenModal}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            )}
            {hasPermission(1061) && (
              <div className="bg-white rounded-[30px]">
                <div className="p-3">
                  <div className="text-[25px] font-bold mb-1 mt-1 ml-4">
                    <p>Clear Generated Payrolls</p>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="bg-primary rounded-[30px] text-white p-2 mr-[5%] font-semibold mt-10 w-[100px]"
                      onClick={handleOpenClearModal}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px] w-[500px] justify-center">
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Download Pay Slip</h2>
            <div className="flex flex-col items-center">
              <div className="mb-4 ">
                <label className="block text-sm font-medium text-gray-700">
                  Employee Number
                </label>
                <select
                  value={employeeNumber}
                  onChange={handleEmployeeNumberChange}
                  className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                >
                  <option value="">Select Employee Number</option>
                  {employees.map((emp) => (
                    <option key={emp.id} value={emp.employee_no}>
                      {emp.employee_no}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Employee Name
                </label>
                <input
                  type="text"
                  value={employeeName}
                  disabled
                  className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Year
                </label>
                <select
                  className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {Array.from({ length: 10 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Month
                </label>
                <select
                  className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded-[73px] mr-2"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded-[73px]"
                onClick={handleDownload}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}

      {isClearModalOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px] w-[500px] justify-center">
            <button
              onClick={handleCloseClearModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Clear Payroll Data</h2>
            <div className="flex flex-col items-center">
              <div className="mb-4 flex items-center">
                <label className="block text-sm font-medium text-gray-700">
                  Month-end Payroll
                </label>
                <input
                  type="checkbox"
                  checked={toggleMonthly}
                  onChange={() => setToggleMonthly(!toggleMonthly)}
                  className="ml-2 rounded-full"
                />
                <label className="block text-sm font-medium text-gray-700 ml-4">
                  Incentive Payroll
                </label>
                <input
                  type="checkbox"
                  checked={toggleIncentive}
                  onChange={() => setToggleIncentive(!toggleIncentive)}
                  className="ml-2 rounded-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Year
                </label>
                <select
                  className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {Array.from({ length: 10 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Month
                </label>
                <select
                  className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded-[73px] mr-2"
                onClick={handleCloseClearModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded-[73px]"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      )}

      {isConfirmModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 relative">
            <button
              onClick={handleCloseConfirmModal}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-600"
            >
              &times;
            </button>
            <h2 className="text-2xl mb-4">Confirm Clearance</h2>
            <p className="mb-4">
              Are you sure you want to clear the payroll data for {month}/{year}
              ?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={handleCloseConfirmModal}
              >
                Cancel
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded"
                onClick={handleConfirmClear}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneratedPayroll;
