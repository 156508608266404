import React from "react";

const ApprovalTable = ({data}) => {

  return (
    <div className="w-full h-[640px] bg-[#F3F8FF] rounded-[46px] flex flex-col justify-start my-10">
      <div className="w-full h-[72px] rounded-[46px] bg-[#BFD8AE] flex justify-around items-center px-5 pr-[35px]">
        <p className="font-bold text-[24px] text-[#656565] text-center w-[100px]">
          Emp No
        </p>
        <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
          Full Name
        </p>
        <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
          Edit Feedback
        </p>
        <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
          View
        </p>
        <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
          Approval 01
        </p>
      </div>
      <div className="w-full h-[530px] flex flex-col  items-center px-5 overflow-y-scroll ">
        {data.map((d, i) => {
          return (
            <div
              key={i}
              className="w-full  flex justify-around items-center  "
            >
              <p className="font-bold text-[24px] text-[#656565] text-center  w-[100px]">
                {d.empNo}
              </p>
              <p className="font-bold text-[24px] text-[#656565] text-center  w-[350px]">
                {d.name}
              </p>
              <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                {d.feedBack}
              </p>
              <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                <button className="font-bold text-xl px-5 py-1 underline">
                  Click Here
                </button>
              </p>
              <p className="font-bold text-[24px] text-[#656565] text-center  w-[200px]">
                <button className="bg-[#5B6D49] border rounded-[23px]  text-white font-bold text-xl px-5 py-1 ">
                  Approve
                </button>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApprovalTable;
