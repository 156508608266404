import React from "react";
import AnimatedCard from "../../../components/vehicleFleetCard/card"
import ManagerRoute from '../../../Assets/vehicleFleetImg/manegerroute.svg';
import Route from '../../../Assets/vehicleFleetImg/route.svg';
import usePermissions from "../../../components/permissions/permission"


const Home = () => {
  const { hasPermission } = usePermissions();

  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      {/* {hasPermission(5125) && ( */}

      <AnimatedCard
        imageSrc= {ManagerRoute}
        title="Manager Transportation Schedules"
        link="/route-planning-schedules/manager-transportation-schedules"
       
      />
    {/* )} */}

      {/* Card 2 */}

  
  {/* {hasPermission(5130) && ( */}
      <AnimatedCard
        imageSrc={Route}
        title="Create Gate Pass Schedules"
        link="/route-planning-Schedules/gatepass-transportation-schedules"
      />
    {/* )} */}


      {/* Card 3 */}
      {/* <AnimatedCard
        imageSrc={vehiclePartDetails}
        title="Vehicel Part Details"
        description="Feel the power of towering mountains.dhhhdfhhfff"
        buttonText="Vehicle Part Details"
        link='/vehicle-fleet-management/vehicle-part-details'  
      /> */}

    </div>
  );
};

export default Home;
