/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Printer from "../../../Assets/icons/print 1.png";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function ReleaseItemReturnReqFromBranch() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState(selectedRecord.items || []);
  const [relID, setRelID] = useState("");
  const userId = Cookies.get("employee_no");
  const [netTotal, setNetTotal] = useState(0);

  console.log(selectedRecord);
  const [approvedQuantity, setApprovedQuantity] = useState(
    selectedRecord.approved_quantity || ""
  );
  useEffect(() => {
    setRelID(IdGenerate("REL"));
  }, []);
  const [alert, setAlert] = useState({
    status: "",
    alert: "",
    show: false,
  });

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format the date to ISO string

  const contentToPrint = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => contentToPrint.current,
  //   documentTitle: "Print This Document",
  //   onBeforePrint: () => console.log("before printing..."),
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true,
  // });

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Item Return Request From Branch", 14, 20);

    doc.setFontSize(12);
    doc.text(`Return Request Id : ${selectedRecord.return_id}`, 14, 30);
    doc.text(`RR Date : ${selectedRecord.return_at}`, 14, 40);

    const tableColumn = [
      "Item Name",
      "Return Requested Qty",
      "Recevied Qty",
      "Price (LKR)",
      "Store",
      "Location",
      "Note",
      "Remarks",
      " Line Amount",
    ];

    const tableRows = [];

    tableData.forEach((item) => {
      const rowData = [
        item.item_name,
        item.return_qty,
        item.item_release_qty,
        item.item_price,
        item.store_id,
        item.location,
        item.return_description,
        item.inventory_release_item_description,
        item.line_amount,
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60,
    });

    doc.save("Item Return Request From Branch.pdf");
  };

  const SubmitHandler = async () => {
    const items = tableData.map((item) => ({
      batch_id: item.batch_id,
      accept_qty: parseFloat(item.item_release_qty), // Ensure it's a number
      store_id: item.store_id,
      rack_location: item.location,
    }));

    const data = {
      return_id: selectedRecord.return_id,
      accept_by: userId,
      status: "ACCEPTED",
      items,
    };

    console.log("send data", data);
    //check conditions

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/inventory/BracnReturn/accept-return-in-inventory`,
        data
      );
      console.log(res.data);
      console.log(res.status);

      if (res.status === 200 || res.status === 201) {
        console.log("hello:");
        window.alert("Successfully Added Branch Item Return Request");
        navigate(-1);
      }
    } catch (error) {
      setAlert({
        status: "error",
        alert: "Something Went Wrong",
        show: true,
      });
    }
  };

  const Data = [...tableData]; // Create a copy of tableData
  Data.push({
    item_name: selectedRecord.item_name,
    batch_id: selectedRecord.batch_id,
    return_qty: selectedRecord.return_qty,
    return_description: selectedRecord.return_description,
    item_release_qty: 0,
    inventory_release_item_description: " ",
  });

  useEffect(() => {
    // Calculate net total from the line_amount values
    const total = tableData.reduce(
      (acc, item) => acc + (item.line_amount || 0),
      0
    );
    setNetTotal(total);
  }, [tableData]);

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">Item Return Request From Branch</p>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Return Request Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Return Request Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.return_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">RR Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {new Date(selectedRecord.return_at).toISOString().split("T")[0]}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Return Request Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Return Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Recevied Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Price(LKR)
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Store</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Location</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Note</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Line Amount
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div className="">
            {tableData.length > 0
              ? tableData.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start justify-around my-3  "
                  >
                    <p className="w-10 p1 text-[#656565] text-center">
                      {index + 1}
                    </p>
                    <p className="w-[350px] p1 text-[#656565] text-center">
                      {item.item_name}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.return_qty}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      <input
                        type="number"
                        value={item.item_release_qty || ""}
                        min="0"
                        className="focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        onChange={(e) => {
                          let temp = [...tableData];
                          let inputValue = parseFloat(e.target.value);

                          // Validate input to block values higher than return_qty or less than 0
                          if (inputValue > item.return_qty) {
                            inputValue = item.return_qty; // Set to return_qty if input exceeds it
                          } else if (inputValue < 0) {
                            inputValue = 0; // Set to 0 if input is negative
                          }

                          // Update item_requested_qty with the validated value
                          temp[index].item_release_qty = inputValue;

                          // Calculate line_amount
                          temp[index].line_amount =
                            inputValue * item.item_price;

                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.item_price}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.store_id}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.location}
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.return_description}
                    </p>
                    <p className="w-[200px] p1 text-[#656565] text-center">
                      <textarea
                        className="w-[200px] border border-[#656565] my-2 rounded-2xl"
                        value={item.inventory_release_item_description}
                        onChange={(e) => {
                          const temp = [...tableData];
                          temp[index].inventory_release_item_description =
                            e.target.value;
                          setTableData(temp);
                        }}
                      />
                    </p>
                    <p className="w-[160px] p1 text-[#656565] text-center">
                      {item.line_amount}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[620px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center gap-2">
            <p className="p1 text-[#656565] w-[500px]">Branch ID:</p>
            <div className="relative w-[300px]">
              <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.branch_id}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Net Total</p>
              <p className="p1 text-[#656565]">{netTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button className="w-[138px] h-[41px] flex items-center justify-between bg-white border rounded-[23px] px-5">
          <img src={Printer} alt="" />
          <p
            className="p2 text-[#656565]"
            onClick={() => {
              exportToPDF(null, () => contentToPrint.current);
            }}
          >
            Print
          </p>
        </button>
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl">
          Reject
        </button>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={SubmitHandler}
        >
          Release
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
