import React, { useState, useEffect } from "react";

const ViewUpdateCustomer = () => {
  const [customerData, setCustomerData] = useState({
    customerType: "",
    condition: "",
    location: "",
    rates: [
      { from: "", to: "", discountRate: "" }, // Initial rate object
    ],
  });

  const [customerTypes, setCustomerTypes] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isCustomerSelected, setIsCustomerSelected] = useState(false); // For enabling fields after customer is selected

  // Fetch customer types from the API when the component mounts
  useEffect(() => {
    const fetchCustomerTypes = async () => {
      try {
        const response = await fetch(
          "https://back-ayurveda.erp-quortech.com/v1/inventory/customer/customer-types"
        );
        const data = await response.json();
        setCustomerTypes(data);
      } catch (error) {
        console.error("Error fetching customer types:", error);
      }
    };
    fetchCustomerTypes();
  }, []);

  // Handle customer type selection
  const handleCustomerTypeChange = (e) => {
    const selectedType = e.target.value;
    setCustomerData((prevData) => ({
      ...prevData,
      customerType: selectedType,
    }));

    const selectedCustomer = customerTypes.find(
      (customer) => customer.customer_type === selectedType
    );

    if (selectedCustomer) {
      const { condition, location, rates } = selectedCustomer;
      const formattedRates = rates.map((rate) => {
        const [from, to] = rate.value_range.split("-"); // Assuming value_range is stored as "from-to"
        return {
          from: from || "",
          to: to || "",
          discountRate: `${rate.approved_rate || 0}%`, // Add the percentage symbol
        };
      });

      setCustomerData((prevData) => ({
        ...prevData,
        condition: condition || "",
        location: location || "",
        rates: formattedRates,
      }));
      setIsCustomerSelected(true); // Enable fields after selection
      setIsEditable(false); // Initially set to non-editable
    }
  };

  // Handle input changes in rates
  const handleRateChange = (e, index, field) => {
    let { value } = e.target;

    // Ensure that the discountRate has the "%" sign and is formatted correctly
    if (field === "discountRate") {
      if (value.includes("%")) {
        value = value.replace("%", ""); // Remove the % sign temporarily
      }
      if (!isNaN(value) && value !== "") {
        value = `${value}%`; // Append the % sign back
      }
    }

    setCustomerData((prevData) => {
      const updatedRates = prevData.rates.map((rate, i) =>
        i === index ? { ...rate, [field]: value } : rate
      );
      return {
        ...prevData,
        rates: updatedRates,
      };
    });
  };

  // Enable edit mode
  const handleEdit = () => {
    setIsEditable(true);
  };

  // Save customer data and exit edit mode
  const handleSave = async () => {
    const selectedCustomer = customerTypes.find(
      (customer) => customer.customer_type === customerData.customerType
    );

    if (!selectedCustomer) {
      alert("Customer type does not exist.");
      return; // Exit the function early if the customer doesn't exist
    }

    try {
      const rates = customerData.rates.map((rate) => ({
        from: parseFloat(rate.from),
        to: parseFloat(rate.to),
        approved_rate: parseFloat(rate.discountRate.replace("%", "")), // Remove the % sign for saving
      }));

      const body = {
        customer_type: customerData.customerType,
        condition: customerData.condition,
        cash_credit: "cash", // This can be dynamically updated if needed
        location: customerData.location,
        rates: rates,
      };

      const response = await fetch(
        `https://back-ayurveda.erp-quortech.com/v1/inventory/customer/customer-types?id=${selectedCustomer.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        alert("Customer data updated successfully.");
        setIsEditable(false);
      } else {
        const errorData = await response.json(); // Log the error body
        console.error("Failed to update customer data:", errorData);
        alert("Failed to update customer data.");
      }
    } catch (error) {
      console.error("Error saving customer data:", error); // Log the catch error
      alert("An error occurred while updating customer data.");
    }
  };

  // Handle input changes in other fields in edit mode
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[35px] font-bold ml-10 mt-5">
        View and Update Customer Type
      </p>

      <div className="flex justify-center mt-[5%]">
        <div className="bg-white p-5 w-[90%] rounded-xl ">
          <div className="text-[35px] font-bold grid grid-cols-3 grid-flow-rows justify-center">
            <div className="flex gap-5 items-center">
              <p className="text-[25px] font-bold">Customer Type :</p>
              <select
                name="customerType"
                value={customerData.customerType}
                onChange={handleCustomerTypeChange}
                className="border text-sm border-black rounded-md p-2 w-[250px]"
              >
                <option value="">Select Customer Type</option>
                {customerTypes.map((type) => (
                  <option key={type.id} value={type.customer_type}>
                    {type.customer_type}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center gap-4">
              <p className="text-[25px] text-black font-bold">Condition :</p>
              <input
                name="condition"
                value={customerData.condition}
                onChange={handleChange}
                className="border border-black rounded-xl p-1 h-10 w-[250px] text-sm"
                disabled={!isEditable || !isCustomerSelected}
              />
            </div>

            <div className="flex items-center gap-4">
              <p className="text-[25px] text-black font-bold">Location :</p>
              <input
                name="location"
                value={customerData.location}
                onChange={handleChange}
                className="border border-black rounded-xl p-1 ml-[9%] h-10 w-[250px] text-sm"
                disabled={!isEditable || !isCustomerSelected}
              />
            </div>
          </div>

          <div className="bg-background p-5 mt-8 rounded-lg">
            <div className="flex justify-center">
              <p className="text-[35px] font-bold">Discount Details</p>
            </div>

            {customerData.rates.map((rate, index) => (
              <div key={index} className="flex justify-between items-center mt-4">
                <div className="flex gap-8 items-center">
                  <div className="flex items-center gap-5">
                    <label className="text-[25px] font-bold">
                      From:
                    </label>
                    <input
                      name="from"
                      value={rate.from}
                      onChange={(e) => handleRateChange(e, index, "from")}
                      className="border border-black rounded-xl p-1 h-10 w-[250px]"
                      disabled={!isEditable || !isCustomerSelected}
                    />
                  </div>
                  <div className="flex items-center gap-5">
                    <label className="text-[25px] font-bold">To:</label>
                    <input
                      name="to"
                      value={rate.to}
                      onChange={(e) => handleRateChange(e, index, "to")}
                      className="border border-black rounded-xl p-1 h-10 w-[250px]"
                      disabled={!isEditable || !isCustomerSelected}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <label className="text-[25px] font-bold">Discount Rate:</label>
                  <input
                    name="discountRate"
                    value={rate.discountRate}
                    onChange={(e) => handleRateChange(e, index, "discountRate")}
                    className="border border-black rounded-xl p-1 h-10 w-[250px]"
                    disabled={!isEditable || !isCustomerSelected}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-5 flex gap-5">
            {!isEditable ? (
              <button
                className="bg-blue-500 p-2 rounded-md text-white font-bold"
                onClick={handleEdit}
                disabled={!isCustomerSelected}
              >
                Edit
              </button>
            ) : (
              <button
                className="bg-green-500 p-2 rounded-md text-white font-bold"
                onClick={handleSave}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUpdateCustomer;
