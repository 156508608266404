import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Drug_Logo from "../../../../../Assets/icons/drug-logo.png";
import { useLocation } from "react-router-dom";

const TecBidEvaluationReport = () => {
  const location = useLocation();
  const evaluationData = location.state;

  console.log("Evaluatino data:", evaluationData);
  const downloadPdf = () => {
    const input = document.getElementById("report");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("TecBidEvaluationReport.pdf");
    });
  };

  return (
    <div>
      <div id="report" className="py-[70px] px-[150px] font-sans">
        <div className="flex justify-center gap-[5%]">
          <div className="w-[634px] h-[113px]">
            <img src={Drug_Logo} alt="drug-coop-logo" />
          </div>
          <div>
            <p className="font-semibold justify-center">Tender ID</p>
            <input
              className="h-[70px] w-[192px] rounded-[23px] border border-black"
              value={evaluationData.tender_id}
            />
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <p className="font-bold text-[27px]">Bid Evaluation Summary Report</p>
        </div>
        <hr
          className="bg-black mx-auto mt-2 rounded-xl"
          style={{ height: "5px", width: "200px", border: "none" }}
        />

        <div className="mt-10 flex justify-center">
          <p className="font-semibold text-[15px]">
            In addition to this report summary, the technical evaluation
            committee shall prepare a detailed bid evaluation report.
          </p>
        </div>

        <div className="mt-10 text-[18px] font-semibold">
          <div className="flex items-center mb-4">
            <p className="w-1/4 ">Name of procurement entity:</p>
            <input
              className="h-[40px] w-full rounded-[23px] border border-black"
              value={evaluationData.proc_entity}
            />
          </div>
          <div className="flex items-center mb-4">
            <p className="w-1/4 ">Name of procurement:</p>
            <input
              className="h-[40px] w-full rounded-[23px] border border-black"
              value={evaluationData.proc_name}
            />
          </div>
          <div className="flex items-center mb-4">
            <p className="w-1/4 ">Source of financial resources:</p>
            <input
              className="h-[40px] w-full rounded-[23px] border border-black"
              value={evaluationData.fin_source}
            />
          </div>

          <div>
            <div>
              <p>Pre- bid estimated amount of procurement contract :</p>
              <input
                className="border mt-2 border-black h-[40px] w-[100%] rounded-[23px]"
                value={evaluationData.pre_bid_est_amt}
              />
            </div>

            <div className="mt-4">
              <p>Method of procurement (ICB/NCB/LIB/LNB etc.) :</p>
              <input
                className="border border-black h-[40px] w-[100%] rounded-[23px] mt-2"
                value={evaluationData.proc_method}
              />
            </div>

            <div className="flex gap-4 items-center mt-4">
              <p>Number of bidding documents issued :</p>
              <input
                className="border border-black rounded-[23px] h-[40px] w-full mt-2"
                value={evaluationData.docs_issued}
              />
            </div>

            <div className="flex gap-4 items-center mt-4">
              <p>Number of bids received:</p>
              <input
                className="ml-10 border border-black rounded-[23px] h-[40px] w-[80%]"
                value={evaluationData.bids_received}
              />
            </div>

            <div className="flex gap-4 items-center mt-4">
              <p>Number of bids determined as substantial responsive bids:</p>
              <input
                className=" border border-black rounded-[23px] h-[40px] w-[60%]"
                value={evaluationData.responsive_bids}
              />
            </div>

            <div className=" gap-4 items-center mt-4">
              <p>
                Any common reason/s if any leading to determination as
                non-responsive bids:
              </p>
              <input
                className="mt-2 border border-black rounded-[23px] h-[40px] w-[100%]"
                value={evaluationData.non_resp_reasons}
              />
            </div>
            <div className=" gap-4 items-center mt-4">
              <p>
                Whether the evaluated lowest bidder is recommended for award of
                the contract (yes/no) :
              </p>
              <input
                className="mt-2 border border-black rounded-[23px] h-[40px] w-[100%]"
                value={evaluationData.low_bid_award}
              />
            </div>

            <div className=" gap-4 items-center mt-4">
              <p>If the answer to above is no give the reason/s :</p>
              <input
                className="mt-4 border border-black rounded-[23px] h-[40px] w-[100%]"
                value={evaluationData.no_award_reason}
              />
            </div>

            <div className=" gap-4 items-center mt-4">
              <p>What is the recommended offer price :</p>
              <input
                className="mt-4 border border-black rounded-[23px] h-[40px] w-[100%]"
                value={evaluationData.rec_offer_price}
              />
            </div>

            <div className=" gap-4 items-center mt-4">
              <p>What is the recommended bidder’s bid price at bid opening:</p>
              <input
                className="mt-2 border border-black rounded-[23px] h-[40px] w-[100%]"
                value={evaluationData.rec_offer_price}
              />
            </div>

            <div className=" gap-4 items-center mt-4">
              <p>
                If there is a difference between (12) and (13) above, mention
                the reason/s :
              </p>
              <input
                className="mt-2 border border-black rounded-[23px] h-[40px] w-[100%]"
                value={evaluationData.price_diff}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <button
          onClick={downloadPdf}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Download as PDF
        </button>
      </div>
    </div>
  );
};

export default TecBidEvaluationReport;
