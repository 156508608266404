/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

const ProductionValue = () => {
  const [fileSelected, setFileSelected] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [allowanceData, setAllowanceData] = useState([]);
  const [secondTableData, setSecondTableData] = useState([]);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [productionAmount, setProductionAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [branchFilter, setBranchFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filterAddedDate, setFilterAddedDate] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState("");

  useEffect(() => {
    fetchAllowanceData();
    fetchSecondTableData();
  }, []);

  const fetchAllowanceData = () => {
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-values`
    )
      .then((response) => response.json())
      .then((data) => setAllowanceData(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchSecondTableData = () => {
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-incentives`
    )
      .then((response) => response.json())
      .then((data) => setSecondTableData(data))
      .catch((error) =>
        console.error("Error fetching second table data:", error)
      );
  };

  const handleSubmit = async () => {
    const body = {
      month: parseInt(month),
      year: parseInt(year),
      production_amount: parseFloat(productionAmount),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/${
          isEdit ? "production-value?id=" + editId : "add-production-value"
        }`,
        {
          method: isEdit ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        if (isEdit) {
          setAllowanceData(
            allowanceData.map((item) => (item.id === editId ? data : item))
          );
        } else {
          setAllowanceData([...allowanceData, data]); // Update the allowanceData with new entry
        }
        setShowAlertMessage(false);
        setIsOpen(false); // Close the modal on success
        setIsEdit(false);
        setEditId(null);
        fetchAllowanceData(); // Fetch updated data
      } else {
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error submitting data");
      setShowAlertMessage(true);
    }
  };

  const handleGenerateSubmit = async () => {
    const body = {
      month: parseInt(month),
      year: parseInt(year),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/calculate-production-incentive`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setSecondTableData([...secondTableData, data]);
        setShowAlertMessage(false);
        setIsGenerateOpen(false);
        fetchSecondTableData(); // Fetch updated data
      } else {
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error calculating incentive");
      setShowAlertMessage(true);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-value?id=${id}`
      );
      const data = await response.json();

      if (response.ok) {
        setMonth(data.month);
        setYear(data.year);
        setProductionAmount(data.production_amount);
        setEditId(id);
        setIsEdit(true);
        setIsOpen(true);
      } else {
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error fetching data");
      setShowAlertMessage(true);
    }
  };

  const handleDelete = async () => {
    const endpoint =
      deleteType === "value"
        ? `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-value?id=${deleteId}`
        : `${process.env.REACT_APP_API_ENDPOINT}/v1/hris/payroll/production-incentive?id=${deleteId}`;

    try {
      const response = await fetch(endpoint, {
        method: "DELETE",
      });
      const data = await response.json();
      if (response.ok) {
        if (deleteType === "value") {
          setAllowanceData(
            allowanceData.filter((item) => item.id !== deleteId)
          );
          fetchAllowanceData(); // Fetch updated data
        } else {
          setSecondTableData(
            secondTableData.filter((item) => item.id !== deleteId)
          );
          fetchSecondTableData(); // Fetch updated data
        }
        setIsDeleteOpen(false);
        setDeleteId(null);
        setDeleteType("");
        setShowAlertMessage(false);
      } else {
        setAlertMessage(data.message);
        setShowAlertMessage(true);
      }
    } catch (error) {
      setAlertMessage("Error deleting data");
      setShowAlertMessage(true);
    }
  };

  const filteredData = allowanceData.filter(
    (item) =>
      (!branchFilter || item.branch === branchFilter) &&
      (!departmentFilter || item.department === departmentFilter) &&
      (!filterMonth || item.month.toString() === filterMonth) &&
      (!filterYear || item.year.toString() === filterYear) &&
      (!filterAddedDate ||
        moment(item.added_date).format("YYYY-MM-DD") === filterAddedDate)
  );

  return (
    <div className="bg-background h-screen overflow-y-auto p-5">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] font-bold">
          Production Incentive
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <select
                id="monthSelect"
                name="month"
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
                autoComplete="off"
                className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans mb-3"
              >
                <option value="" className="font-sans-bold">
                  Filter By Month
                </option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <select
                id="yearSelect"
                name="year"
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
                autoComplete="off"
                className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
              >
                <option value="" className="font-sans-bold">
                  Filter By Year
                </option>
                {Array.from(new Array(20), (v, i) => i + 2000).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="bg-white rounded-[22px] h-[189px] w-[430px] p-5 mb-5">
              <div className="text-[#797C80] font-bold text-[29px] mb-5">
                <p>Add Production Value</p>
              </div>
              <div className="flex items-center justify-between mt-14">
                <label
                  htmlFor="fileInput"
                  className={`bg-primary p-2 rounded-[32px] text-white cursor-pointer ${
                    fileSelected ? "hidden" : ""
                  }`}
                  onClick={() => setIsOpen(true)}
                >
                  + Add
                </label>
                <button
                  type="button"
                  className={`bg-primary p-2 rounded-[32px] text-white cursor-pointer ${
                    !fileSelected ? "hidden" : ""
                  }`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="bg-white rounded-[22px] h-[189px] w-[430px] p-5 mb-5">
              <div className="text-[#797C80] font-bold text-[29px] mb-5">
                <p>Generate Production Incentive</p>
              </div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="fileInput"
                  className={`bg-primary p-2 rounded-[32px] text-white cursor-pointer ${
                    fileSelected ? "hidden" : ""
                  }`}
                  onClick={() => setIsGenerateOpen(true)}
                >
                  +Generate
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-5">
            <div className="text-[#797C80] font-bold text-[22px]">
              <p>Production Monthly Values</p>
            </div>
            <div className="text-[#797C80] font-bold text-[22px]">
              <p>Generate Monthly Production Incentive</p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex-1 mr-5">
              <div className="flex flex-col font-sans font-bold rounded-xl">
                <table className="table-auto border-none w-full text-center">
                  <thead>
                    <tr className="bg-white">
                      <th className="text-primary px-4 py-2">Month</th>
                      <th className="text-primary px-4 py-2">Year</th>
                      <th className="text-primary px-4 py-2">
                        Production Value
                      </th>
                      <th className="text-primary px-4 py-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item) => (
                      <tr key={item.id} className="bg-white">
                        <td>{item.month}</td>
                        <td>{item.year}</td>
                        <td>{Number(item.production_amount).toFixed(2)}</td>
                        <td className="flex items-center justify-center space-x-2">
                          <button
                            onClick={() => handleEdit(item.id)}
                            className="text-black font-bold py-2 px-4 rounded "
                          >
                            <CiEdit />
                          </button>
                          <button
                            onClick={() => {
                              setDeleteId(item.id);
                              setDeleteType("value");
                              setIsDeleteOpen(true);
                            }}
                            className="text-red-600 font-bold py-2 px-4 rounded "
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex-1 ml-5">
              <div className="flex flex-col font-sans font-bold rounded-xl">
                <table className="table-auto border-none w-full text-center">
                  <thead>
                    <tr className="bg-white">
                      <th className="text-primary px-4 py-2">Month</th>
                      <th className="text-primary px-4 py-2">Year</th>
                      <th className="text-primary px-4 py-2">
                        Incentive Amount
                      </th>
                      <th className="text-primary px-4 py-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {secondTableData.map((item) => (
                      <tr key={item.id} className="bg-white">
                        <td>{item.month}</td>
                        <td>{item.year}</td>
                        <td>{Number(item.incentive_amount).toFixed(2)}</td>
                        <td className="flex items-center justify-center space-x-2">
                          <button
                            onClick={() => {
                              setDeleteId(item.id);
                              setDeleteType("incentive");
                              setIsDeleteOpen(true);
                            }}
                            className="text-red-600 font-bold py-2 px-4 rounded "
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Enter Production Details
              </p>

              <div className="mt-5 flex flex-col">
                <div className="flex flex-row items-center">
                  <div>
                    <p className="font-sans font-semibold">Month :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      min="1"
                      max="12"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.month && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.month}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">Year :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.year && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.year}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">
                      Production Amount :
                    </p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      value={productionAmount}
                      onChange={(e) => setProductionAmount(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.productionAmount && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.productionAmount}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white"
                  onClick={() => {
                    setIsOpen(false);
                    setIsEdit(false);
                    setEditId(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isGenerateOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Generate Production Incentive
              </p>

              <div className="mt-5 flex flex-col">
                <div className="flex flex-row items-center">
                  <div>
                    <p className="font-sans font-semibold">Month :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      min="1"
                      max="12"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.month && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.month}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-center mt-4">
                  <div>
                    <p className="font-sans font-semibold">Year :</p>
                  </div>

                  <div className="ml-6">
                    <input
                      type="number"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white placeholder-white sm:text-sm px-2 font-sans"
                    />
                    {errorMessage.year && (
                      <p className="text-red-500 text-sm mt-1">
                        {errorMessage.year}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-primary p-2 rounded-[22px] text-white w-[100px]"
                  onClick={handleGenerateSubmit}
                >
                  Submit
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white w-[100px]"
                  onClick={() => setIsGenerateOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDeleteOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Are you sure you want to delete?
              </p>
              <div className="flex justify-center mt-5 space-x-3">
                <button
                  className="bg-red-500 p-2 rounded-[22px] text-white"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  className="bg-gray-500 p-2 rounded-[22px] text-white"
                  onClick={() => setIsDeleteOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductionValue;
