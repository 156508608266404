/** @format */

import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import Arrow from "./../../../Assets/icons/down-arrow.png";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

export default function AddItemToCreatedJobs() {
  const { hasPermission } = usePermissions();
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [resultsShow, setResultShow] = useState(false);

  const [disposeId, setDisposeId] = useState(IdGenerate(""));
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [itemArrow, setItemArrow] = useState(false);
  const [disposedQty, setDisposedQty] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [rawItems, setRawItems] = useState([]);
  const [subItem, setSubItem] = useState("");
  const [rawItemIds, setRawItemIds] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedRecord = {} } = location.state || {};

  useEffect(() => {
    fetchRawItems();
  }, []);

  // Define a function to fetch data
  const fetchRawItems = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/rawitem/allrawitem`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      // Transform data to build the necessary state objects
      const rawItemDetails = data.reduce((acc, item) => {
        acc[item.raw_item_name] = {
          id: item.raw_item_id,
          unit: item.raw_item_measure_unit,
          name: item.raw_item_name,
        };
        return acc;
      }, {});

      // Map raw item names and IDs together
      const rawItemNames = data.map((item) => ({
        id: item.raw_item_id,
        name: item.raw_item_name,
        unit: item.raw_item_measure_unit,
      }));

      // Update state with the transformed data
      setRawItemIds(rawItemDetails);
      setRawItems(rawItemNames);
    } catch (error) {
      console.error("Error fetching raw items:", error);
    }
  };

  const handleAddSubItem = () => {
    const selectedItem = rawItems.find((item) => item.id === subItem);

    if (selectedItem && !selectedItems.some((item) => item.id === subItem)) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        {
          id: selectedItem.id, // Store raw item ID
          name: selectedItem.name, // Store raw item name// Ensure quantity is a number
          unit: selectedItem.unit,
        },
      ]);
      setSubItem(""); // Reset after adding
    }
  };

  console.log("selected Items", selectedItems);
  // const SubmitHandler = async () => {
  //   const data = {
  //     dispose_id: disposeId,
  //     user_id: userId,
  //     date: formattedDate,
  //     items: tableData.map((item) => ({
  //       batch_id: item.item_batch_id,
  //       batch_qty: item.item_qty,
  //       batch_description: item.dispose_remarks,
  //     })),
  //   };
  //   console.log("send data:", data);

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}/dispose/add`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       }
  //     );

  //     if (response.ok) {
  //       const resData = await response.json();
  //       console.log(resData);
  //       alert("Disposed Added Successfully");
  //       setTableData([]);
  //       setDisposedQty([]);
  //       setResultShow(false);
  //     } else if (response.status === 409) {
  //       alert("Already Exist");
  //     } else if (response.status === 400) {
  //       alert("Disposed Not Added");
  //     } else if (response.status === 500) {
  //       alert("Internal Server Error");
  //     } else if (response.status === 404) {
  //       alert("Disposed Not Found");
  //     } else if (response.status === 403) {
  //       alert("Forbidden");
  //     } else if (response.status === 401) {
  //       alert("Unauthorized");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("An unexpected error occurred");
  //   }
  // };

  const handleClickSubmit = async () => {
    const items = selectedItems.map((item) => ({
      item_id: item.id, // Assuming subItem holds the item ID
      item_quantity: item.item_qty,
      item_unit: item.unit,
    }));
    const data = {
      production_id: selectedRecord.production_id,
      items,
    };

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/inventoryRequest/request-raw-item`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert("Items Request added successfully!");
        setSelectedItems([]);
      } else {
        alert("Failed to submit items.");
      }
    } catch (error) {
      console.error("Error submitting items:", error);
      alert("An unexpected error occurred.");
    }
  };
  const handleClickCancel = () => {
    navigate(-1);
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1">Add Items to Job</p>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Item Request for Job</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Production Id :</p>

              <input
                className="input-1 border border-[#00000036]"
                type="text"
                disabled
                value={selectedRecord.production_id}
              />
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Job Id :</p>

              <input
                className="input-1 border border-[#00000036]"
                type="text"
                disabled
                value={selectedRecord.job_id}
              />
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Job Name :</p>

              <input
                className="input-1 border border-[#00000036]"
                type="text"
                disabled
                value={selectedRecord.job_name}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item Name :</p>
          <select
            className="border rounded-[100px] border-[#00000036] p-2 w-[70%] mr-30"
            value={subItem}
            onChange={(e) => setSubItem(e.target.value)}
          >
            <option value="">Select Raw Item</option>
            {rawItems.map((rawItem, index) => (
              <option key={index} value={rawItem.id}>
                {rawItem.name}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={handleAddSubItem}
            className="bg-[#99BC85] text-white h-[30px] w-[70px] rounded-md mr-[5%]"
          >
            Add
          </button>
        </div>
      </div>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Measure Unit
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Quantity</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Action</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {selectedItems.length > 0
            ? selectedItems.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.id}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.name}
                    </p>
                  </div>
                  <div className="w-[200px] flex items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.unit}
                    </p>
                  </div>
                  <div className="w-[200px] flex  items-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        className="w-full border border-[#797979] rounded-[40px]"
                        value={item.item_qty}
                        onChange={(e) => {
                          let temp = [...selectedItems];
                          temp[index].item_qty = e.target.value;
                          setSelectedItems(temp);
                        }}
                      />
                    </p>
                  </div>

                  <div className="w-[200px] flex justify-center items-center">
                    <button
                      className="w-[full] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
                      onClick={() => {
                        let temp = [...selectedItems];
                        temp.splice(index, 1);
                        setSelectedItems(temp);
                      }}
                    >
                      <p className="p2 text-white">delete</p>
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="w-full flex justify-end items-center gap-x-10 my-10">
        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4">
          <p className="p2 text-white" onClick={handleClickCancel}>
            Cancel
          </p>
        </button>

        <button className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4">
          <p className="p2 text-white" onClick={handleClickSubmit}>
            Submit
          </p>
        </button>
      </div>
    </div>
  );
}
