/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Cookies from "js-cookie";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import { toWords } from "number-to-words";
import usePermissions from "../../../../components/permissions/permission";

const Partial_Purchase_Order_Create = () => {
  const { hasPermission } = usePermissions();
  const [issueDate, setIssueDate] = useState(new Date());
  const [deliveryDate, setDeliveryDate] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewsecond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthired, setImagePreviewthired] = useState(null);
  const [imagethired, setImagethired] = useState(null);
  const [totalAmountInWords, setTotalAmountInWords] = useState("");
  const [referenceNo, setReferenceNO] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRecord = location.state?.selectedRecord || {};
  const [tableData, setTableData] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [total, setTotal] = useState(0);
  // Define a function to fetch Procument item data

  console.log("quotation id", selectedRecord.qoutation_id);
  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/get-open-qoutation/${selectedRecord.qoutation_id}`
        );
        const data = await response.json();
        // Map through data.items and update with quantity_to_received
        const updatedItems = data.items.map((item, index) => ({
          ...item,
          item_qty_to_received:
            selectedRecord.pr_items[index]?.quantity_to_received || 0, // Assuming each item has a corresponding pr_item
          item_received: 0,
        }));

        setTableData(updatedItems);
        console.log("Updated tableData", updatedItems);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchQuotationData();
  }, []);

  useEffect(() => {
    const numericAmount = parseFloat(total);
    if (!isNaN(numericAmount) && isFinite(numericAmount)) {
      setTotalAmountInWords(convertAmountToWords(numericAmount));
    } else {
      setTotalAmountInWords("");
    }
  }, [total]);

  // Function to convert total amount to words
  const convertAmountToWords = (amount) => {
    const integerPart = Math.floor(amount);
    const decimalPart = Math.round((amount - integerPart) * 100); // Get the cents part

    const integerPartInWords = toWords(integerPart);
    let decimalPartInWords = "";

    if (decimalPart > 0) {
      decimalPartInWords = toWords(decimalPart);
      return `${integerPartInWords} rupees and ${decimalPartInWords} cents`;
    } else {
      return `${integerPartInWords} rupees`;
    }
  };

  const handleSubmit = async () => {
    // Check if referenceNo and deliveryDate are provided
    if (!referenceNo || !deliveryDate) {
      alert("Reference Number and Delivery Date cannot be empty.");
      return; // Prevent further execution
    }
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("supplier_id", selectedRecord.supplier_id);
      formData.append("reference_no", referenceNo);
      formData.append("po_issue_date", formattedDate);
      formData.append("delivery_date", deliveryDate);
      formData.append("total", total);
      formData.append("amount_in_words", totalAmountInWords);

      // Extract item_id and item_qty from tableData and add it to the formData
      const po_items = tableData.map((item) => ({
        item_id: item.item_id,
        item_qty: item.item_qty_to_receive,
        rate: item.item_unit_cost,
        amount: item.item_line_amount,
        discount_rate: item.discount_rate,
        vat: item.vat,
        ses: item.ses,
        other_tax: item.other_tax,
      }));
      formData.append("po_items", JSON.stringify(po_items));

      // Add files to the formData
      formData.append("prepared_img", image); // Append file directly
      formData.append("prepared_user_id", userId);
      formData.append("prepared_date", formattedDate);
      formData.append("checked_img", imagesecond);
      formData.append("checked_user_id", userId);
      formData.append("checked_date", formattedDate);
      formData.append("approved_img", imagethired);
      formData.append("approved_user_id", userId);
      formData.append("approved_date", formattedDate);
      formData.append("po_status", "ACTIVE");
      formData.append("pr_id", selectedRecord.pr_id);

      // Inspect the FormData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/purchase_order/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the responsereqDate
      console.log("Response:", response.data);
      alert("Purchase Request successfully added");
      navigate(-1);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Purchase Request");
    }
  };

  // Reset function
  const handleReset = () => {
    setIssueDate("");
    setDeliveryDate("");
    setTableData([]);
    setImagePreview(null);
    setImagePreviewsecond(null);
    setImagePreviewthired(null);
    setTotalAmountInWords("");
    setReferenceNO("");
    setSelectedSupplier("");
    setSubTotal(0);
    setTotalDiscount(0);
    setTotalVAT(0);
    setTotal(0);
  };

  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Signature Box
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewsecond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Theired Signature Box
  const handleDragOverthired = (e) => {
    e.preventDefault();
  };

  const handleDropthired = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthired(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const calculateSubTotal = () => {
    const total = tableData.reduce((accumulator, item) => {
      return accumulator + (item.item_line_amount || 0); // Ensure item_line_amount is a number
    }, 0);

    setTotal(total.toFixed(2)); // Set subTotal with 2 decimal points
  };
  // useEffect to update subTotal whenever tableData changes
  useEffect(() => {
    calculateSubTotal();
  }, [tableData]);

  const handleInputChange = (index, key, value) => {
    const temp = [...tableData];

    // Ensure value is not less than or equal to 0 for relevant fields
    if (key === "item_qty_to_receive") {
      value = parseFloat(value) <= 0 ? 0 : parseFloat(value); // Set to 0 if less than or equal to 0
    }

    // Update the corresponding value in the row
    temp[index][key] = value;

    // Recalculate the line amount for the item
    temp[index].item_line_amount = calculateLineAmount(temp[index]);

    setTableData(temp);
  };

  const calculateLineAmount = (item) => {
    // Extract values from the item, ensuring they are numbers
    const item_qty = parseFloat(item.item_qty_to_receive) || 0;
    const item_unit_price = parseFloat(item.unit_price) || 0;
    const item_discount_rate = parseFloat(item.discount_rate) || 0;
    const item_VAT = parseFloat(item.vat) || 0;
    const item_SES = parseFloat(item.ses) || 0;
    const item_other_TAX = parseFloat(item.other_tax) || 0;

    // Calculate the total amount before taxes and discounts
    const totalAmount = item_qty * item_unit_price;
    console.log("totalAmount", totalAmount);

    // Calculate the discount amount
    const discountAmount = (item_discount_rate / 100) * totalAmount;
    console.log("discountAmount", discountAmount);

    // Calculate the total price after discount
    const totalAfterDiscount = totalAmount - discountAmount;
    console.log("totalAfterDiscount", totalAfterDiscount);

    // Calculate the VAT, SES, and other tax amounts
    const vatAmount = (item_VAT / 100) * totalAfterDiscount;
    console.log("vatAmount", vatAmount);
    const sesAmount = (item_SES / 100) * totalAfterDiscount;
    console.log("sesAmount", sesAmount);
    const otherTaxAmount = (item_other_TAX / 100) * totalAfterDiscount;
    console.log("otherTaxAmount", otherTaxAmount);

    // Calculate the final line amount
    const lineAmount =
      totalAfterDiscount + vatAmount + sesAmount + otherTaxAmount;
    console.log("lineAmount", lineAmount);

    // Return the line amount rounded to 2 decimal places
    return parseFloat(lineAmount.toFixed(2));
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Purchase Order Form
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Create Purchase Order</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">PR No1</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.pr_id}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Request Title</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.title}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.supplier_id || ""}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={selectedRecord.supplier_name || ""}
              disabled
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Reference No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={referenceNo}
              onChange={(e) => setReferenceNO(e.target.value)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Delivery Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Delivery Date"
              selected={deliveryDate}
              onChange={(date) => setDeliveryDate(date)}
            />
          </div>
        </div>
      </div>

      <p className="header-1 mb-10">Purchase Order Item Details</p>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[300px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center "> Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Sub Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Measure Unit
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Request Quantity
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Received</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Unit Cost</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Discount Rate
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">VAT</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">SES</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Other TAX</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Qty to Receive
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Qty to Received
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[150px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Line Amount</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[300px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_category_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_sub_category_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_measure_unit}
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_quantity}
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_received}
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.unit_price}
                    </p>
                  </div>

                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.discount_rate}
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.vat}
                    </p>
                  </div>
                  <div className="w-[150px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.ses}
                    </p>
                  </div>

                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.other_tax}
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        value={item.item_qty_to_receive}
                        className={
                          item.item_quantity < item.item_qty_to_receive
                            ? "border-[#ef0e0ef7] rounded-2xl w-full border-4 px-1 focus:outline-none"
                            : "focus:outline-none border-[#00000059] rounded-2xl w-full border-2 px-1"
                        }
                        min="0"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "item_qty_to_receive",
                            e.target.value
                          )
                        }
                      />
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_qty_to_received}
                    </p>
                  </div>
                  <div className="w-[150px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_line_amount}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="w-full flex justify-end px-16 pt-3">
        <div className="w-[700px] h-full  flex flex-col gap-y-2">
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Total</p>
              <p className="p1 text-[#656565]">{total}</p>
            </div>
          </div>
          <div className="w-[700px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]">Amount In Words</p>
              <p className="p1 text-[#656565]">{totalAmountInWords}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="  grid grid-cols-3 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Ordered By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Second Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOversecond}
          onDrop={handleDropsecond}
        >
          {imagePreviewsecond ? (
            <img
              src={imagePreviewsecond}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Approved By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Thiered Signature */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverthired}
          onDrop={handleDropthired}
        >
          {imagePreviewthired ? (
            <img
              src={imagePreviewthired}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Prepared By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>

        {/* hasPermission(2770)  */}
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default Partial_Purchase_Order_Create;
