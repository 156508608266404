/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../../components/permissions/permission";

const TableDecisions = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const handleNavigatecategory = () => {
    navigate("/suppliers/category");
  };

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Decisions
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[30%] mt-[8%] mx-6  ">
        {/* Card 2 */}

        {hasPermission(1860) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Pending
              </h2>
              <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
                Purchase Requests Approvals
              </h3>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={() => navigate(`/supply/decisions/pending_approval`)}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {/* {showDialog && <DialogBox onClose={closeDialog} />} */}
          </div>
        )}
        {hasPermission(1870) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Purchase Requests
              </h2>
              <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
                for Chairman Approval
              </h3>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={() =>
                    navigate(`/supply/decisions/chariman_approval`)
                  }
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {/* {showDialog && <DialogBox onClose={closeDialog} />} */}
          </div>
        )}
        {hasPermission(1881) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Purchase Requests
              </h2>
              <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
                for GM Approval
              </h3>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={() => navigate(`/supply/decisions/gm_approval`)}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {/* {showDialog && <DialogBox onClose={closeDialog} />} */}
          </div>
        )}
        {hasPermission(1880) && (
          <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card">
            <div className="min-h-[110px] min-w-[150px]">
              <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
                Approved
              </h2>
              <h3 className="text-[28px] text-[#797C80] font-semibold mb-1">
                Purchase Requests
              </h3>
            </div>
            <div className="flex items-center">
              <div className="flex justify-end w-full">
                <button
                  onClick={() => navigate(`/supply/decisions/create_po`)}
                  className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]"
                >
                  View
                </button>
              </div>
            </div>
            {/* {showDialog && <DialogBox onClose={closeDialog} />} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableDecisions;
