 

import React from "react";

import { Link } from "react-router-dom";
import usePermissions from "../../components/permissions/permission";

const Recipe_nav = () => {
  const { hasPermission } = usePermissions();
  const recipeData = [
    {
      id: 1,
      header: "Create Recipes",

      headerClasses: "text-black",
      linkTo: "/create-recipe",
      permissionId: 1300,
    },
    {
      id: 2,
      header: "Pending Recipes",

      headerClasses: "text-black",
      linkTo: "/pending-recipe",
      permissionId: 1310,
    },
    {
      id: 3,
      header: "Approved Recipes",

      headerClasses: "text-black",
      linkTo: "/created-recipe",
      permissionId: 1320,
    },
    {
      id: 4,
      header: "Rejected Recipes",

      headerClasses: "text-black",
      linkTo: "/rejected-recipe",
      permissionId: 1330,
    },
  ];

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80] ">
        Recipe
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6 ">
        {recipeData.map((card) =>
          hasPermission(card.permissionId) ? (
            <Link key={card.id} to={card.linkTo}>
              <div
                key={card.id}
                className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  {/* Conditional rendering based on header words */}
                  {card.header.split(" ").map((word, index) => (
                    <h2 key={index} className="text-[20px] font-semibold mb-1">
                      {word}
                    </h2>
                  ))}
                </div>
                <div className="flex justify-between items-center">
                  <button className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]">
                    Click Here
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Recipe_nav;
