 

import React from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

const Loan_nav = () => {
  const { hasPermission } = usePermissions();
  return (
    <div className="font-sans bg-background h-screen overflow-y-auto">
      <div>
        <p className="text-[40px] text-headerColor font-semibold ml-6 mt-6 text-header">
          Loan Information
        </p>
      </div>

      <div>
        <div className="grid grid-cols-2 grid-flow-col gap-[100px] ml-[200px] mr-[200px] mt-[150px] ">
          {hasPermission(1060) && (
            <div className="bg-white rounded-[30px]">
              <div className="p-3">
                <div>
                  <div className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                    <p>View Loan</p>
                    <p>Information</p>
                  </div>
                </div>
                <Link to="/view-loan-info">
                  <div className="flex justify-end mr-[5%] mb-[5%]">
                    <button className="bg-primary text-white p-2 rounded-[20px] font-semibold">
                      View
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          )}
          {hasPermission(1061) && (
            <div className="bg-white rounded-[30px]">
              <div className="p-3">
                <div className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                  <p>Review</p>
                  <p>Guarants</p>
                </div>
                <Link to="/review-guarantors">
                  <div className="flex justify-end">
                    <button className="bg-primary rounded-[30px] text-white p-2 mr-[5%] font-semibold">
                      View
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loan_nav;
