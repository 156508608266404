import React, { useState } from "react";
import "./addSupplier.css";
import IdGenerate from "../../../utils/id_generate";
import axios from "axios";

export default function AddSupplier(props) {
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string
  const [userId, setUserId] = useState("USER-000000");

  const [data, setData] = useState({
    supplier_id: IdGenerate("SUPPLIER"),
    supplier_name: "",
    supplier_nic: "",
    supplier_address: "",
    supplier_contact_no: "",
    supplier_email:""
  });

  const AddHandler = async () => {
    const Data = {
      supplier_id: data.supplier_id,
      supplier_name: data.supplier_name,
      supplier_nic: data.supplier_nic,
      supplier_address: data.supplier_address,
      supplier_contact_no: data.supplier_contact_no,
      supplier_email:data.supplier_email,
      supplier_update_date: formattedDate,
      supplier_update_user_id: userId,
    };

    try {
      const res = await axios.post( `${process.env.REACT_APP_BACKEND_URL}/supplier/add`,Data)
      if (res.status === 200) {
        alert("Supplier Added Successfully");
        CancelHandler();
      }
    } catch (error) {
      if(error.response.status === 500){
        window.alert('Internal server error');
        console.log(error);
    }else if(error.response.status === 400){
        window.alert('please fill required fields');
        console.log(error);
    }else if(error.response.status === 409){
        window.alert('supplier already exist')
    }
    }
  };

  const CancelHandler = () => {
    setData({
      supplier_id: IdGenerate("SUPPLIER"),
      supplier_name: "",
      supplier_nic: "",
      supplier_address: "",
      supplier_contact_no: "",
      supplier_email:""
    });
  };
  return (
    <div className="p-10 bg-background">
      <p className="header-1"> Add Supplier</p>

      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center my-5">Enter Supplier Details</p>

          {/* <div className="w-[600px] flex items-center justify-between my-5">
            <label className="label-1">Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.supplier_id}
              onChange={""}
              disabled
            />
          </div> */}

          <div className="w-[600px] flex items-center justify-between my-5">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.supplier_name}
              onChange={(e) => {
                setData({
                  ...data,
                  supplier_name: e.target.value,
                });
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-5">
            <label className="label-1">Supplier NIC</label>
            <label className=" label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.supplier_nic}
              onChange={(e) => {
                setData({
                  ...data,
                  supplier_nic: e.target.value,
                });
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-5">
            <label className="label-1">Supplier Address</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.supplier_address}
              onChange={(e) => {
                setData({
                  ...data,
                  supplier_address: e.target.value,
                });
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-5">
            <label className="label-1">Supplier Contact No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={data.supplier_contact_no}
              onChange={(e) => {
                setData({
                  ...data,
                  supplier_contact_no: e.target.value,
                });
              }}
            />
          </div>

          <div className="w-[600px] flex items-center justify-between my-5">
            <label className="label-1">Supplier E-mail</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="email"
              value={data.supplier_email}
              onChange={(e) => {
                setData({
                  ...data,
                  supplier_email: e.target.value,
                });
              }}
            />
          </div>

        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 mb-10 px-[200px]">
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4"
            onClick={() => AddHandler()}
          >
            <p className="text-white">ADD</p>
            
          </button>
          <button
            className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
            onClick={() => CancelHandler()}
          >
           <p className="text-white">CANCEL</p>
          </button>
        </div>
    </div>
  );
}
