import React, { useState, useEffect, useCallback, useMemo } from "react";

const SupplyChain_Permission = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const permissionHierarchy = useMemo(
    () => ({
      15: { children: [240, 241, 242, 243, 244, 245, 246] },
      240: { children: [1790, 1800] },
      241: { children: [1810, 1820, 1830, 1840, 1850] },
      242: { children: [1860, 1870, 1880, 1881] },
      243: { children: [1890] },
      244: { children: [1910, 1920, 1930, 1940, 1950] },
      245: { children: [1960] },
      1810: { children: [2600, 2610, 2620] },
      1820: { children: [2630, 2640, 2650, 2660, 2670, 2680] },
      1880: { children: [2690] },
      1890:{children:[2700]},
      1910: { children: [2910, 2920] },
      1920: { children: [2930] },
      1930: { children: [2940, 2950] },
      1940: { children: [2960] },
      1950: { children: [2970, 2980] },

      // Add the rest of your permissions here
    }),
    []
  );




  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };



  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure HRIS permission ID (37) is included if any HRIS permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[15].children.includes(perm)
      ) &&
      !selectedPermissions.includes(15)
    ) {
      selectedPermissions.push(15);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);


   return (
    <div>
      <div>
        <div className="">
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Supply Chain
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Dashboard
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1790"
                      checked={!!checkedValues[1790]}
                      onChange={() => handleCheckboxChange(1790)}
                    />
                    Suppliers
                  </label>

                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1800"
                      checked={!!checkedValues[1800]}
                      onChange={() => handleCheckboxChange(1800)}
                    />
                    Purchase requests
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchase Requests
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1810"
                      checked={!!checkedValues[1810]}
                      onChange={() => handleCheckboxChange(1810)}
                    />
                    Create Purchase Requests
                  </label>

                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2600"
                        checked={!!checkedValues[2600]}
                        onChange={() => handleCheckboxChange(2600, 1810)}
                      />
                      create purchase Request
                    </label>
                  </div>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2600"
                        checked={!!checkedValues[2610]}
                        onChange={() => handleCheckboxChange(2610, 241)}
                      />
                      pending purchase Requests for Approval
                    </label>
                  </div>

                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2600"
                        checked={!!checkedValues[2620]}
                        onChange={() => handleCheckboxChange(2620, 241)}
                      />
                      Pending Purchase Requests for Prepared
                    </label>
                  </div>

                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1820"
                      checked={!!checkedValues[1820]}
                      onChange={() => handleCheckboxChange(1820)}
                    />
                    Subjects
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2630"
                          checked={!!checkedValues[2630]}
                          onChange={() => handleCheckboxChange(2630, 1820)}
                        />
                        RAW-DRIED
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2640"
                          checked={!!checkedValues[2640]}
                          onChange={() => handleCheckboxChange(2640, 1820)}
                        />
                        RAW-FRESH
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2650"
                          checked={!!checkedValues[2650]}
                          onChange={() => handleCheckboxChange(2650, 1820)}
                        />
                        RAW-OIL
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2660"
                          checked={!!checkedValues[2660]}
                          onChange={() => handleCheckboxChange(2660, 1820)}
                        />
                        RAW - PACKING AND STATIONARY
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2670"
                          checked={!!checkedValues[2670]}
                          onChange={() => handleCheckboxChange(2670, 1820)}
                        />
                        RAW - RASA
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2680"
                          checked={!!checkedValues[2680]}
                          onChange={() => handleCheckboxChange(2680, 1820)}
                        />
                        RAW - CHEMICAL
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1830"
                      checked={!!checkedValues[1830]}
                      onChange={() => handleCheckboxChange(1830)}
                    />
                    Purchase Requests
                  </label>

                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1840"
                      checked={!!checkedValues[1840]}
                      onChange={() => handleCheckboxChange(1840)}
                    />
                    Request Approved by Chairman
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1850"
                      checked={!!checkedValues[1850]}
                      onChange={() => handleCheckboxChange(1850)}
                    />
                    Request Approved for Quotation
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1"></div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Decisions
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1860"
                      checked={!!checkedValues[1860]}
                      onChange={() => handleCheckboxChange(1860)}
                    />
                    Pending Purchase Requests Approvals
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1870"
                      checked={!!checkedValues[1870]}
                      onChange={() => handleCheckboxChange(1870)}
                    />
                    Purchase Requests for Chairman Approval
                  </label>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1880"
                      checked={!!checkedValues[1880]}
                      onChange={() => handleCheckboxChange(1880)}
                    />
                    Approved Purchase Requests
                  </label>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2690"
                        checked={!!checkedValues[2690]}
                        onChange={() => handleCheckboxChange(2690, 1880)}
                      />
                      Create a PO
                    </label>
                  </div>
                  
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1881"
                      checked={!!checkedValues[1881]}
                      onChange={() => handleCheckboxChange(1881)}
                    />
                   Purchase Requests for GM Approval
                  </label>

              
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Suppliers
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1890"
                      checked={!!checkedValues[1890]}
                      onChange={() => handleCheckboxChange(1890)}
                    />
                    Supplier Details
                  </label>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2700"
                        checked={!!checkedValues[1890]}
                        onChange={() => handleCheckboxChange(3500, 1890)}
                      />
                      Add Supplier
                    </label>
                  </div>

                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1"></div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  committees
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1910"
                      checked={!!checkedValues[1910]}
                      onChange={() => handleCheckboxChange(1910)}
                    />
                    Appoint Committee Members
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="2910"
                            checked={!!checkedValues[2910]}
                            onChange={() => handleCheckboxChange(2910, 1910)}
                          />
                          Create
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] text-black">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="2920"
                            checked={!!checkedValues[2920]}
                            onChange={() => handleCheckboxChange(2920, 1910)}
                          />
                          View
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] text-black"></div>
                    <div className="ml-[9%] text-black"></div>
                    <div className="ml-[9%] text-black"></div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1920"
                      checked={!!checkedValues[1920]}
                      onChange={() => handleCheckboxChange(1920)}
                    />
                    Technical Evaluation Committee
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-5 mt-2">
                      {/* <div className="ml-[9%] text-black">
                        <div className="text-black">
                          <label className="block mb-2">
                            <input
                              type="checkbox"
                              className="mr-2"
                              value="2930"
                              checked={!!checkedValues[2930]}
                              onChange={() => handleCheckboxChange(2930, 1920)}
                            />
                            View
                          </label>
                        </div>
                      </div> */}
                    </div>
                    <div className="ml-5 mt-2"></div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1930"
                      checked={!!checkedValues[1930]}
                      onChange={() => handleCheckboxChange(1930)}
                    />
                    Sample Testing Committee
                  </label>
                  <div className="ml-[9%] text-black">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3630"
                          checked={!!checkedValues[3630]}
                          onChange={() => handleCheckboxChange(2940, 1930)}
                        />
                        Create
                      </label>
                    </div>
                  </div>

                  <div className="ml-[9%] text-black">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="3630"
                          checked={!!checkedValues[3640]}
                          onChange={() => handleCheckboxChange(2950, 1930)}
                        />
                        View
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1940"
                      checked={!!checkedValues[1940]}
                      onChange={() => handleCheckboxChange(1940)}
                    />
                    Tender Committee
                  </label>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="3650"
                        checked={!!checkedValues[3650]}
                        onChange={() => handleCheckboxChange(3650, 1940)}
                      />
                      View
                    </label>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1950"
                      checked={!!checkedValues[1950]}
                      onChange={() => handleCheckboxChange(1950)}
                    />
                    Opening Price From
                  </label>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2970"
                        checked={!!checkedValues[2970]}
                        onChange={() => handleCheckboxChange(2970, 1950)}
                      />
                      Create
                    </label>
                  </div>
                  <div className="text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="2980"
                        checked={!!checkedValues[2980]}
                        onChange={() => handleCheckboxChange(2980, 1950)}
                      />
                      View
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchase Order
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1960"
                      checked={!!checkedValues[1960]}
                      onChange={() => handleCheckboxChange(1960)}
                    />
                    Purchase Order
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  vouchers
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500"></label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupplyChain_Permission;
