// src/components/DocumentUploadPopup.js

import React, { useState } from 'react';

const DocumentUploadPopup = ({ onClose }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    // Handle file upload logic
    console.log("File uploaded:", file);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl mb-4 font-bold">Upload Document</h2>
        <input type="file" onChange={handleFileChange} />
        <div className="mt-4">
          <button
            className="bg-[#9CAC8B] text-white px-4 py-2 rounded mr-2"
            onClick={handleUpload}
          >
            Upload
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadPopup;
