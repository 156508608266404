import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Logo_Loader from "../../../../Assets/icons/LOGO-LOADER.png"; // HRIS Logo path

const Summary_Report = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [data, setData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  // Fetch departments on component mount
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch(
          `${API_URL}/v1/hris/designations/getdesignation`
        );
        const result = await response.json();
        const departmentsData = Array.isArray(result)
          ? result.map((item) => item.department)
          : [];
        setDepartments([...new Set(departmentsData)]);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();

    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFetchData = async () => {
    if (startDate && endDate) {
      setIsLoading(true); // Start loading
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
      const url = `${API_URL}/v1/hris/attendence/getLeaveByDateRange?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;

      try {
        const response = await fetch(url);
        const result = await response.json();
        setData(Array.isArray(result) ? result : []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  const getUniqueDepartments = () => departments;

  const filteredData = data.filter((row) => {
    const employeeNoMatch = row.employee_no
      ? row.employee_no.toLowerCase().includes(searchQuery.toLowerCase())
      : false;

    const departmentMatch = selectedDepartment
      ? row.department === selectedDepartment
      : true;

    return employeeNoMatch && departmentMatch;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleExportExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Summary Report");

    worksheet.columns = [
      { header: "Date", key: "Date", width: 30 },
      { header: "Employee No", key: "EmployeeNo", width: 30 },
      { header: "Leave Type", key: "LeaveType", width: 30 },
      { header: "Reason", key: "Reason", width: 30 },
      { header: "Employee Fullname", key: "EmployeeFullname", width: 30 },
      { header: "Requested Date", key: "RequestedDate", width: 30 },
      { header: "Check-In Time", key: "CheckInTime", width: 30 },
      { header: "Check-Out Time", key: "CheckOutTime", width: 30 },
      { header: "Status", key: "Status", width: 30 },
    ];

    filteredData.forEach((row) => {
      worksheet.addRow({
        Date: moment(row.date).format("D-MMM-YY"),
        EmployeeNo: row.employee_no || "- -",
        LeaveType: row.leave_type || "- -",
        Reason: row.reason || "- -",
        EmployeeFullname: row.employee_fullname || "N/A",
        RequestedDate: row.requested_date
          ? moment(row.requested_date).format("D-MMM-YY")
          : "- -",
        CheckInTime: row.checkIN_time
          ? moment(row.checkIN_time, "HH:mm:ss.SSSSSS").format("HH:mm")
          : "- -",
        CheckOutTime: row.checkOUT_time
          ? moment(row.checkOUT_time, "HH:mm:ss.SSSSSS").format("HH:mm")
          : "- -",
        Status: row.status || "- -",
      });
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "6d709c" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f3f4f6" },
      };
      cell.alignment = { horizontal: "center" };
    });

    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber !== 1) {
        const isEvenRow = rowNumber % 2 === 0;
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: isEvenRow ? "f4e5ff" : "FFFFFFFF" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });
        row.height = 30;
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "summary_report.xlsx");
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPages = Math.floor(maxPagesToShow / 2);
    const startPage = Math.max(1, currentPage - halfMaxPages);
    const endPage = Math.min(totalPages, currentPage + halfMaxPages);

    if (currentPage > halfMaxPages + 1) {
      pageNumbers.push(
        <li key="start" className="mx-1">
          <button
            onClick={() => handlePageChange(1)}
            className="px-4 py-2 border rounded bg-white text-gray-800"
          >
            1
          </button>
          <span className="px-2">...</span>
        </li>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => handlePageChange(i)}
            className={`px-4 py-2 border rounded ${
              currentPage === i
                ? "bg-[#7226a5] text-white"
                : "bg-white text-gray-800"
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="end" className="mx-1">
          <span className="px-2">...</span>
          <button
            onClick={() => handlePageChange(totalPages)}
            className="px-4 py-2 border rounded bg-white text-gray-800"
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return <ul className="flex">{pageNumbers}</ul>;
  };

  const getStatusStyle = (actionType) => {
    switch (actionType) {
      case "late in":
        return "bg-[#FFE9D0] p-1";

      case "early out":
        return "bg-[#FFFED3] p-1";

      case "late in / early out":
        return "bg-[#BBE9FF] p-1";

      case "missing out":
        return "bg-[#FABC3F] text-white p-1";

      case "missing in":
        return "bg-[#E85C0D] text-white p-1";

      case "normal check-in":
        return "bg-[#B1AFFF] text-white p-1";

      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  // Loader component with semi-transparent overlay
  const Loader = () => {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
        <img
          src={Logo_Loader}
          alt="HRIS Logo"
          className="animate__pulse animate__animated animate__infinite w-40 h-40"
        />
      </div>
    );
  };

  return (
    <div className="relative bg-background overflow-y-auto h-screen">
      <div className="mx-6">
        {isLoading && <Loader />}
        <div
          className={`${
            isLoading ? "opacity-50" : "opacity-100"
          } transition-opacity duration-300`}
        >
          <div>
            <div className="flex justify-between mt-5 items-center">
              <p className="text-[30px] font-semibold">Summary Report</p>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex justify-between items-center">
              <div className="flex gap-4 items-center">
                <div className="relative">
                  <input
                    className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                    placeholder="Search by Employee Number"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
                </div>
              </div>
              <div className="relative flex items-center gap-3 ">
                <select
                  className="text-black px-4 py-2 rounded-md shadow-custom"
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                >
                  <option value="">All Departments</option>
                  {getUniqueDepartments().map((dept, index) => (
                    <option key={index} value={dept}>
                      {dept}
                    </option>
                  ))}
                </select>
                <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
                  <FaCalendarAlt className="h-5 w-5 text-gray-400" />
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="d MMM, yyyy"
                    className="text-sm text-gray-600 focus:outline-none"
                    placeholderText="Start Date"
                  />
                  <span className="text-gray-400">-</span>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="d MMM, yyyy"
                    className="text-sm text-gray-600 focus:outline-none"
                    placeholderText="End Date"
                  />
                </div>
                <button
                  className="px-4 py-2 bg-primary text-white rounded-md shadow-custom"
                  onClick={handleFetchData}
                >
                  Get Data
                </button>
              </div>
              <div>
                <button
                  className=" mr-5 px-4 py-2 rounded-md bg-primary shadow-custom text-white p-2"
                  onClick={handleExportExcel}
                >
                  Export Document
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4 mt-4 min-w-screen">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Employee No
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Leave Type
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Reason
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Requested Date
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Department
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Employee Fullname
                    </th>

                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Check In Time
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Check Out Time
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {paginatedData.length > 0 ? (
                    paginatedData.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          index % 2 === 0 ? "bg-green-100" : "bg-white"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {moment(item.date).format("D-MMM-YY")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {item.employee_no || "- -"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {item.leave_type || "- -"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {item.reason || "- -"}
                        </td>{" "}
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {item.requested_date || "- -"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {item.department || "- -"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {item.employee_fullname || "- -"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {moment(item.checkIN_time).format("HH:mm:ss")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {moment(item.checkOUT_time).format("HH:mm:ss")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-md text-black">
                          <span
                            className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getStatusStyle(
                              item.status
                            )}`}
                          >
                            {item.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="9"
                        className="px-3 py-2 text-center text-gray-500 text-sm"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center my-4 min-w-screen">
              <nav>{renderPagination()}</nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary_Report;
