 

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../components/permissions/permission";

export default function UpdateTrainingType() {
  const { hasPermission } = usePermissions();
  const [trainingTypeData, setTrainingTypeData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedTrainingType, setSelectedTrainingType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/trainingtype/gettrainingtype`
      );
      const data = await response.json();
      setTrainingTypeData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditTrainingType = (trainingType) => {
    setSelectedTrainingType(trainingType);
    setEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedTrainingType((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateTrainingType = async () => {
    try {
      if (!selectedTrainingType || !selectedTrainingType.training_type) {
        console.error("Selected training type is missing.");
        return;
      }

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/trainingtype/updatetrainingtype?id=${selectedTrainingType.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedTrainingType),
        }
      );

      if (response.ok) {
        console.log("Training type updated successfully!");
        setEditModalOpen(false);
        fetchData(); // Fetch updated data
      } else {
        console.error(
          `Failed to update training type. Server responded with status ${response.status}.`
        );
        const errorData = await response.json(); // Parse and log any additional error data from the server
        console.error("Error details:", errorData);
      }
    } catch (error) {
      console.error("Error updating training type:", error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = trainingTypeData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="p-10 bg-background h-screen overflow-y-auto">
      <p className="header-1">View & Update Training Type</p>

      {/* Designation Table */}
      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
        <table className="table-auto border-none mt-10">
          <thead>
            <tr className="bg-white">
              <th className="text-PrimaryColor1 px-4 py-2">Training Type</th>
              <th className="text-PrimaryColor1 px-4 py-2">
                Training Type Description
              </th>
              <th className="text-PrimaryColor1 px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((trainingType) => (
              <tr
                key={trainingType.id}
                onClick={() => handleEditTrainingType(trainingType)}
              >
                <td className="bg-white px-4 py-2">
                  {trainingType.training_type}
                </td>
                <td className="bg-white px-4 py-2">
                  {trainingType.training_type_description}
                </td>
                <td className="bg-white px-4 py-2">
                  {hasPermission(1770) && (
                    <button className="bg-gray-300 p-2 rounded-md">Edit</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-PrimaryColor1 text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(trainingTypeData.length / itemsPerPage))].map(
          (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-PrimaryColor1 px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(trainingTypeData.length / itemsPerPage)
          }
          className="bg-PrimaryColor1 text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>

      {/* Edit Designation Modal */}
      {editModalOpen && (
        <div className="modal mt-10">
          <div className="modal-content">
            <div className="w-full flex items-center justify-between">
              <div className="header-1">Update Training Type Details</div>
              <span
                className="cursor-pointer p-2 text-gray-500 w-[118px] h-[45px] flex items-center justify-center bg-PrimaryColor2 border rounded-[23px] px-4"
                onClick={() => setEditModalOpen(false)}
              >
                Close
              </span>
            </div>
            <div className="w-full flex items-center justify-center">
              <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
                <p className="header-1 text-center mb-6">
                  Update Training Type Details
                </p>
                <div className="w-[600px] flex items-center justify-between my-10">
                  <label className="label-1">Training Type:</label>
                  <input
                    className="input-1 border border-[#00000036]"
                    type="text"
                    name="training_type"
                    value={selectedTrainingType?.training_type || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-[600px] flex items-center justify-between my-10">
                  <label className="label-1">Training Type Description:</label>
                  <input
                    className="input-1 border border-[#00000036]"
                    type="text"
                    name="training_type_description"
                    value={
                      selectedTrainingType?.training_type_description || ""
                    }
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center justify-end gap-x-6 mb-10 px-[360px]">
                  <button
                    className="w-[118px] h-[45px] flex items-center justify-center bg-[#ab2626] border rounded-[23px] px-4"
                    onClick={handleUpdateTrainingType}
                  >
                    <p className="p2 text-white">Update</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
