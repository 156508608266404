 

import React, { useState, useEffect } from "react";
import usePermissions from "../../../components/permissions/permission";

function OveroleProductionTable({ setSelectedProductionId }) {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null); // State to keep track of the selected row
  const { REACT_APP_API_ENDPOINT } = process.env;
  const itemsPerPage = 5;
  const maxPageNumbers = 5;
  let totalPages = Math.ceil(tableData.length / itemsPerPage);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/productionlevel/allproduction`
    )
      .then((response) => response.json())
      .then((data) => {
        const mappedData = data.map(
          ({
            job_name,
            completed_stages,
            total_stages,
            production_manager,
            production_id, // Added production_id to data mapping
          }) => ({
            Production: job_name,
            Progress: completed_stages,
            StageCount: total_stages,
            Supervisor: production_manager,
            ProductionId: production_id, // Added production_id to mapped data
          })
        );
        setTableData(mappedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Slice data based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedRows = tableData.slice(startIndex, endIndex);

  const handleRowClick = (row) => {
    setSelectedProductionId(row.ProductionId); // Set the selected production_id in the parent component state
    setSelectedRow(row); // Set the selected row for applying hover effect
  };

  return (
    <div className="box-border h-screen w-full p-4 bg-[#FFFFFF] ml-9 mt-5">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#99BC85]">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
              >
                Production
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
              >
                Progress
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
              >
                Stage Count
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
              >
                Supervisor
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan="4" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : (
              slicedRows.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(row)} // Call handleRowClick on row click
                  className={selectedRow === row ? "bg-gray-200" : ""}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    {row.Production}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {row.Progress}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {row.StageCount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {row.Supervisor}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 ml-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
    </div>
  );
}

export default OveroleProductionTable;
