/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Cookies from "js-cookie";
import cloudiccon from "../../../../Assets/icons/cloud-add.png";
import FileIcon from "../../../../Assets/icons/pdf_file.png";

const Pending_Tender_Quo_View = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const userId = Cookies.get("employee_no");
  const userName = Cookies.get("employee_name_initial");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [negoDis, setNegoDis] = useState("");
  const [netTotal, setNetTotal] = useState(0);
  const [category, setCategory] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const location = useLocation();
  const { selectedRecord = {} } = location.state || {};
  const [tableData, setTableData] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  console.log("selectedRec", selectedRecord);
  const [memberSignature, setMemberSignature] = useState({});

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/get-open-qoutation/${selectedRecord.qoutation_id}`
        );
        const data = await response.json();

        setTableData(data);
        console.log("tableData", data);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchQuotationData();
  }, []);

  const handleRecommend = async (
    memberId,
    approveStatusKey,
    signaturePathKey,
    index
  ) => {
    try {
      const formData = new FormData();

      // Add text data to the FormData object
      formData.append("quotation_id", selectedRecord.qoutation_id);
      formData.append("member", userId);
      formData.append("approve_status", "APPROVE");
      formData.append("comment_at", formattedDate);

      console.log("signature", memberSignature[index]);

      // Append the correct signature file for the given index
      if (memberSignature[index]) {
        formData.append("signature", memberSignature[index]); // Use the correct index to access the signature
      } else {
        alert("No signature provided.");
        return;
      }

      // Prepare negotiated discounts and add them to FormData
      const negotiated_discounts = tableData.items.map((item) => ({
        item_id: item.item_id,
        discount: item.negoDis,
      }));
      formData.append(
        "negotiated_discounts",
        JSON.stringify(negotiated_discounts)
      );

      // Send the request
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/approve-tender-by-member`,
        {
          method: "PATCH",
          body: formData, // Pass FormData directly as the body
        }
      );

      // Handle the response
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Response:", result);
      alert("Pending Purchase Request successfully sent for approvals");
      navigate(-1);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to send approvals Purchase Request");
    }
  };

  const handleReject = (memberId, approveStatusKey, signaturePathKey) => {
    // Update the state with rejection and possibly handle API logic
    // Example: Update the member's status to "REJECTED"
  };

  // Function to prevent the default behavior when dragging over the signature area
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Function to handle the drop of the signature for a specific member
  const handleDrop = (e, index) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0]; // Get the first dropped file

    if (file && file.type.startsWith("image/")) {
      setMemberSignature((prevSignatures) => {
        // Create a new object with the updated signature
        const updatedSignatures = { ...prevSignatures, [index]: file };
        console.log("Updated Signatures:", updatedSignatures);
        return updatedSignatures;
      });
    } else {
      alert("Please drop a valid image file.");
    }
  };

  // Function to create a preview URL if needed
  const getSignaturePreview = (index) => {
    const signature = memberSignature[index];
    return signature ? URL.createObjectURL(signature) : null;
  };

  const handleInputChange = (index, key, value) => {
    // Create a copy of the items array
    const updatedItems = [...tableData.items];

    // Ensure value is not less than or equal to 0 for relevant fields
    if (key === "negoDis" && value <= 0) {
      value = 0; // Set to 0 if less than or equal to 0
    }

    // Update the specific item in the copied array
    updatedItems[index][key] = parseFloat(value) || 0;

    // Recalculate the net line amount for the updated item
    updatedItems[index].net_line_amount = calculateLineAmount(
      updatedItems[index]
    );

    // Update the tableData state with the modified items array
    setTableData((prev) => ({
      ...prev,
      items: updatedItems,
    }));
  };

  const calculateLineAmount = (item) => {
    // Calculate the line amount based on quantity, unit price, and various rates
    const { negoDis, line_amount } = item;

    // Calculate the discount amount
    const discountAmount = (negoDis / 100) * line_amount;
    console.log("discountAmount", discountAmount);

    // Final line amount calculation
    const net_line_amount = line_amount - discountAmount;
    console.log("lineAmount", net_line_amount);

    return parseFloat(net_line_amount.toFixed(2));
  };

  const calculateNetTotal = () => {
    const total = (tableData?.items || []).reduce((accumulator, item) => {
      return accumulator + (item.net_line_amount || 0); // Ensure net_line_amount is a number
    }, 0);

    setNetTotal(total.toFixed(2)); // Set net total with 2 decimal points
  };

  // useEffect to update netTotal whenever tableData changes
  useEffect(() => {
    if (tableData?.items) {
      calculateNetTotal();
    }
  }, [tableData]);

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Quotaion For Approve
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] relative p-6">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request No:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
              type="text"
              value={tableData.pr_id}
              readOnly
            />
          </div>
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
              Purchase Request Title:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
              value={tableData.title}
              readOnly
            />
          </div>
          <div className="flex items-center col-span-2">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
              Tender Committee:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
              value={tableData.title}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-[#F3F8FF] w-[97%] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Quotation</p>
          {/* Search input for supplier name */}
          <div className="w-[600px] flex items-center justify-between my-10 relative">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tableData.supplier_name}
              readOnly
            />
          </div>

          {/* Supplier ID input (auto-populated) */}
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier ID</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tableData.supplier_id}
              readOnly
            />
          </div>

          <p className="header-1 mb-10">Purchase Request Item Details</p>

          <div className="flex flex-col justify-start w-[125%]  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
            <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
              <p className="w-10 p1 text-[#656565] text-center">#</p>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Name
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Qty
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Measure Unit
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Unit Price
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Discount Rate
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">VAT</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">SES</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Other TAX
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Line Amount
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Negotiated Amount
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Net Amount
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
            </div>
            <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
              {tableData?.items?.length > 0 ? (
                tableData.items.map((item, index) => (
                  <div key={index} className="w-full  flex justify-around ">
                    <div className="w-10 flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {index + 1}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_id}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_name}
                      </p>
                    </div>
                    <div className="w-[200px] flex text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.item_quantity}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 ml-5 text-[#656565] text-center ">
                        {item.item_measure_unit}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.unit_price}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.discount_rate}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.vat}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.ses}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 text-[#656565] text-center ">
                        {item.other_tax}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 ml-5 text-[#656565] text-center ">
                        {item.line_amount}
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="p1 ml-10 text-[#656565] text-center">
                        <input
                          className="w-full border border-[#797979] rounded-[40px]"
                          type="number"
                          min={0}
                          value={item.negoDis || 0}
                          onChange={(e) =>
                            handleInputChange(index, "negoDis", e.target.value)
                          }
                        />
                      </p>
                    </div>
                    <div className="w-[200px] flex  text-center">
                      <p className="w-full p1 ml-5 text-[#656565] text-center ">
                        {item.net_line_amount}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No items available</p>
              )}
            </div>
          </div>

          <div className="w-full flex justify-end items-start px-16 pt-3 ml-[15%]">
            <div className="w-[550px] h-full  flex flex-col gap-y-2">
              <div className="w-[550px] h-full rounded-[23px] bg-white flex flex-col px-4 py-3">
                <div className="flex items-center justify-between">
                  <p className="p1 text-[#656565]"> Sub Total</p>
                  <p className="p1 text-[#656565]">{tableData.net_total}</p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="p1 text-[#656565]"> Negotiated Total</p>
                  <p className="p1 text-[#656565]">{netTotal}</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" flex items-center justify-center mt-5">
            <div>
              <h2 className="text-dialogHeader font-poppins font-semibold text-[26px] text-center">
                Upload Files
              </h2>
              <hr className=" ml-[20%] w-[60%] border-solid border-b-2 border-black"></hr>
            </div>
          </div>
          <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[60%] flex-shrink-0 mt-5 ml-[10%]">
            <main className="container mx-auto max-w-screen-lg h-full">
              <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                Uploaded Quotation and Decision Document
              </h1>

              <div className="flex justify-around mt-5">
                {" "}
                {/* Flex container for 2 columns */}
                {/* Quotation Document */}
                {tableData.qoutation_document_path ? (
                  <div className="flex flex-col items-center">
                    <a
                      href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/openingcommittee/files/${tableData.qoutation_document_path}`}
                      download={tableData.qoutation_document_path}
                      className="cursor-pointer"
                    >
                      <img
                        className="h-[100px] w-[100px] object-contain"
                        src={FileIcon}
                        alt="File Icon"
                      />
                    </a>
                    <p>Quotation Document</p>
                  </div>
                ) : (
                  <p>No Quotation Document</p>
                )}
                {/* Decision Document */}
                {tableData.qoutation_document_path ? (
                  <div className="flex flex-col items-center">
                    <a
                      href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/get-desition-doc-by-name/${tableData.qoutation_document_path}`}
                      download={tableData.qoutation_document_path}
                      className="cursor-pointer"
                    >
                      <img
                        className="h-[100px] w-[100px] object-contain"
                        src={FileIcon}
                        alt="File Icon"
                      />
                    </a>
                    <p>Decision Document</p>
                  </div>
                ) : (
                  <p>No Decision Document</p>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-5 ml-6">
        {selectedRecord.tender_committee_vote.members.map((member, index) => {
          const memberId = member[`comment_by${index + 1}`];
          const memberName = member[`member_name${index + 1}`];
          const signaturePathKey = `signature_path${index + 1}`;
          const approveStatusKey = `approve_status${index + 1}`;

          return (
            <div
              key={index}
              className="flex flex-col items-center justify-center mt-6"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e, index)}
            >
              {/* Show signature preview or drop area */}
              {memberSignature[index] || member[signaturePathKey] ? (
                <img
                  src={
                    memberSignature[index]
                      ? getSignaturePreview(index) // Use preview URL
                      : member[signaturePathKey]
                  }
                  alt="Signature Preview"
                  className="w-40 h-40 rounded-lg mb-4"
                />
              ) : (
                <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
                  <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                    {memberId === userId
                      ? "Recommend By You"
                      : `Recommended By ${memberId}`}
                  </p>
                  <img
                    src={cloudiccon}
                    alt="Cloud Icon"
                    className="w-16 h-16 mb-2"
                  />
                  <p className="text-[#8691AB] font-bold ml-4">
                    Drag and Drop Digital Signature
                  </p>
                  <p className="text-black font-semibold ml-2 mt-2 text-[14px]">
                    {memberId === userId ? userName : memberName}
                  </p>
                </div>
              )}

              {/* Action Buttons */}
              {memberId === userId && (
                <div className="w-full flex items-center justify-center gap-x-6 my-10 px-16">
                  <button
                    className="text-white font-bold text-xl py-2 px-4 rounded-lg bg-[#AA4343]"
                    onClick={() =>
                      handleReject(memberId, approveStatusKey, signaturePathKey)
                    }
                  >
                    Reject
                  </button>
                  <button
                    className="text-white font-bold text-xl py-2 px-4 rounded-lg bg-[#5B6D49] hover:bg-[#495e33]"
                    onClick={() =>
                      handleRecommend(
                        memberId,
                        approveStatusKey,
                        signaturePathKey,
                        index
                      )
                    }
                  >
                    Recommend
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Pending_Tender_Quo_View;
