/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Inventory_Permssion = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const permissionHierarchy = useMemo(
    () => ({
      2: { children: [110, 111, 112, 113, 114, 115, 116, 117, 118] },
      110: { children: [] },
      111: { children: [] },
      112: { children: [1220, 1221] },
      113: { children: [1230, 1231] },
      114: { children: [1240, 1241] },
      115: { children: [1240, 1241] },
      116: { children: [1240, 1241] },
      117: { children: [1270] },
      118: { children: [1280, 1281, 1282] },
     
      1220: { children: [2300, 2301] },
      1221: { children: [2310, 2311] },
      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure HRIS permission ID (2) is included if any HRIS permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[2].children.includes(perm)
      ) &&
      !selectedPermissions.includes(2)
    ) {
      selectedPermissions.push(2);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline font-sans"
            value={2}
          >
            Inventory
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p
                  className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black"
                  value={6}
                >
                  Inventory Purchase GRN
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="110"
                    checked={!!checkedValues[110]}
                    onChange={() => handleCheckboxChange(110)}
                  />
                  Inventory Purchase GRN
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Purchased Inventory Items Store
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="111"
                    checked={!!checkedValues[111]}
                    onChange={() => handleCheckboxChange(111)}
                  />
                  Purchased Inventory Items Store
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Request
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1220"
                      checked={!!checkedValues[1220]}
                      onChange={() => handleCheckboxChange(1220)}
                    />
                    Production Item Requests
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2300"
                          checked={!!checkedValues[2300]}
                          onChange={() => handleCheckboxChange(2300, 1220)}
                        />
                        Edit
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2301"
                          checked={!!checkedValues[2301]}
                          onChange={() => handleCheckboxChange(2301, 1220)}
                        />
                        Released
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1221"
                      checked={!!checkedValues[1221]}
                      onChange={() => handleCheckboxChange(1221)}
                    />
                    Branch Item Requests
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2320"
                          checked={!!checkedValues[2320]}
                          onChange={() => handleCheckboxChange(2320)}
                        />
                        View and Release
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2322"
                          checked={!!checkedValues[2322]}
                          onChange={() => handleCheckboxChange(2322)}
                        />
                        Export CSV
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="2321"
                          checked={!!checkedValues[2321]}
                          onChange={() => handleCheckboxChange(2321)}
                        />
                        Released Branch Req
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="10100"
                          checked={!!checkedValues[10100]}
                          onChange={() => handleCheckboxChange(10100)}
                        />
                        View Released Branch Req
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="10101"
                          checked={!!checkedValues[10101]}
                          onChange={() => handleCheckboxChange(10101)}
                        />
                        Export CSV Released
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="10102"
                          checked={!!checkedValues[10102]}
                          onChange={() => handleCheckboxChange(10102)}
                        />
                        View History Log
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Dispose
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="114"
                    checked={!!checkedValues[114]}
                    onChange={() => handleCheckboxChange(114)}
                  />
                  Inventory Dispose
                </label>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1240"
                          checked={!!checkedValues[1240]}
                          onChange={() => handleCheckboxChange(1240)}
                        />
                        Action
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1241"
                          checked={!!checkedValues[1241]}
                          onChange={() => handleCheckboxChange(1241)}
                        />
                        Submit
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Disposed Items
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="115"
                    checked={!!checkedValues[115]}
                    onChange={() => handleCheckboxChange(115)}
                  />
                  Disposed Items
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Return
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="116"
                    checked={!!checkedValues[116]}
                    onChange={() => handleCheckboxChange(116)}
                  />
                  Return Items
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Item Adjustment
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="117"
                    checked={!!checkedValues[117]}
                    onChange={() => handleCheckboxChange(117)}
                  />
                  Items Adjustment
                </label>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1270"
                          checked={!!checkedValues[1270]}
                          onChange={() => handleCheckboxChange(1270)}
                        />
                        Edit
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Dashboard
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="118"
                    checked={!!checkedValues[118]}
                    onChange={() => handleCheckboxChange(118)}
                  />
                  Dashboard
                </label>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1280"
                          checked={!!checkedValues[1280]}
                          onChange={() => handleCheckboxChange(1280)}
                        />
                        Total Available
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1281"
                          checked={!!checkedValues[1281]}
                          onChange={() => handleCheckboxChange(1281)}
                        />
                        Total
                      </label>
                    </div>
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1282"
                          checked={!!checkedValues[1282]}
                          onChange={() => handleCheckboxChange(1282)}
                        />
                        Total
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Add Item Mannually
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="119"
                    checked={!!checkedValues[119]}
                    onChange={() => handleCheckboxChange(119)}
                  />
                  Add Item Mannually
                </label>
                <div className="ml-5 mt-2">
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1290"
                          checked={!!checkedValues[1290]}
                          onChange={() => handleCheckboxChange(1290)}
                        />
                        Add
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inventory_Permssion;
