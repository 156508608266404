import React from "react";
import Count5000 from "../../../Assets/vehicleFleetImg/5000.svg";
import AnimatedCard from "../../../components/vehicleFleetCard/card";


const Home = () => {

  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      <AnimatedCard
        imageSrc= {Count5000}
        title="Vehicles_exceeding_5000km"
        link="/compliance-reporting/vehicles_exceeding_5000km"
      />
    </div>
  );
};

export default Home;
