import React, { useState } from "react";

function AddNewDisciplinary({ employee_no, setFetchData, onClose }) {
  const [disciplinarySections, setDisciplinarySections] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleDisciplinaryFileUpload = (event) => {
    const files = event.target.files;
    const newUploadedFiles = [...uploadedFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === "application/pdf") {
        newUploadedFiles.push(file);
      } else {
        // Show alert message if a file is not a PDF
      }
    }
    setUploadedFiles(newUploadedFiles);
  };

  const handledisSubmit = async () => {
    try {
      const formData = new FormData();
      const employee_no = document.getElementById("employee_no").value;

      formData.append("employee_no", employee_no);

      // Loop through uploaded files and append them to formData
      uploadedFiles.forEach((file, index) => {
        formData.append(`employee_disciplinary_files`, file);
        // Retrieve the date of the disciplinary call
        const disciplinaryCallDate = document.getElementById(
          `employee_disciplinary_call_date_${index}`
        ).value;
        // Append the disciplinary call date to formData
        formData.append(
          `employee_disciplinary_call_date`,
          disciplinaryCallDate
        );
      });

      // Log formData to see the data being sent
      console.log("FormData:", formData);

      // Make the POST request with formData
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/uploaddisfiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      // Handle response
      if (response.ok) {
        // File(s) uploaded successfully
        const responseData = await response.json();
        console.log("Files uploaded successfully:", responseData);
        onClose();
        setFetchData((prevKey) => prevKey + 1);
      } else {
        // Handle error response
        console.error("Error uploading files:", response.statusText);
      }
    } catch (error) {
      // Handle error
      console.error("Error uploading files", error);
    }
  };

  const renderDisciplinaryActionsSections = () => {
    const sections = [];
    const maxSections = Math.min(disciplinarySections, 10);

    for (let i = 0; i < maxSections; i++) {
      sections.push(
        <div key={i} className="mt-5">
          <div className="flex items-center justify-center">
            <div className="w-[100%] bg-[#ffffff] rounded-xl p-8 mt-5">
              <div className="flex">
                <label
                  htmlFor={`employee_dependent_relationship_${i}`}
                  className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                >
                  Disciplinary Actions
                </label>
              </div>
              <hr className="line border-t border-gray-300 w-[25%]"></hr>
              <div className="mt-10">
                <form>
                  <div className="flex mt-5 flex-col md:flex-row font-bold">
                    <div className="flex flex-col mr-10">
                      <label
                        htmlFor={`employee_disciplinary_call_date_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Date of Explanation Call
                      </label>
                      <input
                        type="date"
                        name={`employee_disciplinary_call_date_${i}`}
                        id={`employee_disciplinary_call_date_${i}`}
                        autoComplete={`employee_disciplinary_call_date_${i}`}
                        placeholder="Enter Type"
                        className="w-[300px] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-gray-900 placeholder-white sm:text-sm px-2 font-sans"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`disfile_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Upload Letter
                      </label>
                      <div className="grid w-full max-w-xs items-center gap-1.5">
                        <input
                          className="flex w-full h-10 rounded-md border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#9CAC8B] file:text-white file:text-sm file:font-medium file:h-10"
                          type="file"
                          id={`disfile_${i}`}
                          onChange={handleDisciplinaryFileUpload}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  return (
    <div className="bg-[#ffffff] w-[100%] rounded-[35px] h-[800px] text-[32px] ml-[5%] mr-[5%] font-bold overflow-y-auto">
      <p className="text-4xl mb-4 ml-7 mt-[8%] mr-10 font-bold text-[#797C80] ">
        Add Disciplinary Actions
      </p>
      {renderDisciplinaryActionsSections()}

      <div className="ml-[3%]">
        <button
          type="button"
          onClick={() => setDisciplinarySections((prev) => prev + 1)}
          className="rounded-full bg-[#5B6D49] mb-5 px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          style={{ borderRadius: "45px" }}
        >
          Add Disciplinary Actions
        </button>
      </div>
      <div className="flex gap-5 justify-end mr-5 mb-10">
        <button
          onClick={onClose}
          className="rounded-full bg-[rgb(171,175,166)] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
        >
          Cancel
        </button>
        <button
          onClick={handledisSubmit}
          className="rounded-full bg-[#5B6D49] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddNewDisciplinary;
