 

import React, { useState } from "react";
import OveroleProductionTable from "./OveroleProductionTable";
import StageData from "./StageData";

function Manage_Production() {
  const [selectedProductionId, setSelectedProductionId] = useState(null);

  const handleRowClick = (productionId) => {
    setSelectedProductionId(productionId);
  };

  return (
    <div>
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Manage Production
      </p>
      <div className="grid grid-flow-row-dense grid-cols-5 overflow-y-auto h-screen">
        <div className="col-span-2">
          <OveroleProductionTable setSelectedProductionId={handleRowClick} />
        </div>
        <div className="col-span-3 overflow-y-auto ">
          <StageData productionId={selectedProductionId} />
        </div>
      </div>
    </div>
  );
}

export default Manage_Production;
