import React from "react";
import FuleReport from "../../../Assets/vehicleFleetImg/fuleReport.svg";
import FuleCost from "../../../Assets/vehicleFleetImg/fuelCostUpdate.svg";
import FuleConsumption from '../../../Assets/vehicleFleetImg/fuelConsumption.svg';
import AnimatedCard from "../../../components/vehicleFleetCard/card";


const Home = () => {


  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      <AnimatedCard
        imageSrc= {FuleConsumption}
        title="Fuel Consumption Data"
        link="/fuel-manegemnt/fuel-consumption-data"
      />

      {/* Card 2 */}
      <AnimatedCard
        imageSrc={FuleCost}
        title="Fuel Cost Update"
        link="/fuel-manegemnt/fuel-cost-update"
      />

      {/* Card 3 */}
      <AnimatedCard
        imageSrc={FuleReport}
        title="Generate Monthly Fuel Reports"
        link='/fuel-manegemnt/generate-monthly-fuel-reports'  
      />

    </div>
  );
};

export default Home;
