import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Functional component
const Example = () => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [data, setData] = useState([]);

  const getVehicleCountDeta = async () => {
    try {
      const params = {
        vehicle_type: 'All Vehicle',
      };
      const response = await axios.get(
        `${backendUrl}/vehicle/getVehicleCountDashboard`,
        {
          params,
        }
      );
    
      setData(response.data.barData);
     
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  useEffect(() => {
    getVehicleCountDeta();
  }, []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="value"
          fill="#82ca9d"
          activeBar={<Rectangle fill="gold" stroke="purple" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
