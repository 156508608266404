 

import React, { useState } from "react";
import Leave_Reports from "./leave_reports_info";
import usePermissions from "../../../components/permissions/permission";

const ReportsAnalytics = () => {
  const { hasPermission } = usePermissions();
  const [selectedCard, setSelectedCard] = useState(""); // State to manage selected card

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="text-[40px] text-headerColor font-semibold ml-6 mt-6 text-header">
          Reports & Analytics
        </p>
      </div>
      <div className="grid grid-cols-3 grid-flow-col gap-8 ml-6 mt-6 px-[100px] ">
        {/* Card 1 */}
        {hasPermission(1040) && (
          <div className="bg-white rounded-[32px] p-6 ">
            <div>
              <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Monthly Leave
              </p>
              <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">Reports</p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => handleCardClick("Monthly Leave")}
                className="bg-primary p-3 text-white rounded-[20px] mt-3 font-semibold"
              >
                View
              </button>
            </div>
          </div>
        )}

        {/* Card 2 */}
        {hasPermission(1041) && (
          <div className="bg-white rounded-[32px] p-6">
            <div>
              <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Medical Leave
              </p>
              <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">Reports</p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => handleCardClick("Medical Leave")}
                className="bg-primary p-3 text-white rounded-[20px] mt-3 font-semibold"
              >
                View
              </button>
            </div>
          </div>
        )}

        {/* Card 3 */}
        {hasPermission(1042) && (
          <div className="bg-white rounded-[32px] p-6">
            <div>
              <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Incentive{" "}
                <span className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans ">&</span>
              </p>
              <p className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                Attendance Allowance Reports
              </p>
            </div>
            <div className="flex justify-end ">
              <button
                onClick={() => handleCardClick("Incentive & Attendance")}
                className="bg-primary p-3 text-white rounded-[20px] mt-3 font-semibold"
              >
                View
              </button>
            </div>
          </div>
        )}

        {/* Popup/Modal */}
        {selectedCard && (
          <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
            <Leave_Reports
              cardName={selectedCard}
              onClose={() => setSelectedCard("")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportsAnalytics;
