import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import cloudiccon from "../../../Assets/icons/cloud-add.png";
import axios from "axios";

const Upload_Invoice_Purchase_Order = ({ onClose, data }) => {
  const navigate = useNavigate();
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [files, setFiles] = useState({});
  const [image, setImage] = useState({});
  const [currentDate, setCurrentDate] = useState(new Date());

  const addFile = (file) => {
    const newFiles = { ...files };
    newFiles[file.name] = file;
    setFiles(newFiles);
  };

  // Submitting data.
  const handleSubmit = async () => {
    try {
      // Create a FormData object
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("tender_id", data);
      formData.append("file", image);
      formData.append("added_user_id", "USER-49970751");
      formData.append("added_date", currentDate.toLocaleDateString("en-GB"));

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/purchase_order/add/invoice`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response

      console.log("Response:", response.data);
      alert("Purchase Order Add successfully added");
      // handleItemReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Purchase Order");
    }
  };

  const handleCancel = () => {
    setFiles({});
  };

  const handleFileInputChange = (e) => {
    const fileList = e.target.files[0];
    if (fileList) {
      setImage(fileList);
      setFiles({ [files.name]: fileList });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const fileList = e.dataTransfer.files[0];
    setImage(fileList);
    for (const file of fileList) {
      addFile(file);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-dialogBoxbg border-gray-300 rounded-[24px] p-8 z-10 w-[60%] h-[80%] ">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center  border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div className="">
          {" "}
          <h1 className="text-[#797C80] text-[36px] font-bold">
            Upload Invoice
          </h1>
          <hr class="w-48 h-[1px] bg-gray-100 border-0 rounded  dark:bg-gray-700"></hr>
        </div>
        <div className="flex justify-center items-center h-[80%]">
          <div className="bg-dialogBoxbg rounded-[19px] w-[90%] h-[95%] flex-shrink-0">
            <div className="flex justify-center items-center h-[100%]">
              <div className="bg-dialogBoxbg rounded-[19px] w-[100%] h-[100%] flex-shrink-0">
                <div className="bg-white justify-center items-center rounded-[26px] min-h-[400px] w-[80%] h-[90%] flex-shrink-0 mt-5 ml-[10%]">
                  <main className="container mx-auto max-w-screen-lg h-full">
                    {/* File upload modal */}
                    <article className="relative h-full flex flex-col rounded-md">
                      {/* Scroll area */}
                      <section className="h-full overflow-auto p-8 w-full flex flex-col">
                        <div className="flex items-center">
                          <img
                            src={cloudiccon}
                            alt="Cloud Icon"
                            className="h-10 w-10 rounded-full border border-gray-600"
                          />

                          <div>
                            <p className="text-gray-800 font-inter font-medium text-lg ml-2">
                              Upload Meeting Report
                            </p>
                            <p className="text-dialogSubmenu-500 text-gray-400 font-inter font-medium text-sm ml-2">
                              Select and upload the files of Your choice
                            </p>
                          </div>
                        </div>
                        <hr className="  w-[100%] border-solid border-b-2 border-grayColor mt-2"></hr>

                        <header
                          className="border-dashed border-2 bg-dialogboxFileup border-gray-400 py-12 flex flex-col justify-center items-center mt-2 rounded-[26px]"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center items-center flex-col">
                            <div>
                              <img
                                src={cloudiccon}
                                alt="Cloud Icon"
                                className="h-10 w-10 rounded-full"
                              />
                            </div>
                            <span>
                              Choose a PDF file or drag & drop it here
                            </span>
                            &nbsp;
                            <span className="text-gray-400">
                              files anywhere or
                            </span>
                          </p>
                          <input
                            id="hidden-input"
                            type="file"
                            className="hidden"
                            onChange={handleFileInputChange}
                          />
                          <button
                            id="button"
                            className="mt-2 rounded-[16px] px-3 py-1 bg-white hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                            onClick={() =>
                              document.getElementById("hidden-input").click()
                            }
                          >
                            Browse File
                          </button>
                        </header>

                        {/* Uploaded files list */}
                        <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                          Uploaded Files
                        </h1>
                        <ul className="flex flex-1 flex-wrap -m-1">
                          {Object.values(files).map((file, index) => (
                            <li
                              key={index}
                              className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24"
                            >
                              <article
                                tabIndex="0"
                                className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                              >
                                <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                  <h1 className="flex-1 group-hover:text-blue-800">
                                    {file.name}
                                  </h1>
                                  <div className="flex">
                                    <span className="p-1 text-blue-800">
                                      <i>
                                        <svg
                                          className="fill-current w-4 h-4 ml-auto pt-1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                        </svg>
                                      </i>
                                    </span>
                                    <p className="p-1 size text-xs text-gray-700">
                                      {file.size > 1024
                                        ? file.size > 1048576
                                          ? Math.round(file.size / 1048576) +
                                            "mb"
                                          : Math.round(file.size / 1024) + "kb"
                                        : file.size + "b"}
                                    </p>
                                  </div>
                                </section>
                              </article>
                            </li>
                          ))}
                        </ul>
                      </section>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sticky footer */}

        <footer className="flex justify-end px-8 pb-8 ml-10 mt-10">
          <button
            id="submit"
            className="rounded-lg px-3 py-1 bg-addBtn hover:bg-green-800 text-white text-lg focus:shadow-outline focus:outline-none"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            id="cancel"
            className="ml-3 rounded-lg px-3 py-1 hover:bg-gray-300 text-black font-bold focus:shadow-outline focus:outline-none text-lg"
            onClick={handleCancel}
          >
            Reset
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Upload_Invoice_Purchase_Order;
