/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GrPrevious, GrNext } from "react-icons/gr";
import CSV from "../../../../Assets/icons/csv.png";
import usePermissions from "../../../../components/permissions/permission";
import Papa from "papaparse";

const CommitteeMembers = ({ itemReq }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItemReq, setselectedItemReq] = useState([]);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [selectComStatus, setSelectComStatus] = useState("PENDING");
  const [selectComType, setSelectComType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    if (selectComStatus !== null) {
      fetchItemData();
    }
  }, [selectComStatus, selectComType]);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/all/committees?status=${selectComStatus}&committee_title=${selectComType}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setItems(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const HandleOnClick = (itemReq) => {
    // Navigate to the new page and pass the object
    navigate("/committees/members/edit", {
      state: { selectedRecord: itemReq },
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredEmployees =
    items && items.length > 0
      ? items.filter((item) => {
          const searchMatch =
            !searchQuery ||
            item.inventory_request_by
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            item.inventory_request_id
              .toLowerCase()
              .includes(searchQuery.toLowerCase());

          return searchMatch;
        })
      : [];

  const currentItems = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const exportToCSV = (currentItems) => {
    const csvData = currentItems.map((itemReq) => ({
      "Committee Title": itemReq.committee_title,
      "Committee Type": itemReq.committee_type,
      "Appointing Date": itemReq.appointing_date,
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "CommitteeMembers.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className="bg-background h-screen overflow-y-auto">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        View Committee Members
      </p>
      <div className="flex justify-between overflow-hidden pb-6">
        <div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <input
                type="text"
                placeholder="Search by committee name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-[200px] sm:w-[300px] h-10 duration-300 rounded-full border border-white px-2 py-1"
              />
            </div>
          </div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <select
                value={selectComStatus}
                onChange={(e) => setSelectComStatus(e.target.value)}
                className="input-1 border border-[#00000036]"
              >
                <option value="">Select Committee Status</option>
                <option value="PENDING">PENDING</option>
                <option value="APPROVED">APPROVED</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col font-poppins font-bold ml-6 mt-4">
            <div className="relative font-sans group hidden sm:block">
              <select
                value={selectComType}
                onChange={(e) => setSelectComType(e.target.value)}
                className="input-1 border border-[#00000036]"
              >
                <option value="">Select Committee Type</option>
                <option value="SAMPLE TESTING COMMITTEE">
                  SAMPLE TESTING COMMITTEE
                </option>
                <option value="OPENING COMMITTEE">OPENING COMMITTEE</option>
                <option value="TECHNICAL EVALUATION COMMITTEE">
                  TECHNICAL EVALUATION COMMITTEE
                </option>
                <option value="TENDER COMMITTEE">TENDER COMMITTEE</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto max-h-[calc(100vh-200px)] ml-5 mr-5 rounded-[22px] ">
        <table className="table-auto border-none overflow-x-auto w-full">
          <thead>
            <tr className="bg-white font-sans text-[16px]">
              <th className="text-primary px-6 py-4">Committee Title</th>
              <th className="text-primary px-6 py-4">Committee Type</th>
              <th className="text-primary px-6 py-4">Appointing Date</th>
              <th className="text-primary px-6 py-4">Action</th>
            </tr>
          </thead>
          <tbody className="font-semibold font-sans">
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((itemReq) => (
                <tr key={itemReq.committee_id}>
                  <td className="bg-white px-6 py-4 text-center">
                    {itemReq.committee_title}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {itemReq.committee_type}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {itemReq.appointing_date}
                  </td>
                  <td className="bg-white px-6 py-4 text-center">
                    {hasPermission(2920) && (
                      <button
                        className="bg-gray-300 p-2 items-center rounded-md w-[200px]"
                        onClick={() => HandleOnClick(itemReq)}
                      >
                        View
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center bg-white px-6 py-4">
                  No table data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrPrevious className="w-3 h-3" />
        </button>
        {[...Array(Math.ceil(filteredEmployees.length / itemsPerPage))].map(
          (page, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1 ? "bg-gray-300" : "bg-white"
              } text-primary px-4 py-2 mx-1 rounded-md`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(filteredEmployees.length / itemsPerPage)
          }
          className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
        >
          <GrNext className="w-3 h-3" />
        </button>
      </div>
      <div className="flex justify-end mb-4 mr-4">
        <button className="bg-primary text-white font-sans p-3 rounded-[20px]">
          <div className="flex items-center" onClick={exportToCSV}>
            <div onClick={exportToCSV} className="font-semibold">
              Export CSV
            </div>
            <div>
              <img className="h-8 w-8" src={CSV} alt="logo-csv" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default CommitteeMembers;