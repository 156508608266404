/** @format */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";
import FileIcon from "../../../../Assets/icons/pdf_file.png";

const Sample_Testing_Quo_View = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [items, setItems] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [prResults, setPrResults] = useState([]);
  const [selectSupplierID, setSelectSupplierID] = useState("");
  const [selectPRTitle, setPRTitle] = useState("");
  const [selectPRID, setPRID] = useState("");
  const [subTotal, setSubTotal] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedRecord = {} } = location.state || {};
  const [tableData, setTableData] = useState([]);
  console.log("selectedReccccccc", selectedRecord.quotation_id);

  const fetchQuotationData = async () => {
    console.log("HI");
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/sampleTestingCommittee/sample-testing/${selectedRecord.quotation_id}`
      );
      const data = await response.json();

      setTableData(data);
      console.log("tableData", data);
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  useEffect(() => {
    fetchQuotationData();
  }, []);
  useEffect(() => {
    console.log("hello MF");
  }, []);

  // Reset function
  const handleReset = () => {
    navigate(-1);
  };

  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Function to handle file upload
  const handleFileUpload = (index, file) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].uploadedFile = file; // Assign the uploaded file to the correct item
    setTableData(updatedTableData);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Sample Testing Quotation Form
        </p>
      </div>

      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px] relative p-6">
        <div className="grid grid-cols-2 gap-6">
          {/* Purchase Request No */}
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request No:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              type="text"
              value={tableData.length > 0 ? tableData[0].pr_no || "" : ""}
              readOnly
            />
          </div>

          {/* Purchase Request Title */}
          <div className="flex items-center">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Purchase Request Title:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              value={tableData.length > 0 ? tableData[0].title || "" : ""}
              readOnly
            />
          </div>

          {/* Sample Testing Committee */}
          <div className="flex items-center col-span-2">
            <p className="text-[22px] text-[black] font-bold mt-2 mb-2">
              Sample Testing Committee:
            </p>
            <input
              className="rounded-lg border border-gray-300 w-[60%] ml-4 mt-2 mb-2"
              value={
                tableData.length > 0 ? tableData[0].committee_title || "" : ""
              }
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center justify-center">
        <div className="bg-[#F3F8FF] w-[90%] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Quotation</p>

          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Quotation No</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={
                tableData.length > 0 ? tableData[0].quotation_id || "" : ""
              }
              readOnly // Make the input read-only as it will be auto-populated
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Supplier Name</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={
                tableData.length > 0 ? tableData[0].supplier_name || "" : ""
              }
              readOnly // Make the input read-only as it will be auto-populated
            />
          </div>

          {/* Supplier ID input (auto-populated) */}
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Date of Submission</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={tableData.length > 0 ? tableData[0].create_at || "" : ""}
              readOnly // Make the input read-only as it will be auto-populated
            />
          </div>

          <p className="header-1 mb-10">Quotation Item Details</p>
          <div className="flex flex-col w-[120%] bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
            <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
              <p className="w-10 p1 text-[#656565] text-center">#</p>
              <div className="w-[250px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Item Name
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Sample No
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">Status</p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
              <div className="w-[200px] flex flex-col items-center">
                <p className="w-full p1 text-[#656565] text-center ">
                  Testing Document
                </p>
                <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
              </div>
            </div>
            <div className="flex flex-col  gap-y-5 my-3 h-[250px] overflow-y-scroll">
              {tableData &&
              tableData.length > 0 &&
              tableData[0].items.length > 0
                ? tableData[0].items.map((item, index) => (
                    <div key={index} className="w-full flex justify-around">
                      <div className="w-10 flex items-center">
                        <p className="w-full p1 text-[#656565] text-center">
                          {index + 1}
                        </p>
                      </div>
                      <div className="w-[250px] flex items-center">
                        <p className="w-full p1 text-[#656565] text-center">
                          {item.item_name}
                        </p>
                      </div>
                      <div className="w-[200px] flex items-center">
                        <p className="w-full p1 text-[#656565] text-center">
                          {item.sample_no}
                        </p>
                      </div>
                      <div className="w-[200px] flex items-center">
                        <p className="w-full p1 text-[#656565] text-center">
                          {item.status}
                        </p>
                      </div>
                      <div className="w-[200px] flex items-center">
                        {item.testing_document_path ? (
                          <div className="flex justify-center">
                            <a
                              href={`${REACT_APP_API_ENDPOINT}/v1/supplyChain/sampleTestingCommittee/sample-testing-file/${item.testing_document_path}`}
                              download={item.testing_document_path} // Make the file downloadable
                              className="cursor-pointer"
                            >
                              <img
                                className="h-[100px] w-[100px] object-contain"
                                src={FileIcon}
                                alt="File Icon"
                              />
                            </a>
                          </div>
                        ) : (
                          <p>No file uploaded</p>
                        )}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      {/* Signarute Image Uploader */}

      <div className="  grid grid-cols-1 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img
            src={
              tableData.length > 0 && tableData[0].internal_auditor_signature
                ? tableData[0].internal_auditor_signature
                : ""
            }
            alt="Preview"
            className="w-40 h-40 rounded-lg mb-4"
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Sample_Testing_Quo_View;
