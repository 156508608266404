import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaCar } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import Navbar from "../../../components/navbar/navbar";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Fuel_consumption_dashboard = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]); // State to hold fetched data
  const currentDate = moment().format("MMMM Do YYYY");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("All Vehicles");
  const [departments, setDepartments] = useState([]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentTime(moment().format("h:mm:ss a"));
  //   }, 1000);
  //   return () => clearInterval(timer);
  // }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setIsDropdownOpen(false);
    // Fetch or filter data based on the selected department if needed
  };

  useEffect(() => {
    setDepartments(["All Vehicles", "Department A", "Department B"]);
  }, []);

  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Fuel Consumption (Liters)",
        data: [120, 150, 180, 100, 90, 160],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        fill: true,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Fuel Consumption",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="mx-10 mt-2"> {/* Reduced top margin */}
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between mt-4 gap-6"> {/* Reduced top margin */}
          <div>
            <p className="text-[24px] font-semibold">Inventory Management</p> {/* Reduced font size */}
            <div className="flex align-left items-center gap-6">
              <p className="text-[14px] font-semibold text-black">Vehicle Fleet Management System</p> {/* Reduced font size */}
              <p className="text-[14px] font-semibold text-black">Inventory Management</p> {/* Reduced font size */}
              <p className="text-[14px] font-semibold text-[#ABDCB0]">Fuel Consumption Dashboard</p> {/* Reduced font size */}
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[18px] font-bold">{currentDate}</div> {/* Reduced font size */}
            </div>
            <div className="text-[18px] font-bold">{currentTime}</div> Reduced font size
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            {/* Department Dropdown */}
            <div className="relative">
              <button
                className="p-2 border border-black rounded-[12px]" 
                onClick={toggleDropdown}
              >
                <div className="flex gap-3 items-center">
                  <div>{selectedDepartment}</div>
                  <MdKeyboardArrowDown />
                </div>
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                  <ul>
                    {departments.map((dept, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleDepartmentSelect(dept)}
                      >
                        {dept}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center text-black gap-2">
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-3 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="h-5 w-5 text-gray-400" />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="Start Date"
              />
              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="End Date"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Cards */}
      <div className="mt-8">
        <div className="grid grid-cols-3 grid-flow-rows gap-4"> {/* Reduced gap */}
          {/* card 1 */}
          <div className="border border-black p-2 rounded-xl"> {/* Reduced padding */}
            <div className="flex justify-between items-center mt-3"> {/* Reduced margin */}
              <div>
                <FaCar className="w-6 h-6" /> {/* Reduced icon size */}
              </div>
              <div>
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-4 font-semibold">Total Fuel Consumed</p> {/* Reduced margin */}
            <p className="text-[24px] font-semibold">{data.totalWorkforce}</p> {/* Reduced font size */}
          </div>
          {/* card 2 */}
          <div className="border border-black p-2 rounded-xl"> {/* Reduced padding */}
            <div className="flex justify-between items-center mt-3"> {/* Reduced margin */}
              <div>
                <FaCar className="w-6 h-6" /> {/* Reduced icon size */}
              </div>
              <div>
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-4 font-semibold">Average Fuel Efficiency</p> {/* Reduced margin */}
            <p className="text-[24px] font-semibold">{data.presentWorkforce}</p> {/* Reduced font size */}
          </div>
        </div>
      </div>

      {/* Line Chart */}
      <div className="flex gap-3 items-center w-full mt-4"> {/* Reduced top margin */}
        <div className="flex-auto">
          <Line data={lineChartData} options={lineChartOptions} />
        </div>
      </div>
    </div>
  );
};

export default Fuel_consumption_dashboard;
