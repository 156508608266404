/** @format */

import React from "react";

import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

const InventoryReturn_nav = () => {
  const { hasPermission } = usePermissions();
  const BranchReqData = [
    {
      id: 1,
      header: "Branch Item Return Request",

      headerClasses: "text-black",
      linkTo: "/inventory/branch-item-return-req",
      permissionId: 1220,
    },
    {
      id: 2,
      header: "Add Mobile POS Item Return",

      headerClasses: "text-black",
      linkTo: "/inventory/mobilepos-return-req",
      permissionId: 1221,
    },
    {
      id: 3,
      header: "Mobile POS Item Return Request Lists",

      headerClasses: "text-black",
      linkTo: "/inventory/mobilepos-return-req-list",
      permissionId: 1221,
    },
  ];

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80] ">
        Inventory Item Return Requests
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6 ">
        {BranchReqData.map((card) =>
          hasPermission(card.permissionId) ? (
            <Link key={card.id} to={card.linkTo}>
              <div
                key={card.id}
                className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  {/* Conditional rendering based on header words in groups of 3 */}
                  {card.header
                    .split(" ")
                    .reduce((acc, word, index) => {
                      const chunkIndex = Math.floor(index / 3); // Group words into chunks of 3
                      if (!acc[chunkIndex]) {
                        acc[chunkIndex] = []; // Create a new chunk
                      }
                      acc[chunkIndex].push(word); // Add word to the current chunk
                      return acc;
                    }, [])
                    .map((chunk, index) => (
                      <h2
                        key={index}
                        className="text-[20px] font-semibold mb-1"
                      >
                        {chunk.join(" ")}{" "}
                        {/* Join the words in each chunk with a space */}
                      </h2>
                    ))}
                </div>
                <div className="flex justify-between items-center">
                  <button className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]">
                    Click Here
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
};

export default InventoryReturn_nav;
