import React, { useState, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cloudIcon from "../../../../../Assets/icons/cloud-add.png";
import { ImagePreview } from "@files-ui/react";
import ReactToPrint from "react-to-print";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

const Opening_Price_Report_Form_View = () => {
  const navigate = useNavigate();

  const download = () => {
    navigate("/committees/openingPriceFormView/pdf", {
      state: { formData, instituteNames },
    });
  };

  const [tenderID, setTenderID] = useState("");
  const [formData, setFormData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [instituteNames, setInstituteNames] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const handleTenderIDChange = async (e) => {
    const id = e.target.value;
    setTenderID(id);

    if (id) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_opening_price_form/id/status/${id}/APPROVED`
        );

        if (response.data.length > 0) {
          setFormData(response.data[0]);
          setImagePreview(response.data[0].committee_signature); // Assuming you have a URL or base64 image
          console.log("Data:", imagePreview);
          fetchInstitutes(id);
        } else {
          setFormData(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFormData(null);
      }
    }
  };
  const fetchInstitutes = async (id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/tender_opening_price_form/institutes/id/${id}`
      );
      setInstituteNames(
        response.data.map((institute) => institute.institute_name)
      );
    } catch (error) {
      console.error("Error fetching institutes:", error);
    }
  };

  const componentRef = useRef();
  return (
    <div className="bg-[#E5EDF9] overflow-y-auto h-screen " ref={componentRef}>
      <p className="text-[40px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        View: Opening Price Form
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2">
            Tender ID:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2"
            value={tenderID}
            onChange={handleTenderIDChange}
          ></input>

          <p className=" text-[22px] text-[black] font-bold mt-2 mb-2 ml-[20%]">
            Tender Name:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[20%] ml-10 mt-2 mb-2 "
            value={formData?.tender_name || ""}
            readOnly
          ></input>
        </div>
      </div>

      {/* Third form part */}
      <div className=" flex">
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          View Form
        </p>
      </div>
      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] grid grid-cols-2">
        <div className="flex items-center justify-between">
          <p className="text-[22px] text-[#8691AB] font-bold ml-6">Section</p>
          <input
            className="rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            value={formData?.section || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6">
            Date of Bid Opening:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            value={formData?.deadline_date || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Time of Bid Opening:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            value={formData?.bid_opening_time || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Reason of Tender Calling
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            value={formData?.reason || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            No.of Institutions that call for prices:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8"
            value={formData?.no_of_institutions || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Number of Firms submitted prices:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            value={formData?.no_of_firms_submitted_prices || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Letter File:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            value={formData?.letter_file || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Correspondence File No:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            value={formData?.correspondence_file_no || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name of the Cheques are to be Written:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            value={formData?.name_of_cheques || ""}
            readOnly
          ></input>
        </div>
        <div className="flex items-center justify-between mt-6">
          <p className=" text-[22px] text-[#8691AB] font-bold ml-6 ">
            Name and the Address of the Payee:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[40%] ml-10 h-8 mb-6"
            value={formData?.name_and_address_of_payee || ""}
            readOnly
          ></input>
        </div>
      </div>

      {/* Add Institutes */}
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50]">
        Add Institutes
      </p>
      <div className="bg-[#F3F8FF] w-[95%] mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex flex-col">
          {instituteNames.map((name, index) => (
            <div key={index} className="flex items-center mb-2">
              <p className="text-[22px] text-[#8691AB] font-bold">
                Institute Name:
              </p>
              <input
                className="rounded-lg border border-gray-300 w-[80%] ml-10"
                value={name}
                disabled
              />
            </div>
          ))}
        </div>
      </div>

      {/* Fourth form part */}
      <>
        {" "}
        <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
          Tender Opening Committee
        </p>
      </>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px] justify-between">
        <div className="flex ">
          <div className="min-w-[50%]">
            <div className="flex">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
                Name
              </p>
              <input
                className=" rounded-lg border border-gray-300 w-[90%] ml-10 h-8 mt-6"
                value={formData?.committee_user_name || ""}
                readOnly
              ></input>
            </div>
            <div className="flex items-center">
              <p className=" text-[22px] text-[#8691AB] font-bold ml-6 mt-6">
                Committee Role
              </p>
              <input
                className=" rounded-lg border border-gray-300 w-[90%] ml-10 h-8 mt-6"
                value={formData?.committee_role || ""}
                readOnly
              ></input>
            </div>
          </div>
          {/* Yes NO  */}

          <div className=" ml-[10%]">
            {/* Drag and drop area */}
            <div className="flex flex-col items-center justify-center mt-6">
              {imagePreview ? (
                <img
                  src={`${REACT_APP_API_ENDPOINT}/v1/inventory/tender_opening_price_form/file/${imagePreview}`}
                  alt="Preview"
                  className="w-40 h-40 rounded-lg mb-4"
                />
              ) : (
                <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
                  <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                    Add Signature
                  </p>
                  <img
                    src={cloudIcon}
                    alt="Cloud Icon"
                    className="w-16 h-16 mb-2"
                  />
                  <p className="text-[#8691AB] font-bold ml-4">
                    Drag and Drop Digital Signature
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Other Note
      </p>

      <div className="bg-[#F3F8FF] w-[95%]  mt-6 ml-6 rounded-[46px]">
        <div className="mt-4 ml-6 flex">
          <p className=" text-[22px] text-[#8691AB] font-bold mt-2 mb-2">
            Note:
          </p>
          <input
            className=" rounded-lg border border-gray-300 w-[80%] ml-10 mt-2 mb-2"
            value={formData?.note || ""}
            readOnly
          ></input>
        </div>
      </div>

      <div className="mb-6 mt-6 ml-6">
        <button
          onClick={download}
          className="bg-[#797C80] text-[20px] font-bold text-white rounded-[33px] w-40 h-8 ml-4"
        >
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default Opening_Price_Report_Form_View;
