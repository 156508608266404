import React, { useState } from "react";

function AddNewDependent({ employee_no, setFetchData, onClose }) {
  const [itemSections, setItemSections] = useState(2);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [employeeData, setEmployeeData] = useState({
    dependent_details: [],
  });

  // Function to render dependent sections
  const renderDependentSections = () => {
    const sections = [];
    const maxSections = Math.min(itemSections, 6);

    for (let i = 1; i < maxSections; i++) {
      sections.push(
        <div key={1}>
          <div className="flex items-center justify-center">
            <div className="w-[100%] bg-[#ffffff] rounded-xl p-8 mt-5">
              <p className="text-md mb-4 font-bold text-[#797C80]">
                Dependent{" "}
                {dependantNumber(i + employeeData.dependent_details.length)}
              </p>
              <hr className="line border-t border-gray-300 w-[25%]" />

              <div>
                <form>
                  <div className="flex mt-5 flex-col md:flex-row font-bold">
                    <div className="flex flex-col mr-10">
                      <label
                        htmlFor={`employee_dependent_relationship_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Relationship
                      </label>
                      <input
                        type="text"
                        name={`employee_dependent_relationship_${i}`}
                        id={`employee_dependent_relationship_${i}`}
                        autoComplete={`employee_dependent_relationship_${i}`}
                        placeholder="Spouse / Child"
                        className="w-[300px] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-gray-900 placeholder-white sm:text-sm px-2 font-sans"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`employee_dependent_dob_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Date of Birth
                      </label>
                      <input
                        type="text"
                        name={`employee_dependent_dob_${i}`}
                        id={`employee_dependent_dob_${i}`}
                        autoComplete={`employee_dependent_dob_${i}`}
                        placeholder="Enter Date of Birth "
                        className="w-[300px] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-gray-900 placeholder-white sm:text-sm px-2 font-sans"
                      />
                    </div>
                  </div>

                  <div className="flex mt-5 flex-col md:flex-row font-bold">
                    <div className="flex flex-col mr-10">
                      <label
                        htmlFor={`employee_dependent_name_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name={`employee_dependent_name_${i}`}
                        id={`employee_dependent_name_${i}`}
                        autoComplete={`employee_dependent_name_${i}`}
                        placeholder="Enter name"
                        className="w-[300px] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-gray-900 placeholder-white sm:text-sm px-2 font-sans"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`employee_dependent_nic_${i}`}
                        className="text-xl mb-2 font-bold text-[#797C80] font-sans"
                      >
                        NIC
                      </label>
                      <input
                        type="text"
                        name={`employee_dependent_nic_${i}`}
                        id={`employee_dependent_nic_${i}`}
                        autoComplete={`employee_dependent_nic_${i}`}
                        placeholder="Enter NIC number"
                        className="w-[300px] h-10 rounded-[73px] py-1.5 bg-[#9CAC8B] text-gray-900 placeholder-white sm:text-sm px-2 font-sans"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col  font-bold mt-5"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return sections;
  };

  // Function to handle adding a new dependent section
  const handleAddItemSection = () => {
    setItemSections(itemSections + 1); // Increment itemSections state
  };

  // Function to generate dependent numbers
  const dependantNumber = (number) => {
    // Logic to generate dependent numbers
    return number;
  };

  const createRequestBody = () => {
    const dependents = [];
    const maxSections = Math.min(itemSections, 6);

    for (let i = 0; i < maxSections; i++) {
      const name = document.getElementById(
        `employee_dependent_name_${i}`
      ).value;
      const relationship = document.getElementById(
        `employee_dependent_relationship_${i}`
      ).value;
      const nic = document.getElementById(`employee_dependent_nic_${i}`).value;
      const dob = document.getElementById(`employee_dependent_dob_${i}`).value;

      // Check if any of the required fields are empty
      if (
        name.trim() === "" ||
        relationship.trim() === "" ||
        nic.trim() === "" ||
        dob.trim() === ""
      ) {
        // If any required field is empty, skip adding this dependent to the array
        continue;
      }

      const dependentData = {
        employee_dependent_name: name,
        employee_dependent_relationship: relationship,
        employee_dependent_nic: nic,
        employee_dependent_dob: dob,
      };
      dependents.push(dependentData);
    }

    return dependents;
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      // Log the data being sent
      console.log("Data to be sent:", {
        employee_no: employee_no,
        dependent_details: createRequestBody(),
      });

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/employees/insertEmployeeDependents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            employee_no: employee_no,
            employee_dependent_details: createRequestBody(),
          }),
        }
      );
      const data = await response.json();
      console.log(data); // Handle the response data as needed
      onClose();
      setFetchData((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="bg-[#ffffff] w-[100%] rounded-[35px] h-[800px] text-[32px] ml-[5%] mr-[5%] font-bold overflow-y-auto">
      <p className="text-4xl mb-4 ml-7 mt-[8%] mr-10 font-bold text-[#797C80] ">
        Add Dependent Details{" "}
      </p>
      {renderDependentSections()}
      <div className="ml-[3%]">
        <button
          type="button"
          onClick={handleAddItemSection}
          className="rounded-full bg-[#5B6D49] mb-5 px-3 py-2 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
          style={{ borderRadius: "45px" }}
        >
          Add a dependant
        </button>
      </div>
      <div className="flex gap-5 justify-end mr-5 mb-10">
        <button
          onClick={onClose}
          className="rounded-full bg-[rgb(171,175,166)] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="rounded-full bg-[#5B6D49] w-[100px] h-[40px]  text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 font-sans"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddNewDependent;
