/** @format */

import React, { useEffect, useState } from "react";

const DialogMemberDetails = ({ onClose, id }) => {
  const [comMembers, setComMembers] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const fetchComMembers = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/committee-member-by-pr/${id}`
      );
      const data = await response.json();
      setComMembers(data);
      console.log("Filtered Items:", data);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchComMembers();
  }, [id]);

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-gray-800 bg-opacity-75 fixed inset-0"></div>
      <div className="bg-background rounded-[24px] p-8 z-10 w-[80%] h-[75%]">
        <div className="ml-[97%]">
          <button
            onClick={onClose}
            className="absolute flex justify-center border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px] flex-shrink-0"
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
        <div>
          <h2 className="text-[#071C50] font-poppins font-semibold text-[26px] ml-[5%] mt-4">
            Member Details
          </h2>
          <hr className="w-1/12 border-t-4 border-[#5B6D49] ml-[5%] rounded-[22px]" />
        </div>
        <div className="flex justify-center items-center mt-4 h-[80%]">
          <div className="bg-[#FFF] rounded-[19px] w-[90%] h-[80%] flex-shrink-0 overflow-hidden">
            <div className="overflow-y-auto h-full">
              <table className="table-fixed mt-4 border-none min-h-[100%]">
                <thead>
                  <tr className="bg-white text-gray-600">
                    <th className="sticky top-0 bg-white z-10">Employee ID</th>
                    <th className="sticky top-0 bg-white z-10">
                      Employee Name
                    </th>
                    <th className="sticky top-0 bg-white z-10">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {comMembers.length > 0 ? (
                    comMembers.map((member, index) => (
                      <tr key={index}>
                        <td className="border-b border-gray-300 p-2">
                          {member.employee_id}
                        </td>
                        <td className="border-b border-gray-300 p-2">
                          {member.employee_fullname}
                        </td>
                        <td className="border-b border-gray-300 p-2">
                          {member.role}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        className="text-center border-b border-gray-300 p-4 text-gray-500"
                      >
                        No committee member details available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogMemberDetails;
